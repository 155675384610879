import type { TFunction as TFunc } from 'react-i18next';
import { format } from 'date-fns';
import { DateFormat as DF } from '@common/types';
import { Button } from '@components';

export const getInvoiceHistoryColumns = (t: TFunc): Record<string, any>[] => [
  {
    dataIndex: 'referenceDate',
    key: 'referenceDate',
    title: t('invoice.issueDate'),
    render: (record: string) => (
      <span>{record ? format(new Date(record), DF.DateByDots) : ''}</span>
    ),
  },
  {
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    title: t('invoice.invoiceNumber'),
    render: (record: string) => <span>{record || '-'}</span>,
  },
  {
    key: 'bookedBy',
    title: t('invoice.bookedBy'),
    render: (record: any) => <span>{`${record.firstName} ${record.lastName}`}</span>,
  },
  {
    dataIndex: 'agency',
    key: 'agency',
    title: t('common.paxAgency'),
    render: (record: string) => <span>{record || '-'}</span>,
  },
  {
    dataIndex: 'totalNumberOfPassengers',
    key: 'totalNumberOfPassengers',
    title: 'Pax N°',
    render: (record: string) => <span>{record || '-'}</span>,
  },
  {
    dataIndex: 'transferType',
    key: 'transferType',
    title: t('common.transferType'),
    render: (record: string) => <span>{record ? record.replace('_', ' ') : '-'}</span>,
  },
  {
    dataIndex: 'totalKm',
    key: 'totalKm',
    title: 'Km',
    render: (record: string) => <span>{record || '0.00'}</span>,
  },
  {
    dataIndex: 'totalDuration',
    key: 'totalDuration',
    title: t('common.duration'),
    render: (record: string) => <span>{record || '00:00'}</span>,
  },
  {
    dataIndex: 'requestingUnit',
    key: 'requestingUnit',
    title: t('common.unit'),
    render: (record: string) => <span>{record || '-'}</span>,
  },
  {
    dataIndex: 'invoiceTotal',
    key: 'invoiceTotal',
    title: t('common.total'),
    render: (record: string) => <span>{record || '-'}</span>,
  },
  {
    dataIndex: 'bookingState',
    key: 'bookingState',
    title: t('common.status'),
    render: (record: string) => <span className="status">{record || '-'}</span>,
  },
  {
    dataIndex: 'pdf',
    key: 'actions',
    title: '',
    render: (record: string) => (
      <Button
        className="btn-download"
        text={t('common.btnDownload')}
        variant="submit"
        onClick={() => window.open(record)}
      />
    ),
  },
];
