/* istanbul ignore file */
import { type FC, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { addMinutes, parse } from 'date-fns';
import { toast } from 'react-toastify';
import { Form, type FormState, Multistep } from 'informed';
import type { AxiosError } from 'axios';

import { TIME_INTERVAL } from '@common/constants';
import { Loader } from '@components';
import { DateFormat } from '@common/types';
import { useRepository } from '@context';
import { useFacility } from '@common/hooks';
import {
  Drive,
  DriveApi,
  DriveCreate,
  DriveFormDataApi,
  DriveStatus,
  DropdownOption,
  DriveFormState,
  DriveSchemaApi,
  DriveUpdateApi,
  PlDrive,
  PlDriveFormData,
  PlDriver,
  PlVehicle,
  TripType,
} from '@common/interfaces';
import {
  calcSharedDrive,
  checkConflictDrives,
  checkOverlappingDrives,
  getErrors,
  getRecurringDriveErrorsText,
  getSoonTime,
  Serializer,
} from '@common/utils';
import {
  ApprovingSharedDriveStep,
  MainStep,
  RecurringForceCreate,
  ReturnTripStep,
  TimeSlotEdit,
  TripStep,
} from './components';
import {
  findOption,
  getDefaultOption,
  getLocationChoices,
  mapDriverOptions,
  mapPaxOptions,
  mapVehicleOptions,
  tripTypeOpt,
} from '../../utils';
import './DriveForm.styles.scss';

let driveCreateData: DriveFormDataApi;

interface DriveFormProps {
  drivers: PlDriver[];
  drives: PlDrive[];
  formData: PlDriveFormData | null;
  vehicles: PlVehicle[];
  onCancel: () => void;
  onSubmit: () => void;
}

const DriveForm: FC<DriveFormProps> = ({
  drivers,
  drives,
  formData,
  vehicles,
  onCancel,
  onSubmit,
}) => {
  const {
    facility: { agencyId, city },
    facilityId,
  } = useFacility();
  const { plannerRepo } = useRepository();
  const { t } = useTranslation();

  const [drive, setDrive] = useState<Drive>();
  const [dropoffTownOptions, setDropoffTownOptions] = useState<DropdownOption[]>();
  const [isTimeSlotEdit, setTimeSlotEdit] = useState<boolean>(false);
  const [pickupTownOptions, setPickupTownOptions] = useState<DropdownOption[]>();
  const [purposes, setPurposes] = useState<DropdownOption[]>([]);

  const [dropLocChoices, setDropLocChoices] = useState<{ [x: string]: DropdownOption[] }>();
  const [pickLocChoices, setPickLocChoices] = useState<{ [x: string]: DropdownOption[] }>();

  const [createRecurringErrors, setCreateRecurringErrors] = useState<string[]>([]);
  const [showRecurringForceCreate, setRecurringForceCreate] = useState<boolean>(false);
  const [stepReturnTripEnabled, setStepReturnTripEnabled] = useState<boolean>(false);

  const {
    booking,
    commentToDriver,
    commentToPax,
    driver,
    dropoffDate,
    dropoffLocation,
    dropoffTime,
    dropoffTown,
    hasPassengers,
    id: driveId,
    pickupDate,
    pickupLocation,
    pickupTime,
    pickupTown,
    vehicle,
  } = drive || {};

  const {
    agencyFk,
    agencyShortName,
    email,
    firstName,
    indexNumber,
    lastName,
    managerEmail,
    pax,
    passengersInfo,
    phoneNumber,
    purpose,
    refCode,
    remarks,
    requestingUnit,
    state,
    transferType,
    typeOfTrip,
  } = booking || {};

  useQuery('get-drive', () => plannerRepo.getDrive(facilityId, formData?.id!, { agencyId }), {
    enabled: !!formData?.id,
    onSuccess: (data: DriveApi) => {
      if (data) setDrive(Serializer.formatDriveToFormData(data));
    },
    onError: (error: any) => {
      if (error.message) toast.error(error.message || t('common.errorMsgDefault'));
    },
  });

  useQuery('get-drive-schema', () => plannerRepo.getDriveSchema(facilityId), {
    onSuccess: (data: DriveSchemaApi) => {
      const dropTown = data?.drives?.child?.children?.dropoff_town?.choices || [];
      const pickTown = data?.drives?.child?.children?.pickup_town?.choices || [];
      const purposeChoices = data?.booking_extra?.children?.purpose?.choices || [];
      const obj = data?.drives?.child?.children;
      const dropLoc = obj?.dropoff_location?.choices as unknown as { [x: string]: string[] };
      const pickLoc = obj?.pickup_location?.choices as unknown as { [x: string]: string[] };

      if (dropTown.length) setDropoffTownOptions(dropTown.map(Serializer.formatChoices));
      if (pickTown.length) setPickupTownOptions(pickTown.map(Serializer.formatChoices));
      if (purposeChoices.length) setPurposes(purposeChoices.map(Serializer.formatChoices));
      if (dropLoc && Object.keys(dropLoc).length) setDropLocChoices(getLocationChoices(dropLoc));
      if (pickLoc && Object.keys(pickLoc).length) setPickLocChoices(getLocationChoices(pickLoc));
    },
    onError: (error: any) => {
      if (error.message) toast.error(error.message || t('common.errorMsgDefault'));
    },
  });

  const { mutate: createDrive, isLoading: isCreateDrivesLoading } = useMutation(
    'planner-drive-create',
    (data: DriveFormDataApi) => {
      driveCreateData = data;
      return plannerRepo.createDrive(facilityId, data, { agencyId });
    },
    {
      onSuccess: () => {
        toast.success(t('mobility.msgDrivePublished'));
        onSubmit();
      },
      onError: (e: any) => {
        if (e.response && e.response.data?.errors?.length && driveCreateData?.recurring_option) {
          setRecurringForceCreate(true);
          setCreateRecurringErrors(getRecurringDriveErrorsText(e));
        } else {
          toast.error(getErrors(e.response.data));
        }
      },
    },
  );

  const { mutate: updateDrive, isLoading: isUpdateDrivesLoading } = useMutation(
    'planner-drive-update',
    (data: DriveUpdateApi) => plannerRepo.updatePlannerDrive(facilityId, driveId!, data),
    {
      onSuccess: () => {
        toast.success(t('mobility.msgDriverUpdated'));
        onSubmit();
      },
      onError: (e: any) => {
        toast.error(getErrors(e.response.data));
      },
    },
  );

  const { isLoading: isDriveCancelProcess, mutateAsync: cancelDrive } = useMutation(
    'cancel-drive',
    () => plannerRepo.cancelDrive(facilityId, driveId!),
    {
      onSuccess: () => {
        onSubmit?.();
        toast.success(t('mobility.msgDriveCancel'));
      },
      onError: (e: AxiosError) => {
        if (e.message) toast.error(t('common.errorMsgDefault'));
      },
    },
  );

  const isEditProcess = !!formData?.id;
  const isNotEditable = isEditProcess && formData?.driveStatus !== DriveStatus.NotStarted;

  const driverOptions = mapDriverOptions(drivers);
  const paxOptions = mapPaxOptions(vehicles, formData?.vehicleId! || vehicle!);
  const vehicleOptions = mapVehicleOptions(vehicles);

  const dropTownOpt = findOption(dropoffTownOptions, dropoffTown);
  const pickTownOpt = findOption(pickupTownOptions, pickupTown);
  const dropLocOpt = findOption(dropLocChoices?.[dropTownOpt?.value!], dropoffLocation);
  const pickLocOpt = findOption(pickLocChoices?.[pickTownOpt?.value!], pickupLocation);
  const dropoffLocOption = getDefaultOption(dropLocOpt, dropoffLocation);
  const dropoffTownOption = getDefaultOption(dropTownOpt, dropoffTown);
  const pickupLocOption = getDefaultOption(pickLocOpt, pickupLocation);
  const pickupTownOption = getDefaultOption(pickTownOpt, pickupTown);
  const dropoffLocationPredefined = dropLocChoices ? dropoffLocOption : dropoffLocation;
  const dropoffTownPredefined = dropLocChoices ? dropoffTownOption : dropoffTown;
  const pickupLocationPredefined = pickLocChoices ? pickupLocOption : pickupLocation;
  const pickupTownPredefined = pickLocChoices ? pickupTownOption : pickupTown;
  const initLocation = dropLocChoices && pickLocChoices ? undefined : '';
  const initTown = dropLocChoices && pickLocChoices ? undefined : city;

  const initDriveData: Record<string, unknown> = {
    commentToDriver,
    commentToPax,
    driver: findOption(driverOptions, isEditProcess ? driver : formData?.driverId),
    dropoffDate: isEditProcess
      ? parse(dropoffDate!, DateFormat.ApiDateAlt, new Date())
      : formData?.resDate!,
    dropoffLocation: isEditProcess ? dropoffLocationPredefined : initLocation,
    dropoffTime: isEditProcess
      ? parse(dropoffTime!, DateFormat.ApiTimeFull, new Date())
      : addMinutes(getSoonTime(formData?.resDate!), TIME_INTERVAL),
    dropoffTown: isEditProcess ? dropoffTownPredefined : initTown,
    pickupDate: isEditProcess
      ? parse(pickupDate!, DateFormat.ApiDateAlt, new Date())
      : formData?.resDate!,
    pickupLocation: isEditProcess ? pickupLocationPredefined : initLocation,
    pickupTime: isEditProcess
      ? parse(pickupTime!, DateFormat.ApiTimeFull, new Date())
      : getSoonTime(formData?.resDate!),
    pickupTown: isEditProcess ? pickupTownPredefined : initTown,
    vehicle: findOption(vehicleOptions, isEditProcess ? vehicle : formData?.vehicleId),
  };

  const initPaxData: Record<string, unknown>[] = Array.from({ length: paxOptions?.length }, () => ({
    agency: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    requestingUnit: '',
  }));

  const initFormData: Record<string, unknown> = {
    mainInfo: isEditProcess
      ? {
          agency: { label: agencyShortName, value: agencyFk },
          email,
          firstName,
          indexNumber,
          lastName,
          managerEmail,
          noPax: !hasPassengers,
          pax: paxOptions.find((i) => +i.value === pax),
          passengersInfo: passengersInfo?.length ? passengersInfo : initPaxData,
          phoneNumber,
          remarks,
        }
      : { passengersInfo: initPaxData },
    returnTrip: {
      driveList: [initDriveData],
    },
    tripInfo: {
      driveList: [initDriveData],
      driveListMultileg: isEditProcess ? [initDriveData] : [initDriveData, initDriveData],
      purpose: findOption(purposes, purpose),
      transferType: isEditProcess ? transferType : '',
      typeOfTrip: findOption(tripTypeOpt, typeOfTrip),
    },
  };

  const handleSubmit = (data: FormState) => {
    const formState = { ...(data.values as unknown as DriveFormState) };
    const { addRecurring, tripInfo, returnTrip } = formState;

    if (isEditProcess) {
      const {
        mainInfo: { requestingUnit: unit, ...rest },
        tripInfo: tripData,
      } = data.modified as unknown as DriveFormState;
      const modifiedFields = {
        ...(unit.value === requestingUnit ? { ...rest } : { unit, ...rest }),
        ...tripData,
      };

      if (!Object.keys(modifiedFields).length) {
        return toast.error(t('planner.msgNoChanges'));
      }
    }

    if (
      tripInfo.typeOfTrip?.value === TripType.MultiLeg &&
      tripInfo.driveListMultileg?.length &&
      checkOverlappingDrives(tripInfo.driveListMultileg)
    ) {
      return toast.error(t('planner.msgOverlappingDrives'));
    }

    if (!addRecurring) {
      if (tripInfo.typeOfTrip?.value === TripType.MultiLeg) {
        const sharedDrive = tripInfo.driveListMultileg?.map((d: DriveCreate) =>
          calcSharedDrive({
            drives: drives.filter((i: PlDrive) => i.id !== driveId!),
            dropoffDate: d.dropoffDate,
            dropoffTime: d.dropoffTime,
            pickupDate: d.pickupDate,
            pickupTime: d.pickupTime,
            vehicle: d.vehicle,
          }),
        );

        if (sharedDrive?.some((d: PlDrive | null) => d?.id)) {
          return toast.error(t('planner.msgSharedDrive'));
        }
      } else {
        const driveList = [...(tripInfo.driveList || [])];

        if (
          !isEditProcess &&
          tripInfo.typeOfTrip?.value === TripType.RoundTrip &&
          returnTrip?.driveList
        ) {
          driveList.push(...(returnTrip?.driveList || []));
        }

        const conflict = driveList?.map((d: DriveCreate) =>
          checkConflictDrives({
            driver: d.driver,
            drives: drives.filter((i: PlDrive) => i.id !== driveId!),
            dropoffDate: d.dropoffDate,
            dropoffTime: d.dropoffTime,
            pickupDate: d.pickupDate,
            pickupTime: d.pickupTime,
            vehicle: d.vehicle,
          }),
        );

        const [conflictType] = conflict;

        if (conflictType) {
          const errorMsg =
            conflictType === 'driver'
              ? t('planner.msgConflictDrives')
              : `${t('bookingDetails.selected')} ${conflictType} ${t(
                  'bookingDetails.msgConflictMulti',
                )}`;

          return toast.error(errorMsg);
        }
      }
    }

    if (driveId && isEditProcess) {
      updateDrive(Serializer.mapPlannerDriveToUpdate({ data: formState, driveId }));
    } else {
      createDrive(Serializer.mapPlannerDriveForm(formState));
    }
  };

  const isLoading = isEditProcess
    ? !drive || !purposes?.length || isUpdateDrivesLoading || isDriveCancelProcess
    : isCreateDrivesLoading;

  return (
    <>
      {isLoading && <Loader spinning />}
      {!isLoading && !isTimeSlotEdit && !showRecurringForceCreate && (
        <>
          {refCode && <div className="booking-ref-code">Ref code: {refCode}</div>}
          <Form
            className={isNotEditable ? 'drive-form form-disabled' : 'drive-form'}
            initialValues={initFormData}
            onSubmit={handleSubmit}
          >
            {isNotEditable && (
              <div className="form-not-editable-warn">{t('planner.msgDriveStatus')}</div>
            )}

            <Multistep>
              {(): JSX.Element => (
                <>
                  <MainStep
                    bookingState={state!}
                    drive={drive}
                    formData={formData}
                    paxOptions={paxOptions}
                    onCancel={onCancel}
                    onCancelDrive={cancelDrive}
                    onTimeSlotEdit={() => setTimeSlotEdit(true)}
                  />
                  <TripStep
                    drive={drive}
                    drives={drives}
                    driverOptions={driverOptions}
                    dropoffLocationChoices={dropLocChoices}
                    dropoffTownOptions={dropoffTownOptions}
                    formData={formData}
                    initMultileg={initDriveData}
                    pickupLocationChoices={pickLocChoices}
                    pickupTownOptions={pickupTownOptions}
                    purposeOptions={purposes}
                    tripTypeOptions={tripTypeOpt}
                    vehicleOptions={vehicleOptions}
                    vehicles={vehicles}
                    setStepReturnTripEnabled={setStepReturnTripEnabled}
                  />
                  {stepReturnTripEnabled ? (
                    <>
                      <ReturnTripStep
                        drive={drive}
                        drives={drives}
                        driverOptions={driverOptions}
                        dropoffLocationChoices={dropLocChoices}
                        dropoffTownOptions={dropoffTownOptions}
                        formData={formData}
                        pickupLocationChoices={pickLocChoices}
                        pickupTownOptions={pickupTownOptions}
                        vehicleOptions={vehicleOptions}
                        vehicles={vehicles}
                      />
                      <ApprovingSharedDriveStep />
                    </>
                  ) : (
                    <ApprovingSharedDriveStep />
                  )}
                </>
              )}
            </Multistep>
          </Form>
        </>
      )}
      {!isLoading && isTimeSlotEdit && <TimeSlotEdit data={drive!} onSubmit={onSubmit} />}
      {!isLoading && showRecurringForceCreate && (
        <RecurringForceCreate
          errors={createRecurringErrors}
          onCancel={onCancel}
          onConfirm={async () => {
            createDrive({ ...driveCreateData, force_create: true });
            toast.warning(`${t('common.recurring.successMsg')}.\n ${createRecurringErrors}`);
          }}
        />
      )}
    </>
  );
};

export default DriveForm;
