/* istanbul ignore file */
import { useEffect } from 'react';
import { t } from 'i18next';
import { addMinutes, isAfter, isEqual, isSameDay, parse, setHours, setMinutes } from 'date-fns';
import { useFormApi } from 'informed';

import { DateField } from '@ui-modules/informed';
import { TIME_INTERVAL } from '@common/constants';
import { DropdownOption, ShuttleStop } from '@common/interfaces';
import { useFieldState } from '@common/hooks';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

type FieldProps = { fieldGroup?: string; scheduled?: ShuttleStop[] };

const DropoffTimeField = ({ fieldGroup, scheduled }: FieldProps) => {
  const { setValue } = useFormApi();
  const { value: dropoffDate } = useFieldState<Date>('dropoffDate');
  const { value: dropoffTime } = useFieldState<Date>('dropoffTime');
  const { value: pickupDate } = useFieldState<Date>('pickupDate');
  const { value: pickupTime } = useFieldState<Date>('pickupTime');
  const { value: dropoffLocation } = useFieldState<DropdownOption>('dropoffLocation');

  const isSameDate = pickupDate && dropoffDate && isSameDay(pickupDate, dropoffDate);
  const minEndTime = addMinutes(pickupTime, TIME_INTERVAL);

  let confTime: string | undefined;
  if (scheduled && dropoffLocation) {
    confTime = scheduled.find((i) => i.location === dropoffLocation?.value)?.pickupTime;
  }

  useEffect(() => {
    if (isSameDate && (isAfter(pickupTime, dropoffTime) || isEqual(pickupTime, dropoffTime))) {
      setValue(fieldGroup ? `${fieldGroup}.dropoffTime` : 'dropoffTime', minEndTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupTime]);

  useEffect(() => {
    if (confTime) {
      const time = parse(confTime, 'HH:mm', new Date());
      setValue(fieldGroup ? `${fieldGroup}.dropoffTime` : 'dropoffTime', time);
    } else if (scheduled && dropoffLocation) {
      const lastStop = scheduled[scheduled.length - 1]?.pickupTime;
      setValue(
        fieldGroup ? `${fieldGroup}.dropoffTime` : 'dropoffTime',
        lastStop ? parse(lastStop, 'HH:mm', new Date()) : addMinutes(pickupTime, TIME_INTERVAL),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confTime, dropoffLocation, fieldGroup, scheduled]);

  return (
    <div className="field field-time" data-testid="planner-drive-form-field-dropoff-time">
      <DateField
        className="input"
        dateFormat="HH:mm"
        disabled={!!confTime}
        label={t('bookingDetails.dropoffTime')}
        maxTime={isSameDate ? setHours(setMinutes(new Date(), 45), 23) : undefined}
        minTime={
          isSameDate
            ? setHours(setMinutes(new Date(), minEndTime.getMinutes()), minEndTime.getHours())
            : undefined
        }
        name="dropoffTime"
        readOnly={!!confTime}
        showTimeSelect
        showTimeSelectOnly
        timeCaption={undefined}
        timeFormat="HH:mm"
        timeIntervals={TIME_INTERVAL}
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default DropoffTimeField;
