/* istanbul ignore file */
import type { PrivateVehicleCreateApi } from '@common/interfaces';
import { format } from 'date-fns';
import { DateFormat } from '@common/types';
import { FormType } from '@pages/PrivateVehicleFormPage/utils';
import { PrivateVehicleFormType } from '../schema/PrivateVehicleForm.schema';

class PVSerializer {
  public static async mapFormDataToPrivateVehicleCreate(
    data: PrivateVehicleFormType,
  ): Promise<PrivateVehicleCreateApi> {
    const {
      agency,
      contractType,
      country,
      dateFrom,
      dateTo,
      dutyStation,
      profileEmail,
      firstName,
      formType,
      isDriverNeeded,
      lastName,
      contactEmail,
      position,
      preferredTypeOfVehicle,
      purpose,
      reason,
      entryOnDuty,
      notToExceed,
    } = data;

    const submitData = {
      type_of_request: 'private_vehicle_request',
      data: {
        agency,
        contact_email: contactEmail || [],
        contract_type: contractType,
        country,
        date_from: dateFrom ? format(dateFrom, DateFormat.ApiDate) : '',
        date_to: dateTo ? format(dateTo, DateFormat.ApiDate) : '',
        duty_station: dutyStation,
        profile_email: profileEmail,
        entry_on_duty: entryOnDuty ? format(entryOnDuty, DateFormat.ApiDate) : '',
        first_name: firstName,
        last_name: lastName,
        is_booking_on_behalf_of_colleague: formType === FormType.Colleague,
        is_driver_needed: Boolean(isDriverNeeded!),
        not_to_exceed: notToExceed ? format(notToExceed, DateFormat.ApiDate) : '',
        position,
        preferred_type_of_vehicle: preferredTypeOfVehicle,
        purpose,
        reason,
      },
    };

    return submitData;
  }
}

export default PVSerializer;
