import { useTranslation } from 'react-i18next';
import { DropdownOption } from '@common/interfaces';
import { InputText, Select } from '@components';

type SectionProps = { ctrl: any; pax: number; requestUnits: DropdownOption[] };

const EmergencySection = ({ ctrl, pax, requestUnits }: SectionProps) => {
  const { t } = useTranslation();

  const renderEmergencyFieldSet = (idx: number): JSX.Element => (
    <fieldset key={idx}>
      <legend data-testid={`bf-passenger-details-legend-${idx}`}>
        {t('common.passenger')} {idx + 2} details
      </legend>
      <InputText
        name={`passengersInfo.${idx}.firstName`}
        className="field"
        control={ctrl}
        label={t('common.firstName')}
        required
        data-testid={`bf-passenger-first-name-${idx}`}
      />
      <InputText
        name={`passengersInfo.${idx}.lastName`}
        className="field"
        control={ctrl}
        label={t('common.surname')}
        required
        data-testid={`bf-passenger-last-name-${idx}`}
      />
      <InputText
        name={`passengersInfo.${idx}.email`}
        className="field"
        control={ctrl}
        label={t('common.email')}
        required
        data-testid={`bf-passenger-email-${idx}`}
      />
      <InputText
        name={`passengersInfo.${idx}.agency`}
        className="field"
        control={ctrl}
        label={t('common.agency')}
        required
        data-testid={`bf-passenger-agency-${idx}`}
      />
      <InputText
        name={`passengersInfo.${idx}.phoneNumber`}
        className="field"
        control={ctrl}
        label={t('common.phoneNumber')}
        required
        data-testid={`bf-passenger-phone-number-${idx}`}
      />
      <Select
        name={`passengersInfo.${idx}.requestingUnit`}
        className="field"
        control={ctrl}
        label={t('common.requestingUnit')}
        options={requestUnits}
        required
        data-testid={`bf-passenger-requesting-unit-${idx}`}
      />
    </fieldset>
  );

  return <>{Array.from({ length: pax }, (_, i) => renderEmergencyFieldSet(i))}</>;
};

export default EmergencySection;
