/* istanbul ignore file */
import { type FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isSameDay } from 'date-fns';
import { Multistep, useFormApi, useFormState, useMultistepApi } from 'informed';

import {
  Drive,
  DriveFormState,
  DriveFormSteps as Steps,
  DropdownOption,
  EventMoved,
  PlDrive,
  PlDriveFormData,
  PlDriver,
  PlVehicle,
  ShuttleStop,
  StepOptions,
  TransferType,
  TripType,
  VerifiedShuttleType,
} from '@common/interfaces';
import {
  calcSharedDrive,
  checkConflictDrives,
  checkShuttleConflictRange,
  checkShuttleType,
  getFullDate,
} from '@common/utils';
import { useFacility, useFieldState } from '@common/hooks';
import { Button } from '@components';
import { ArrowLeft, ArrowRight, Check } from '@assets/svg/icons';

import RecurDrive from '../RecurDrive';
import TripStepFieldset from './TripStepFieldset';
import { ButtonSubmit, PurposeField, TransferTypeField, TripTypeField } from '../../../../fields';
import { findOption, mapLocationOptions } from '../../../../utils';
import '../../../../styles/modal.scss';

interface StepProps {
  drive?: Drive;
  drivers: PlDriver[];
  drives: PlDrive[];
  eventMoved?: EventMoved;
  formData: PlDriveFormData | null;
  initMultileg: Record<string, unknown>;
  options: StepOptions;
  showGoogleLocation: boolean;
  shuttleStops?: ShuttleStop[];
  vehicles: PlVehicle[];
  setStepReturnTripEnabled: (value: boolean) => void;
}

const TripStep: FC<StepProps> = ({
  drive,
  drivers,
  drives,
  eventMoved,
  formData,
  initMultileg,
  options,
  showGoogleLocation,
  shuttleStops: stops,
  vehicles,
  setStepReturnTripEnabled,
}) => {
  const { t } = useTranslation();
  const { facility } = useFacility();
  const { values } = useFormState() as any;
  const { getValue, setValue, submitForm } = useFormApi();
  const { next, setCurrent } = useMultistepApi();
  const { driverOpt, purposeOpt, tripTypeOpt, vehicleOpt } = options;
  const { value: transferType } = useFieldState<TransferType>(`${Steps.Trip}.transferType`);
  const { value: tripType } = useFieldState<DropdownOption>(`${Steps.Trip}.typeOfTrip`);

  const isEditProcess = !!formData?.id;
  const isRoundTrip = tripType?.value === TripType.RoundTrip;
  const isMultiLeg = tripType?.value === TripType.MultiLeg && transferType !== TransferType.Airport;
  const isShuttle = transferType === TransferType.Shuttle;
  const fieldGroup = isMultiLeg ? 'driveListMultileg' : 'driveList';
  const formState = { ...(values as DriveFormState)?.[Steps.Trip] };
  const { driver, dropoffDate, dropoffTime, pickupDate, pickupTime, vehicle } =
    formState?.[fieldGroup]?.[0] || {};
  const drivesRest = drives.filter((i: PlDrive) => i.id !== drive?.id);
  const showRecurringBtn =
    !isEditProcess &&
    (transferType === TransferType.Airport || (tripType && tripType?.value !== TripType.RoundTrip));

  const sharedDrive = useMemo(
    () => calcSharedDrive({ drives: drivesRest, ...formState?.[fieldGroup]?.[0] }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [driver, dropoffDate, dropoffTime, pickupDate, pickupTime, vehicle],
  );

  const [shuttleStops, setShuttleStops] = useState<ShuttleStop[] | null>(stops ?? null);
  const [verifiedShuttle, setVerifiedShuttle] = useState<VerifiedShuttleType | null>(null);

  const locOpt = useMemo(
    () => mapLocationOptions(shuttleStops?.map((i) => i.location)),
    [shuttleStops],
  );

  const formatTripStepFields = (config?: ShuttleStop[]) => {
    const fieldset = `${Steps.Trip}.driveList.0.`;
    const pickup = getValue(`${fieldset}pickupLocation`);
    const dropoff = getValue(`${fieldset}dropoffLocation`);
    const pickupLoc = typeof pickup === 'object' ? (pickup as DropdownOption)?.value : pickup;
    const dropoffLoc = typeof dropoff === 'object' ? (dropoff as DropdownOption)?.value : dropoff;

    if (config) {
      const start = findOption(
        mapLocationOptions(config?.map((i) => i.location)),
        config[0].location,
      );
      const end = findOption(
        mapLocationOptions(config?.map((i) => i.location)),
        config[config.length - 1].location,
      );

      setValue(`${fieldset}pickupLocation`, start);
      setValue(`${fieldset}dropoffLocation`, end);
    } else {
      setValue(`${fieldset}pickupLocation`, pickupLoc);
      setValue(`${fieldset}dropoffLocation`, dropoffLoc);
    }
  };

  useEffect(() => {
    if (dropoffDate && dropoffTime && pickupDate && pickupTime && vehicle) {
      const currentDate = formData?.resDate;
      const verified = checkShuttleType({
        drives: drivesRest,
        end: getFullDate(dropoffDate!, dropoffTime!),
        start: getFullDate(pickupDate!, pickupTime!),
        transferType,
        vehicle: vehicle?.value!,
      });

      if (isSameDay(pickupDate!, currentDate!) && isSameDay(dropoffDate!, currentDate!)) {
        const { scheduledRouteId, transferType: verifiedType, typeChanged } = verified ?? {};
        const { transferType: bookingType } = drive?.booking ?? {};

        if (
          isEditProcess &&
          bookingType !== TransferType.Shuttle &&
          verifiedType === TransferType.Shuttle
        ) {
          const shuttleDrive = drives.find((i) => i.scheduledRouteId === scheduledRouteId);
          const relatedShuttleStops = shuttleDrive?.shuttleConfig?.stops;
          if (!shuttleStops) formatTripStepFields(relatedShuttleStops);
          setShuttleStops(relatedShuttleStops!);
        } else if (
          isEditProcess &&
          bookingType === TransferType.Shuttle &&
          verifiedType !== TransferType.Shuttle
        ) {
          setShuttleStops(null);
          formatTripStepFields();
        } else if (isEditProcess) {
          setShuttleStops(stops ?? null);
        }

        if (verifiedType === TransferType.Shuttle) {
          const shuttleConflict = checkShuttleConflictRange({
            driver: driver?.value!,
            drives,
            end: getFullDate(dropoffDate, dropoffTime),
            start: getFullDate(pickupDate, pickupTime),
            vehicle: vehicle.value!,
          });

          if (shuttleConflict && !typeChanged && shuttleConflict === 'time') {
            toast.error(t('planner.warnShuttleConflict'));
          }
        }

        if (verifiedType === TransferType.Shuttle && !typeChanged) return;

        setVerifiedShuttle(verified);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver, dropoffDate, dropoffTime, pickupDate, pickupTime, vehicle]);

  useEffect(() => {
    if (formData?.scheduled) {
      const { bookingPurpose, bookingTypeOfTrip } = formData?.scheduled || {};
      setValue(`${Steps.Trip}.purpose`, findOption(purposeOpt, bookingPurpose ?? ''));
      setValue(`${Steps.Trip}.typeOfTrip`, bookingTypeOfTrip || TripType.OneWay);
    }
  }, [formData?.scheduled, purposeOpt, setValue]);

  useEffect(() => {
    if (!isEditProcess && tripType) {
      setStepReturnTripEnabled(tripType?.value === TripType.RoundTrip);
    }
  }, [isEditProcess, setStepReturnTripEnabled, tripType]);

  const onVerifiedClick = () => {
    if (!formState?.purpose) return submitForm();

    const conflict = checkConflictDrives({ drives: drivesRest, ...formState?.[fieldGroup]?.[0] });

    if (conflict) {
      return toast.error(
        conflict === 'driver'
          ? t('planner.msgConflictDrives')
          : `${t('bookingDetails.selected')} ${conflict} ${t('bookingDetails.msgConflictMulti')}`,
      );
    }

    if (verifiedShuttle?.typeChanged && (isRoundTrip || isMultiLeg)) {
      return toast.error(`${t('planner.msgConflictTypes')}`);
    }

    setCurrent(Steps.Confirm);
  };

  const getNextBtn = (): JSX.Element | null => {
    if (!transferType) return null;
    if (verifiedShuttle) {
      return (
        <Button
          leftIcon={<Check />}
          text={t('common.btnConfirm')}
          variant="submit"
          onClick={onVerifiedClick}
        />
      );
    }
    if (isEditProcess) return <ButtonSubmit />;
    if (isRoundTrip) {
      return (
        <Button
          data-testid="planner-drive-form-btn-next"
          rightIcon={<ArrowRight />}
          text={t('common.btnNext')}
          variant="primary"
          onClick={next}
        />
      );
    }
    if (!isRoundTrip && !isShuttle && sharedDrive) {
      return (
        <Button
          data-testid="planner-drive-form-btn-save"
          leftIcon={<Check />}
          text={t('common.btnConfirm')}
          type="submit"
          variant="submit"
          onClick={next}
        />
      );
    }

    return <ButtonSubmit />;
  };

  return (
    <Multistep.Step step={Steps.Trip}>
      <nav className="steps">
        <Button
          className="btn"
          data-testid="planner-drive-form-btn-main-info"
          text={t('planner.mainInfo')}
          variant="transparent"
          onClick={() => setCurrent(Steps.Main)}
        />
        <Button
          className="btn"
          data-testid="planner-drive-form-btn-trip-info"
          disabled
          text={t('planner.tripInfo')}
          variant="transparent"
          onClick={() => setCurrent(Steps.Trip)}
        />
      </nav>

      <section className={`fieldset ${isMultiLeg ? 'multi-leg' : ''}`}>
        <div className="row">
          <TransferTypeField
            city={facility?.city}
            fieldGroup={fieldGroup}
            isEdit={isEditProcess}
            isScheduled={!!formData?.scheduled}
          />
        </div>

        {transferType && (
          <>
            {transferType !== TransferType.Airport && (isEditProcess || !isShuttle) && (
              <div className="row">
                <TripTypeField isEdit={isEditProcess} options={tripTypeOpt} />
              </div>
            )}

            {(tripType || transferType === TransferType.Airport) && (
              <>
                <TripStepFieldset
                  drive={drive}
                  drivers={drivers}
                  eventMoved={eventMoved}
                  initMultileg={initMultileg}
                  isEditProcess={isEditProcess}
                  options={{ driverOpt, vehicleOpt, locOpt }}
                  sharedDrive={sharedDrive}
                  showGoogleLocation={showGoogleLocation}
                  shuttleStops={shuttleStops!}
                  transferType={transferType}
                  tripType={tripType?.value as TripType}
                  vehicles={vehicles}
                />

                {(!!shuttleStops?.length || transferType !== TransferType.Airport) && (
                  <div className="row">
                    <PurposeField options={purposeOpt} />
                  </div>
                )}

                {values.addRecurring &&
                (transferType === TransferType.Airport ||
                  tripType?.value !== TripType.RoundTrip) ? (
                  <div className="row">
                    <RecurDrive
                      currentDate={formData?.resDate}
                      isMultileg={isMultiLeg}
                      step={Steps.Trip}
                    />
                  </div>
                ) : null}
              </>
            )}

            <footer>
              <Button
                className="btn-back"
                data-testid="planner-drive-form-btn-back"
                leftIcon={<ArrowLeft />}
                text={t('common.btnBack')}
                variant="transparent"
                onClick={() => setCurrent(Steps.Main)}
              />

              <div>
                {!isShuttle &&
                  showRecurringBtn &&
                  (values.addRecurring ? (
                    <Button
                      onClick={() => setValue('addRecurring', false)}
                      text={t('common.recurring.cancelRecurringBtn')}
                      variant="primary"
                    />
                  ) : (
                    <Button
                      onClick={() => setValue('addRecurring', true)}
                      text={t('common.recurring.addRecurringBtn')}
                      variant="primary"
                    />
                  ))}

                {getNextBtn()}
              </div>
            </footer>
          </>
        )}
      </section>
    </Multistep.Step>
  );
};

export default TripStep;
