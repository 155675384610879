/* istanbul ignore file */
import { Dispatch, FC, Fragment, SetStateAction, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form, FormApi, FormState } from 'informed';
import cn from 'classnames';

import { Button, Loader } from '@components';
import { useRepository } from '@context';
import { useFacility } from '@common/hooks';
import { checkConflictDriveOnEdit, formatDateString, getErrors, Serializer } from '@common/utils';
import { ArrowRound, Dropoff } from '@assets/svg/icons';
import {
  BookingDetails,
  BookingTripType,
  BDDrive,
  DriveState,
  DriveStatus,
  DriveUpdate,
  FormDrive,
  IBookingUpdate,
  PlAcceptDrive,
  PlDrive,
  PlDriver,
  PlResource,
  PlVehicle,
  TransferType,
  BookingRouteReject,
} from '@common/interfaces';
import { AcceptDriveForm, BookingInfo, EditDriveFormItem } from './components';
import './BookingPanel.styles.scss';

interface BookingPanelProps {
  acceptDrives?: PlAcceptDrive[];
  booking: BookingDetails;
  drivesData?: PlDrive[];
  drivers: PlDriver[];
  isAccept?: boolean;
  isEdit?: boolean;
  resource?: [PlResource | null, Dispatch<SetStateAction<PlResource | null>>];
  showShuttle?: boolean;
  setAcceptDrives?: Dispatch<SetStateAction<PlAcceptDrive[]>>;
  setDate?: Dispatch<SetStateAction<Date | null>>;
  vehicles: PlVehicle[];
  onDriveCancel?: () => void;
}

const BookingPanel: FC<BookingPanelProps> = ({
  acceptDrives,
  booking,
  drivesData,
  drivers,
  isAccept,
  isEdit,
  resource,
  showShuttle = false,
  setAcceptDrives,
  setDate,
  vehicles,
  onDriveCancel,
}) => {
  const { facility, facilityId } = useFacility();
  const { bookingRepository, plannerRepo } = useRepository();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const refEditDriveForm = useRef<FormApi | null>(null);

  const [isDriveEditFormTouched, setDriveEditFormTouched] = useState(false);
  const [isVerified, setVerified] = useState(false);

  const { mutateAsync: updateDrives, isLoading: isUpdateProcess } = useMutation(
    (drives: DriveUpdate[]) => plannerRepo.updateDrive(facilityId, booking?.id!, drives),
    {
      onSuccess: () => {
        if (isEdit)
          setTimeout(() => navigate(`${pathname.split('/').slice(0, -2).join('/')}`), 500);
        toast.success(t('mobility.msgUpdateDrive'));
      },
      onError: (e: any) => {
        if (e.response && Object.values(e.response?.data.errors).length) {
          toast.error(getErrors(e.response.data.errors) || t('common.errorMsgDefault'));
        }
      },
    },
  );

  const { mutateAsync: cancelDrive, isLoading: isCancelProcess } = useMutation(
    (driveId: string) => plannerRepo.cancelDrive(facilityId, driveId),
    {
      onSuccess: () => {
        onDriveCancel?.();
        toast.success(t('mobility.msgDriveCancel'));
      },
      onError: (e: any) => {
        if (e.response && Object.values(e.response?.data.errors).length) {
          toast.error(getErrors(e.response.data.errors) || t('common.errorMsgDefault'));
        }
      },
    },
  );

  const { mutateAsync: rejectBooking, isLoading: isRejectProcess } = useMutation(
    (data: IBookingUpdate) =>
      bookingRepository.rejectBooking(facilityId, facility?.agencyId!, booking?.id!, data),
    {
      onSuccess: () => {
        toast.warning(t('booking.msgBookingRejected'));
        navigate(`${pathname.split('/').slice(0, -2).join('/')}`);
      },
      onError: (e: any) => {
        if (e.response) toast.error(getErrors(e?.response?.data));
      },
    },
  );

  const { mutateAsync: rejectRoute, isLoading: isRejectRouteProcess } = useMutation(
    (payload: BookingRouteReject) => bookingRepository.rejectRoute(facilityId, payload),
    {
      onSuccess: () => {
        toast.warning(t('booking.msgRouteRejected'));
      },
      onError: (e: any) => {
        if (e.response) toast.error(getErrors(e?.response?.data));
      },
    },
  );

  const { drives, routes, transferType, typeOfTrip } = booking;
  const isOneWay = typeOfTrip === BookingTripType.OneWay;
  const isRoundTrip = typeOfTrip === BookingTripType.RoundTrip;
  const isMultiLeg = typeOfTrip === BookingTripType.MultiLeg;
  const linkBookings = pathname.split('/').slice(0, -2).join('/');

  const onBtnVerifyClick = (): void => {
    const form = refEditDriveForm.current?.getFormState() as FormState;

    if (form.errors && Object.keys(form.errors).length) {
      toast.error(t('common.errorMsgDefault'));
    }

    const state = Object.values({ ...(form.values as DriveState) });
    const activeDrives = state.filter((_, i: number) => drives[i].state === DriveStatus.NotStarted);
    const isConflict = checkConflictDriveOnEdit(activeDrives);

    if (isConflict) {
      toast.error(t('planner.msgConflictingDrivesEdit'));
      return;
    }

    setVerified(true);
    toast.success(t('planner.msgDriveVerified'));
  };

  const currentAcceptDriveIdx = useMemo(
    () => acceptDrives?.findIndex((drive: PlAcceptDrive) => !drive.accepted && !drive.rejected),
    [acceptDrives],
  );
  const isLoading = isCancelProcess || isRejectProcess || isRejectRouteProcess || isUpdateProcess;

  return (
    <Loader spinning={isLoading}>
      <section className={cn('booking-panel', { 'theme-dark': showShuttle })}>
        <BookingInfo booking={booking} showShuttle={showShuttle} />

        <div className="form-group" data-testid="planner-booking-panel-form-accept">
          {isAccept && (
            <>
              {acceptDrives?.map((data: PlAcceptDrive, idx: number) => {
                if (currentAcceptDriveIdx! < idx) return;
                const route = routes[idx];

                return (
                  <Fragment key={data.routeNumber}>
                    {showShuttle ? (
                      <div className="form-title theme-dark">
                        <div>
                          <h3>
                            {t('bookingDetails.pickupAt')} <span>{route.pickupTime}</span>&nbsp;
                            {route.pickupTown}, {route.pickupLocation}
                          </h3>
                          {formatDateString(route.pickupDate, 'yyyy-MM-dd', 'dd MMM yyyy')}
                        </div>
                        <Dropoff />
                        <div>
                          <h3>
                            {t('bookingDetails.dropoffAt')}&nbsp;
                            {transferType === TransferType.Shuttle && (
                              <span>{route.dropoffTime}</span>
                            )}
                            &nbsp;
                            {route.dropoffTown}, {route.dropoffLocation}
                          </h3>
                          {formatDateString(route.dropoffDate, 'yyyy-MM-dd', 'dd MMM yyyy')}
                        </div>
                      </div>
                    ) : (
                      <h3 className="form-title">
                        {isRoundTrip && t(`bookingDetails.step${data.routeNumber}`)}
                        {isMultiLeg &&
                          `${t('bookingDetails.leg')} ${data.routeNumber}: ${t(
                            'bookingDetails.processTrip',
                          )}`}
                      </h3>
                    )}

                    <AcceptDriveForm
                      acceptDrives={acceptDrives}
                      drives={drivesData!}
                      driveIdx={idx}
                      drivers={drivers}
                      rejectBooking={rejectBooking}
                      rejectRoute={rejectRoute}
                      resource={resource}
                      showShuttle={showShuttle}
                      setAcceptDrives={setAcceptDrives!}
                      setDate={setDate!}
                      vehicles={vehicles}
                    />
                  </Fragment>
                );
              })}

              {!showShuttle && (
                <div className="form-footer">
                  <Link className="link" to={`${linkBookings}/${booking.id}`}>
                    {t('bookingDetails.viewBookingDetails')}
                    <ArrowRound type="right" />
                  </Link>
                </div>
              )}
            </>
          )}

          {isEdit && (
            <Form
              className={cn('form form-edit-drive', { 'theme-dark': showShuttle })}
              formApiRef={refEditDriveForm}
              onSubmit={(formState: Record<string, unknown>) => {
                const state = Object.values(formState.values as DriveState);
                const mappedDrives = state
                  .map((d: FormDrive, i: number) => Serializer.mapDriveEditToUpdate(d, drives[i]))
                  .filter((drive: DriveUpdate) => drive.state === DriveStatus.NotStarted);

                updateDrives(mappedDrives);
                setDriveEditFormTouched(false);
              }}
            >
              {!!(drivers.length && vehicles.length) &&
                drives?.map((drive: BDDrive) => (
                  <EditDriveFormItem
                    drive={drive}
                    drivers={drivers}
                    key={drive.id}
                    vehicles={vehicles}
                    onCancel={cancelDrive}
                    onChange={() => {
                      setDriveEditFormTouched(true);
                      setVerified(false);
                    }}
                  />
                ))}

              <div className="form-footer">
                {!showShuttle && (
                  <div className="form-footer">
                    <Link className="link" to={`${linkBookings}/${booking.id}`}>
                      {t('bookingDetails.viewBookingDetails')}
                      <ArrowRound type="right" />
                    </Link>
                  </div>
                )}

                {(isVerified || isOneWay) && (
                  <Button
                    className="btn btn-confirm"
                    text={t('common.btnConfirm')}
                    type="submit"
                    variant="submit"
                  />
                )}

                {!isOneWay && !isVerified && !!vehicles.length && (
                  <Button
                    className="btn btn-verify"
                    disabled={!isDriveEditFormTouched}
                    text={t('common.btnVerify')}
                    variant="warning"
                    onClick={() => onBtnVerifyClick()}
                  />
                )}
              </div>
            </Form>
          )}
        </div>
      </section>
    </Loader>
  );
};

export default BookingPanel;
