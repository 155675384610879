import { forwardRef } from 'react';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { Select, type SelectProps } from '@mantine/core';
import './InputDropdown.styles.scss';

type DropdownProps = { options?: { label: string; value: string }[] };

export type InputDropdownProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<SelectProps, 'data' | 'defaultValue' | 'value'> &
  DropdownProps;

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  icon: JSX.Element;
  label: string;
}

const DropdownItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ icon, label, ...rest }: ItemProps, ref) => (
    <div ref={ref} {...rest}>
      {icon}
      <span>{label}</span>
    </div>
  ),
);

export function InputDropdown<T extends FieldValues>({
  control,
  defaultValue,
  name,
  options = [],
  required,
  rules,
  shouldUnregister,
  onChange,
  ...props
}: InputDropdownProps<T>) {
  const {
    field: { value, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <Select
      classNames={{
        dropdown: 'dropdown-list',
        icon: 'dropdown-icon',
        input: 'dropdown-input',
        item: 'dropdown-item',
        label: 'dropdown-label',
        rightSection: 'dropdown-ctrl',
        wrapper: 'dropdown-wrapper',
        error: 'field-error',
      }}
      data={options}
      error={fieldState.error?.message}
      itemComponent={DropdownItem}
      searchable
      withAsterisk={!!required}
      onChange={onChange}
      {...field}
      {...props}
    />
  );
}
