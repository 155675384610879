import { WFPLogo } from '@assets/svg/logos';
import './Footer.styles.scss';

interface FooterProps {
  releaseVersion?: string;
}

const Footer = ({ releaseVersion }: FooterProps) => (
  <footer className="ddo-footer">
    <a href="http://www1.wfp.org/">
      <div>
        <span className="powered">powered by</span>
        <WFPLogo className="logo" />
      </div>
    </a>
    <span>{releaseVersion || null}</span>
    <span>{`${new Date().getFullYear()} © WFP`}</span>
  </footer>
);

export default Footer;
