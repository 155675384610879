/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';

import { IndInvoiceDetails, IndInvoiceDriveSet } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { Button } from '@components';
import { ArrowRound } from '@assets/svg/icons';
import './TripsDetails.styles.scss';

const TripsDetails = ({ data }: { data?: IndInvoiceDetails }) => {
  const { t } = useTranslation();

  const [isOpenDetails, setIsOpenDetails] = useState<boolean>(false);

  const formatDate = (date?: string, fromFormat?: string, toFormat?: string) =>
    date ? format(parse(date, fromFormat!, new Date()), toFormat!) : '-';

  const renderDriveDetails = (drive: IndInvoiceDriveSet, idx: number) => (
    <div className="row" key={drive.id}>
      <div>
        <h4>{idx === 1 ? t('bookingDetails.returnTrip') : t('bookingDetails.outwardTrip')}</h4>
        <span>Ref code: {data?.refCode}</span>
      </div>
      <ul className="details">
        {[
          { label: t('bookingDetails.pickupTown'), value: drive.pickupTown },
          { label: t('bookingDetails.pickupLocation'), value: drive.pickupLocation },
          {
            label: t('bookingDetails.pickupDate'),
            value: formatDate(drive.pickupDate, DF.ApiDate, DF.InvoiceInfoDate),
          },
          {
            label: t('bookingDetails.pickupTime'),
            value: formatDate(drive.pickupTime, DF.ApiTimeFull, DF.ApiTime),
          },
          { label: t('bookingDetails.dropoffTown'), value: drive.dropoffTown },
          { label: t('bookingDetails.dropoffLocation'), value: drive.dropoffLocation },
          {
            label: t('bookingDetails.dropoffDate'),
            value: formatDate(drive.dropoffDate, DF.ApiDate, DF.InvoiceInfoDate),
          },
          {
            label: `Est. ${t('bookingDetails.returnTime')}`,
            value: formatDate(drive.dropoffTime, DF.ApiTimeFull, DF.ApiTime),
          },
          { label: t('common.vehicle'), value: drive.vehicle },
          { label: t('common.driver'), value: drive.driver },
          { label: t('invoice.agencyDriver'), value: drive.driverAgency },
          { label: 'Km', value: drive.km },
          { label: t('common.amount'), value: `${drive.price} USD` },
          ...(drive.greenFund
            ? [{ label: t('common.greenFund'), value: `${drive.greenFund} USD` }]
            : []),
          { label: t('common.duration'), value: drive.duration },
        ].map(({ label, value }) => (
          <li key={label}>
            <div className="label">{label}</div>
            <div className="value">{value || ''}</div>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <section className="trip-details-container">
      <ul className="details">
        {[
          { label: t('common.typeOfTrip'), value: data?.typeOfTripDisplay },
          { label: t('common.transferType'), value: data?.transferTypeDisplay },
          { label: t('invoice.paxNumber'), value: data?.nOfPassengers },
          { label: t('invoice.departureTime'), value: data?.flightArrivalDepartureTime },
          { label: t('common.flightNumber'), value: data?.flightNumber },
          { label: t('common.budgetCode'), value: data?.budgetCode },
          { label: t('common.remarks'), value: data?.remarks },
        ].map(({ label, value }) => (
          <li key={label}>
            <div className="label">{label}</div>
            <div className="value">{value || ''}</div>
          </li>
        ))}
      </ul>

      <div className="more-details">
        <div className="more-details-panel" onClick={() => setIsOpenDetails(!isOpenDetails)}>
          <div className="more-details-panel-header">
            {isOpenDetails ? 'Hide' : 'Show'} {t('invoice.fullTripDetails')}
            <Button
              className="btn-more-details"
              icon={<ArrowRound className={isOpenDetails ? 'arrow-round-up' : undefined} />}
              variant="icon"
            />
          </div>
        </div>

        <div className={`${'content'} ${isOpenDetails ? 'open' : ''}`}>
          {data?.driveSet?.length &&
            data?.driveSet?.map((drive, idx) => renderDriveDetails(drive, idx))}
        </div>
      </div>
    </section>
  );
};

export default TripsDetails;
