/* eslint-disable react/no-unstable-nested-components */
import { FC, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Table, Pagination } from '@ui-modules';

import { useRepository } from '@context';
import { Button, Field, Form } from '@components';
import { Search } from '@assets/svg/icons';
import { MONTHS, CURRENT_MONTH, YEARS, CURRENT_YEAR, TABLE_ITEMS } from '@common/constants';
import { ApiList, DropdownOption, IndInvoiceList, IndInvoiceListApi } from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { getErrors, Serializer } from '@common/utils';
import { getIndInvoiceColumns } from './utils';
import './IndividualInvoiceListPage.styles.scss';

enum ReducerActionType {
  AGENCY = 'PASSENGER_AGENCY',
  CLEAR = 'CLEAR',
  KEYWORD = 'KEYWORD',
  MONTH = 'MONTH',
  PAGE = 'PAGE',
  YEAR = 'YEAR',
}

type ReducerAction = { type: ReducerActionType; payload?: string };

interface FormData {
  keyword: string;
  month: string;
  passenger_agency: string;
  year: string;
}

interface SearchData {
  keyword: string;
  month: string;
  page: string;
  passenger_agency: string;
  year: string;
}

const initSearchData: SearchData = {
  keyword: '',
  month: CURRENT_MONTH.value,
  page: '1',
  passenger_agency: '',
  year: CURRENT_YEAR.value,
};

const searchDataReducer = (state: SearchData, action: ReducerAction): SearchData => {
  switch (action.type) {
    case ReducerActionType.AGENCY:
    case ReducerActionType.KEYWORD:
    case ReducerActionType.MONTH:
    case ReducerActionType.YEAR:
      return { ...state, [action.type.toLowerCase()]: action.payload || '', page: '1' };
    case ReducerActionType.PAGE:
      return { ...state, page: action.payload || '' };
    case ReducerActionType.CLEAR:
      return { ...initSearchData };
    default:
      return state;
  }
};

const IndividualInvoiceListPage: FC = () => {
  const nav = useNavigate();
  const { t } = useTranslation();
  const { financialRepo } = useRepository();
  const { agencyName, facility, facilityId } = useFacility();
  const { agencyId, city } = facility;

  const [invoiceData, setInvoiceData] = useState<IndInvoiceList[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [searchData, dispatch] = useReducer(searchDataReducer, initSearchData);

  const { isLoading } = useQuery(
    ['get-bookings-for-invoices-list', searchData],
    () =>
      financialRepo.getBookingsForInvoiceList(facilityId, {
        agencyId,
        current_month: searchData.month,
        current_year: searchData.year,
        limit: 10,
        offset: (+searchData.page - 1) * 10,
        passenger_agency: searchData.passenger_agency,
        search: searchData.keyword,
      }),
    {
      enabled: !!agencyId,
      onError(error: any) {
        if (error.response) toast.error(getErrors(error.response.data));
      },
      onSuccess(data: ApiList<IndInvoiceListApi>) {
        setTotalItems(data.count);
        setInvoiceData(data.results.map(Serializer.formatIndividualInvoiceTable));
      },
    },
  );

  const { data: filtersData } = useQuery<{ passenger_agencies: DropdownOption[] }>(
    ['get-invoice-filters'],
    () => financialRepo.getInvoiceFilters(facilityId),
    { refetchOnWindowFocus: false },
  );

  return (
    <section className="invoice-list-page">
      <div className="header">
        <h1 className="title">{`${t('invoice.invoiceListTitle')}: ${city} | ${agencyName}`}</h1>
      </div>

      <div className="container">
        <div className="row">
          <Form
            className="form"
            defaultValues={initSearchData}
            schema={yup.object().shape({
              keyword: yup.string().nullable().optional(),
              month: yup.string().nullable().optional(),
              passenger_agency: yup.string().nullable().optional(),
              year: yup.string().nullable().optional(),
            })}
            onSubmit={({ keyword }: FormData) => {
              dispatch({ type: ReducerActionType.KEYWORD, payload: keyword });
            }}
          >
            {({ setValue }) => (
              <>
                <div className="field-search">
                  <Field
                    name="keyword"
                    className="search-input"
                    placeholder={t('invoice.inputInvoiceSearch')}
                  />
                  <Button
                    className="btn-search"
                    rightIcon={<Search />}
                    type="submit"
                    variant="primary"
                  />
                  <Button
                    className="btn-reset"
                    text={t('common.btnResetFilters')}
                    variant="link"
                    onClick={() => {
                      setValue('keyword', initSearchData.keyword);
                      setValue('month', CURRENT_MONTH.value);
                      setValue('year', CURRENT_YEAR.value);
                      setValue('passenger_agency', '');
                      dispatch({ type: ReducerActionType.CLEAR });
                    }}
                  />
                </div>

                <div className="filters">
                  <Field
                    name="passenger_agency"
                    input="dropdown"
                    className="agency"
                    label="Agency pax"
                    options={[
                      { label: t('common.allAgencies'), value: '' },
                      ...(filtersData?.passenger_agencies || []),
                    ]}
                    placeholder={t('common.allAgencies')}
                    onChange={(value: string) =>
                      dispatch({ type: ReducerActionType.AGENCY, payload: value })
                    }
                  />

                  <Field
                    name="month"
                    input="dropdown"
                    className="month"
                    label={t('common.month')}
                    options={[{ value: '', label: t('common.allMonths') }, ...MONTHS]}
                    onChange={(value: string) =>
                      dispatch({ type: ReducerActionType.MONTH, payload: value })
                    }
                  />

                  <Field
                    name="year"
                    input="dropdown"
                    className="year"
                    label={t('common.year')}
                    options={[{ value: '', label: t('common.allYears') }, ...YEARS]}
                    placeholder={t('common.allYears')}
                    onChange={(value: string) =>
                      dispatch({ type: ReducerActionType.YEAR, payload: value })
                    }
                  />
                </div>
              </>
            )}
          </Form>
        </div>

        <div className="row">
          <Table
            columns={getIndInvoiceColumns(nav, t)}
            data={invoiceData}
            isLoading={isLoading}
            variant="light"
          />
        </div>

        <div className="footer">
          {totalItems > TABLE_ITEMS ? (
            <Pagination
              selectedPage={+searchData.page}
              showJumper
              totalPages={totalItems}
              onPageChange={(selected: number): void =>
                dispatch({ type: ReducerActionType.PAGE, payload: selected.toString() })
              }
            />
          ) : (
            <div />
          )}
        </div>
      </div>
    </section>
  );
};

export default IndividualInvoiceListPage;
