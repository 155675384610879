import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addMinutes, format, isAfter, parse } from 'date-fns';

import { CRDriveUpdate, CRDriveWorkflow, DriveWorkflow, SharedDrives } from '@common/interfaces';
import { DateInputFormat, DateFormat as DF } from '@common/types';
import { Button, Field, Form } from '@components';
import { EditDriveSchema } from '../../schemas';
import { calcDurationDrive } from '../../utils';
import './EditDriveForm.styles.scss';

const Item = ({ label, value }: { label: string; value: string | number }) => (
  <div className="item">
    <div className="label">{label}</div>
    <div className="value">{value}</div>
  </div>
);

const RideSharing = ({ sharedDrives }: { sharedDrives: SharedDrives[] }) => {
  const { t } = useTranslation();
  return (
    <>
      <h3>{t('report.rideSharing')}</h3>
      {sharedDrives.map(({ bookingRef, vehicleLabel, mileage, duration }) => (
        <div className="row" key={bookingRef}>
          <Item label={t('common.vehicle')} value={vehicleLabel} />
          <Item label="Km" value={mileage} />
          <Item label={t('common.duration')} value={duration} />
        </div>
      ))}
    </>
  );
};

const Workflows = ({ workflows }: { workflows: DriveWorkflow[] }) => {
  const { t } = useTranslation();
  return (
    <>
      <h3>{t('report.workflows')}</h3>
      {workflows.map(({ name, startKm, endKm, distance, duration }) => (
        <div className="row" key={name}>
          <Item label={t('common.name')} value={name} />
          <Item label="Odometer" value={`${startKm} / ${endKm}`} />
          <Item label="Km" value={distance || (endKm - startKm).toFixed(2)} />
          <Item label={t('common.duration')} value={duration} />
        </div>
      ))}
    </>
  );
};

export interface FormState {
  distance: number;
  dropoffDate: Date;
  dropoffTime: string;
  durationDrive: string;
  pickupDate: Date;
  pickupTime: string;
}

export interface EditDriveProps {
  data: CRDriveWorkflow;
  readOnly?: boolean;
  updateDrive: (payload: CRDriveUpdate) => void;
}

const EditDriveForm = ({ data, readOnly = false, updateDrive }: EditDriveProps) => {
  const { t } = useTranslation();

  const [isDriveInFuture, setIsDriveInFuture] = useState<boolean>(false);

  useEffect(() => {
    if (data?.startDate && data?.startTime) {
      setIsDriveInFuture(isAfter(new Date(`${data.startDate} ${data.startTime}`), new Date()));
    }
  }, [data]);

  const calcDuration = useCallback(
    (pickupDate: Date, pickupTime: string, dropoffDate: Date, dropoffTime: string) =>
      calcDurationDrive({
        startDate: pickupDate,
        startTime: pickupTime || '00:00',
        endDate: dropoffDate,
        endTime: dropoffTime || '00:00',
      }),
    [],
  );

  const handleSubmit = useCallback(
    async ({ distance, dropoffDate, dropoffTime, pickupDate, pickupTime }: FormState) => {
      updateDrive({
        distance,
        end_date: format(dropoffDate, DF.ApiDateAlt),
        end_time: dropoffTime,
        start_date: format(pickupDate, DF.ApiDateAlt),
        start_time: pickupTime,
      });
    },
    [updateDrive],
  );

  const schema = useMemo(() => EditDriveSchema(), []);

  const initFormData: FormState | undefined = useMemo(() => {
    if (!data) return undefined;

    const { endDate, endTime, startDate, startTime } = data;
    const pickupDate = startDate ? parse(startDate, DF.ApiDateAlt, new Date()) : new Date();
    const dropoffDate = endDate ? parse(endDate, DF.ApiDateAlt, new Date()) : new Date();
    const durationDrive = calcDuration(pickupDate, startTime, dropoffDate, endTime);

    return {
      distance: data.distance,
      dropoffDate,
      dropoffTime: endTime || '00:00',
      durationDrive,
      pickupDate,
      pickupTime: startTime || '00:00',
    };
  }, [calcDuration, data]);

  return (
    <Form
      className="form-drive-edit modal-light-form"
      defaultValues={initFormData}
      schema={schema}
      onSubmit={handleSubmit}
    >
      {({ setValue, watch }) => {
        const pickDate = watch('pickupDate');
        const pickTime = watch('pickupTime');
        const dropDate = watch('dropoffDate');
        const dropTime = watch('dropoffTime');

        return (
          <>
            {isDriveInFuture && (
              <div className="row">
                <span className="font-red">{t('report.isDriveInFuture')}</span>
              </div>
            )}

            <h2>{t('common.editDrive')}</h2>
            <h3>{t('report.durationAndDistance')}</h3>

            <div className="row">
              <div className="fieldset">
                <Field
                  name="pickupDate"
                  input="date-picker"
                  className="field-date"
                  disabled={readOnly}
                  label={t('common.inputStartDate')}
                  valueFormat={DateInputFormat.DateByDots}
                  onChange={(value: Date) => {
                    let dropDate$ = dropDate;

                    if (isAfter(value as Date, dropDate as Date)) {
                      dropDate$ = value;
                      setValue('dropoffDate', value);
                    }

                    setValue('durationDrive', calcDuration(value, pickTime, dropDate$, dropTime));
                  }}
                />

                <Field
                  name="dropoffDate"
                  input="date-picker"
                  className="field-date"
                  disabled={readOnly}
                  label={t('common.inputEndDate')}
                  minDate={watch('pickupDate')}
                  valueFormat={DateInputFormat.DateByDots}
                  onChange={(value: Date) => {
                    setValue('durationDrive', calcDuration(pickDate, pickTime, value, dropTime));
                  }}
                />

                <Field
                  name="pickupTime"
                  input="time"
                  className="field-time"
                  disabled={readOnly}
                  label={t('common.inputStartTime')}
                  onChange={(value: string) => {
                    const start = new Date(`${format(pickDate, DF.ApiDate)} ${value}`);
                    const end = new Date(`${format(dropDate, DF.ApiDate)} ${dropTime}`);
                    let dropTime$ = dropTime;

                    if (isAfter(start, end)) {
                      const time = format(addMinutes(start, 1), DF.ApiTime);
                      dropTime$ = time;
                      setValue('dropoffTime', time);
                    }

                    setValue('durationDrive', calcDuration(pickDate, value, dropDate, dropTime$));
                  }}
                />

                <Field
                  name="dropoffTime"
                  input="time"
                  className="field-time"
                  disabled={readOnly}
                  label={t('common.inputEndTime')}
                  onChange={(value: string) => {
                    const start = new Date(`${format(pickDate, DF.ApiDate)} ${pickTime}`);
                    const end = new Date(`${format(dropDate, DF.ApiDate)} ${value}`);
                    let pickTime$ = pickTime;

                    if (isAfter(start, end)) {
                      const time = format(addMinutes(end, -1), DF.ApiTime);
                      pickTime$ = time;
                      setValue('pickupTime', time);
                    }

                    setValue('durationDrive', calcDuration(pickDate, pickTime$, dropDate, value));
                  }}
                />

                <Field
                  name="durationDrive"
                  className="field-time"
                  disabled
                  label={`${t('common.duration')}, hr`}
                />

                <Field
                  name="distance"
                  input="number"
                  className="field-odometer"
                  disabled={readOnly}
                  label={t('report.distanceTraveled')}
                  placeholder="km"
                  precision={1}
                  step={0.1}
                  data-testid="edit-drive-workflow-distance"
                />
              </div>
            </div>

            {data?.sharedDrives?.length > 0 && <RideSharing sharedDrives={data.sharedDrives} />}
            {data?.workflows?.length > 0 && <Workflows workflows={data.workflows} />}

            <div className="footer">
              <Button
                className="btn btn-submit"
                disabled={readOnly}
                text={t('common.btnConfirmChanges')}
                type="submit"
                variant="submit"
              />
            </div>
          </>
        );
      }}
    </Form>
  );
};

export default EditDriveForm;
