/* istanbul ignore file */
import type { UseMutateFunction } from 'react-query';
import type { NavigateFunction as NFunction } from 'react-router';
import type { TFunction } from 'react-i18next';
import { Checkbox } from '@mantine/core';

import { BForwardType, Bookings, SortOrder, TransferType } from '@common/interfaces';
import { Button, Tooltip } from '@components';
import { Attachment, Check, Expand, Forward, Recurring, Right, Switch } from '@assets/svg/icons';
import { ExpandIcon, ForwardPayload } from './types';

export const getColumns = (
  bulk: Bookings[],
  isForward: boolean,
  tab: number,
  nav: NFunction,
  t: TFunction,
  forwardBooking: UseMutateFunction<unknown, unknown, ForwardPayload>,
  setForwardProcessing: (value: boolean) => void,
  onBulkAssignClick?: (record: Bookings) => void,
): Record<string, any>[] => [
  {
    ...(tab === 0
      ? {
          key: 'bulkAssignment',
          title: '',
          render: (r: Bookings) => {
            const isChecked = bulk.some((i) => i.id === r.id);
            const showCheckbox = r.transferType === TransferType.Shuttle;

            return (
              showCheckbox && (
                <Checkbox
                  checked={isChecked}
                  className="checkbox-outline"
                  variant="outline"
                  onChange={() => {
                    onBulkAssignClick?.(r);
                  }}
                />
              )
            );
          },
        }
      : {}),
  },
  {
    dataIndex: 'dispatchDate',
    key: 'created',
    title: t('booking.request'),
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: true,
  },
  {
    key: 'pax',
    title: t('common.pax'),
    render: (r: Bookings) => (
      <div>
        {r.pax}&nbsp;{r.agencyShortName}
        <div className="sub">
          {r.firstName}&nbsp;{r.lastName}
        </div>
      </div>
    ),
  },
  {
    key: 'typeOfTrip',
    title: t('common.typeOfTrip'),
    render: (r: Bookings) => (
      <>
        <div className="type-of-trip">
          {r.typeOfTripDisplay}&nbsp;
          {r.isRecurring && (
            <Tooltip label={t('common.recurringDrive')}>
              <Recurring />
            </Tooltip>
          )}
        </div>
        <div className="sub">{r.transferTypeDisplay}</div>
      </>
    ),
  },
  {
    key: 'first_pickup_datetime',
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: true,
    title: t('booking.pickup'),
    render: (r: Bookings) => (
      <div>
        {r.pickupDate}
        <div className="sub">{r.pickupTime}</div>
      </div>
    ),
  },
  {
    key: 'pickupLocation',
    title: t('bookingDetails.pickupLocation'),
    ellipsis: {
      showTitle: true,
    },
    render: (r: Bookings) => (
      <div>
        {r.routes[0]?.pickupLocation}
        <div className="sub">{r.routes[0]?.pickupTown}</div>
      </div>
    ),
  },
  {
    key: 'dropoffLocation',
    title: t('bookingDetails.dropoffLocation'),
    ellipsis: {
      showTitle: true,
    },
    render: (r: Bookings) => (
      <div>
        {r.routes[0]?.dropoffLocation}
        <div className="sub">{r.routes[0]?.dropoffTown}</div>
      </div>
    ),
  },
  {
    dataIndex: 'refCode',
    key: 'refCode',
    title: t('booking.refCode'),
  },
  {
    key: 'hasAttachments',
    title: '',
    render: (record: Bookings) => (
      <div className="attachments">
        {record.hasAttachments && (
          <Tooltip label={t('bookingList.optionalAttachments')}>
            <Attachment />
          </Tooltip>
        )}
      </div>
    ),
  },
  {
    key: 'forwarded',
    title: tab === 0 ? 'Forward' : '',
    render: (r: Bookings) => {
      const className = r.forwardKey === BForwardType.Forward ? 'btn-forward' : 'btn-forwarded';
      const icon =
        (r.forwardKey === BForwardType.Forwarded && <Check />) ||
        (r.forwardKey === BForwardType.Forward && <Forward />) ||
        ((r.forwardKey === BForwardType.Rejected ||
          r.forwardKey === BForwardType.RejectedByAllFP) && <Switch />) ||
        undefined;
      const showForward = r.forwardKey !== BForwardType.NotForForward;
      const showSubStatus =
        r.forwardKey !== BForwardType.Forward && r.forwardKey !== BForwardType.NotForForward;

      const renderForwardStatus = (
        <div style={{ width: 200 }}>
          {r.forwardStatus?.map((i) => (
            <p key={i}>{i}</p>
          ))}
        </div>
      );

      if (showSubStatus) {
        return (
          <Tooltip label={renderForwardStatus} multiline withinPortal>
            <div className="sub">{r.forwardLabel}</div>
          </Tooltip>
        );
      }

      return (
        showForward && (
          <Button
            aria-label={className}
            className={className}
            disabled={isForward || r.forwardKey !== BForwardType.Forward}
            rightIcon={icon}
            text={r.forwardLabel}
            variant="filled"
            onClick={() => {
              if (!isForward) {
                forwardBooking({ agencyFk: r.agencyFk, bookingId: r.id });
                setForwardProcessing(true);

                // Track the 'Forward' button click event
                if (typeof window.gtag === 'function') {
                  window.gtag('event', 'Forward', {
                    event_category: 'Booking',
                    event_label: 'Forward Button Click',
                    value: 1,
                    booking_id: r.id,
                    feature_used: 'Forward Button in Booking List',
                  });
                }
              }
            }}
          />
        )
      );
    },
  },
  {
    key: 'status',
    title: t('common.status'),
    render: (r: Bookings) => {
      const applyBtn = (state: string, text: string): JSX.Element => (
        <Button
          aria-label={`btn-process-${state}`}
          className={`btn-process btn-process-${state}`}
          rightIcon={r.forwardKey !== BForwardType.Rejected && <Right />}
          text={text}
          variant="filled"
          onClick={() => nav?.(state !== 'not-processed' ? `${r.id}` : `booking-accept/${r.id}`)}
        />
      );

      if (r.forwardKey === BForwardType.Rejected) {
        return applyBtn('rejected', t('booking.rejected'));
      }

      switch (r.state) {
        case 'accepted':
          return applyBtn('accepted', t('booking.accepted'));
        case 'cancelled':
          return applyBtn('cancelled', t('booking.cancelledByFP'));
        case 'cancelled_by_user':
          return applyBtn('cancelled', t('booking.userCancelled'));
        case 'disabled':
          return applyBtn('disabled', t('booking.accepted'));
        case 'finished':
          return applyBtn('finished', t('booking.finished'));
        case 'not_processed':
          return applyBtn('not-processed', t('booking.process'));
        case 'ongoing':
          return applyBtn('ongoing', t('booking.ongoing'));
        case 'rejected':
          return applyBtn('rejected', t('booking.rejected'));
        default:
          return null;
      }
    },
  },
];

export const getExpandedIcon = ({ expanded, onExpand, record }: ExpandIcon) =>
  !!record.routes?.length && (
    <Button
      className={expanded ? 'icon-expanded' : 'icon-expand'}
      leftIcon={<Expand />}
      variant="icon"
      onClick={() => onExpand(record)}
    />
  );
