import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { format, isAfter, isEqual } from 'date-fns';

import { MobilityListType, RadioOption, UnavailabilityUpdate } from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { DateFormat as DF } from '@common/types';
import { Button, Field, Form, Loader } from '@components';
import { useRepository } from '@context';
import { FormSchema } from './schemas';
import './Unavailability.styles.scss';

export interface FormState {
  deleted?: boolean;
  end?: Date;
  reason?: string;
  sameDay?: boolean;
  start?: Date;
}

export interface UnavailabilityProps {
  id: string;
  label: string;
  type: MobilityListType;
  onSubmit: () => void;
}

const INIT_FORM_DATA: FormState = {
  deleted: false,
  end: undefined,
  reason: '1',
  sameDay: false,
  start: undefined,
};

const Unavailability = ({ id, label, type, onSubmit }: UnavailabilityProps) => {
  const { facilityId } = useFacility();
  const { mobilityRepository } = useRepository();
  const { t } = useTranslation();

  const [state, setState] = useState({
    errors: [] as string[],
    exist: false,
    isSameDay: false,
    period: null as FormState | null,
  });

  const { errors, exist, isSameDay, period } = state;

  const { mutate: updateUnavailabilities, isLoading } = useMutation(
    'update-unavailabilities',
    (payload: UnavailabilityUpdate) =>
      mobilityRepository.updateUnavailabilities(facilityId, id, type, payload),
    {
      onSuccess: () => {
        setState((s) => ({ ...s, period: null }));
        onSubmit();
      },
      onError: (error: any) => {
        if (error.response) {
          setState((s) => ({ ...s, errors: error.response.data.errors || [], exist: true }));
        }
      },
    },
  );

  const handleSubmit = useCallback(
    async ({ deleted, end, start, reason }: FormState) => {
      if (deleted && start) {
        updateUnavailabilities({ deleted: true, since_date: format(start, DF.ApiDate) });
        return;
      }

      if (period && start && end) {
        updateUnavailabilities({
          since_date: format(start, DF.ApiDate),
          until_date: format(end, DF.ApiDate),
          ...(reason && type === 'vehicle' ? { reason: Number(reason) } : {}),
        });
        return;
      }

      const updatedPeriod: FormState = { deleted, end, start, reason };
      if (start && end && isAfter(start, end)) return toast.error(t('common.errorMsgDateRange'));
      if (start && end && isEqual(start, end)) updatedPeriod.sameDay = true;

      setState((s) => ({ ...s, period: updatedPeriod, isSameDay: !!updatedPeriod.sameDay }));
    },
    [period, t, type, updateUnavailabilities],
  );

  const schema = useMemo(() => FormSchema(), []);
  const vehicleReasonOptions: RadioOption[] = [
    { value: '1', label: t('mobility.vehicleReasonRegularMaintenance') },
    { value: '2', label: t('mobility.vehicleReasonExtraordinaryRepair') },
    { value: '3', label: t('mobility.vehicleReasonForDisposal') },
  ];

  return (
    <Loader spinning={isLoading}>
      <Form
        className="form-unavailability"
        defaultValues={INIT_FORM_DATA}
        schema={schema}
        theme="dark"
        onSubmit={handleSubmit}
      >
        {({ setValue, watch }) =>
          !period ? (
            <div className="fieldset">
              <h2>
                {type === MobilityListType.Driver
                  ? `${t('mobility.selectWhen')} ${label} ${t('mobility.willUnavailable')}`
                  : `${t('mobility.selectWhy')} ${label} ${t('mobility.willUnavailable')}`}
              </h2>

              {type === MobilityListType.Vehicle && (
                <div className="row">
                  <Field name="reason" input="radio" options={vehicleReasonOptions} />
                </div>
              )}

              <div className="row">
                <Field
                  name="start"
                  input="calendar"
                  label={t('common.fromIncluded')}
                  maxDate={!isSameDay ? (watch('end') as Date) : undefined}
                  minDate={new Date()}
                  onChange={(value: Date) => {
                    const end = watch('end') as Date;
                    setState((s) => ({ ...s, isSameDay: isEqual(value, end) }));
                    if (isSameDay || isAfter(value, end)) setValue('end', value);
                  }}
                />
                <Field
                  name="end"
                  input="calendar"
                  className={!watch('start') || isSameDay ? 'disabled' : ''}
                  label={t('common.toIncluded')}
                  minDate={!isSameDay ? (watch('start') as Date) : undefined}
                  onChange={(value: Date) => {
                    const start = watch('start') as Date;
                    setState((s) => ({ ...s, isSameDay: isEqual(start, value) }));
                  }}
                />
              </div>

              <div className="footer">
                <Field
                  name="sameDay"
                  input="checkbox"
                  checked={isSameDay}
                  label={t('common.sameDay')}
                  onChange={(value: boolean) => {
                    const start = watch('start') as Date;
                    setState((s) => ({ ...s, isSameDay: value }));
                    if (value && start) setValue('end', start);
                  }}
                />

                <div className="buttons">
                  <Button
                    className="btn btn-disable"
                    text={`${t('common.disable')} ${
                      type === 'vehicle' ? t('common.vehicle') : t('common.driver')
                    }`}
                    type="button"
                    variant="danger"
                    onClick={() => {
                      setState((s) => ({ ...s, period: { deleted: true, start: new Date() } }));
                      setValue('deleted', true);
                      setValue('start', new Date());
                    }}
                  />

                  <Button
                    className="btn btn-submit"
                    text={t('common.btnConfirmDates')}
                    type="submit"
                    variant="submit"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="confirm-box">
              {period.deleted ? (
                <>
                  <h2>
                    <span>{label}&nbsp;</span>
                    <span>
                      {exist ? t('mobility.willNotBeDisabled') : t('mobility.willBeDisabled')}
                    </span>
                  </h2>
                  <div className="row">
                    <div className="column">
                      <div className="period-label">{t('common.fromIncluded')}</div>
                      <div className="period-date">{format(period.start!, DF.ApiDateAlt)}</div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h2>
                    <span>{label}&nbsp;</span>
                    <span>
                      {exist ? t('mobility.willNotUnavailable') : t('mobility.willUnavailable')}
                    </span>
                  </h2>
                  <div className="row">
                    {isSameDay ? (
                      <div className="column">
                        <div className="period-label">{t('mobility.oneDay')}</div>
                        <div className="period-date">{format(period.start!, DF.ApiDateAlt)}</div>
                      </div>
                    ) : (
                      <>
                        <div className="column">
                          <div className="period-label">{t('common.fromIncluded')}</div>
                          <div className="period-date">{format(period.start!, DF.ApiDateAlt)}</div>
                        </div>
                        <div className="column">
                          <div className="period-label">{t('common.toIncluded')}</div>
                          <div className="period-date">
                            {period?.end && format(period.end, DF.ApiDateAlt)}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              {exist ? (
                <div className="row">
                  {period.deleted ? (
                    <div className="column">
                      <div className="status-exist-warning">
                        {errors?.length ? errors.map((msg) => <p key={msg}>{msg}</p>) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="column">
                      <div className="status-exist-warning">
                        {errors?.length ? errors.map((msg) => <p key={msg}>{msg}</p>) : null}
                      </div>
                      <div className="status-exist-message">
                        {t('common.btnChange')} {type === 'vehicle' ? 'the vehicle' : 'driver'}{' '}
                        {t('mobility.statusExistMsg')}
                      </div>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="footer">
                {exist ? (
                  <Button
                    className="btn btn-back"
                    text={t('common.btnBack')}
                    type="button"
                    variant="outline"
                    onClick={() => setState((s) => ({ ...s, period: null, exist: false }))}
                  />
                ) : (
                  <Button
                    className="btn btn-submit"
                    text={t('common.btnConfirm')}
                    type="submit"
                    variant="submit"
                  />
                )}
              </div>
            </div>
          )
        }
      </Form>
    </Loader>
  );
};

export default Unavailability;
