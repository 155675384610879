/* istanbul ignore file */
import { t } from 'i18next';
import { Dropdown } from '@ui-modules/informed';
import { DropdownOption } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

interface FieldProps {
  vehiclesOptions: DropdownOption[];
}

const VehicleField = ({ vehiclesOptions }: FieldProps) => (
  <div className="field" data-testid="planner-drive-form-field-vehicle">
    <Dropdown
      className="dark-border small-list"
      label={t('common.vehicle')}
      name="vehicle"
      options={vehiclesOptions}
      validate={notEmptyValidator}
    />
  </div>
);

export default VehicleField;
