import type { CSSProperties } from 'react';
import { useRef, useEffect, useState } from 'react';
import styles from './WfpChatbot.module.scss';
import { ChatIconOpened, ChatIconClosed } from './ChatIcons';

/* eslint-disable-next-line */
export interface WfpChatbotProps {
  style?: CSSProperties;
  token: string;
  user: string;
  thread: string;
  iframeSrc: string;
}

export function WfpChatbot(props: WfpChatbotProps) {
  const { token, user, thread, style, iframeSrc } = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isActivated, setIsActivated] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (!iframeRef.current) {
      return;
    }
    iframeRef.current.onload = () => {
      iframeRef.current?.contentWindow?.postMessage(
        {
          token,
          user,
          thread,
        },
        iframeRef.current.src,
      );
    };
  }, [token, user, thread, isActivated]);

  return (
    <aside
      className={`${styles.container} ${isOpened ? styles.opened : styles.closed} ${
        isActivated && styles.animate
      }`}
      style={style}
      data-testid="chatbot_container"
    >
      <div className={styles['chatbot-wrap']}>
        {isActivated && (
          <iframe
            data-testid="chatbot_iframe"
            className={styles.chatbot}
            ref={iframeRef}
            title="chatbot"
            src={iframeSrc}
            sandbox="allow-scripts allow-same-origin"
            height="100%"
            width="100%"
          />
        )}
      </div>
      <div className={styles['button-wrap']}>
        <button
          data-testid="chatbot_btn"
          type="button"
          className={styles.button}
          onClick={() => {
            setIsActivated(true);
            setIsOpened((x) => !x);
          }}
        >
          <span>Chat with us</span>
          <div className={styles['icon-wrap']}>
            <div className={styles['icon-slider']}>
              <ChatIconOpened />
              <ChatIconClosed />
            </div>
          </div>
        </button>
      </div>
    </aside>
  );
}

export default WfpChatbot;
