import { type FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '@ui-modules';
import { Button, Loader } from '@components';
import { useGlobalContext } from '@context';
import { SessionStorageKeys } from '@common/constants';
import { DropdownOption } from '@common/interfaces';
import { useFacilityPermission, useParamsParse } from '@common/hooks';
import { checkUserLogin } from '@common/utils';
import { DriverLogo } from '@assets/svg/logos';
import './EntryPage.styles.scss';

const DO_URL = process.env.REACT_APP_DO_URL;

const EntryPage: FC = () => {
  const agencyId = sessionStorage.getItem(SessionStorageKeys.AGENCY_ID)!;
  const agencyName = sessionStorage.getItem(SessionStorageKeys.AGENCY)!;
  const facilityId = sessionStorage.getItem(SessionStorageKeys.FACILITY)!;
  const navigate = useNavigate();
  const { facilities: facilityData, isLoading } = useFacilityPermission();
  const { parsed, userType } = useParamsParse();
  const { setAgencyId, setAgencyName, setFacilityId } = useGlobalContext();
  const { t } = useTranslation();

  const [agencies, setAgencies] = useState<DropdownOption[]>([]);
  const [cities, setCities] = useState<DropdownOption[]>([]);
  const [countries, setCountries] = useState<DropdownOption[]>([]);
  const [facilities, setFacilities] = useState<DropdownOption[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<DropdownOption | null>(null);
  const [selectedCity, setSelectedCity] = useState<DropdownOption | null>(null);
  const [selectedAgency, setSelectedAgency] = useState<DropdownOption | null>(null);
  const [selectedFacility, setSelectedFacility] = useState<DropdownOption | null>(null);

  const getAgencyOptions = ({ city, country }: { city?: string; country?: string }): void => {
    const data = facilityData.filter(
      (i) => (!city || i.city.value === city) && (!country || i.country.value === country),
    );

    setAgencies([...new Map(data.flatMap((i) => i.agencies.map((a) => [a.value, a]))).values()]);
  };

  const getCityOptions = (country: string): void => {
    const data = facilityData?.filter((i) => i.country.value === country).map((i) => i.city);

    if (data?.length) setCities([...new Map(data.map((i) => [i.value, i])).values()]);
  };

  const getFacilityOptions = ({ city, country }: { city?: string; country?: string }): void => {
    const data = facilityData.filter(
      (i) => (!city || i.city.value === city) && (!country || i.country.value === country),
    );

    setFacilities(data.map((item) => ({ label: item.facilityName, value: item.id })));
  };

  const handleAgencyChange = (agency: DropdownOption): void => {
    sessionStorage.setItem(SessionStorageKeys.AGENCY, agency.label);
    sessionStorage.setItem(SessionStorageKeys.AGENCY_ID, agency.value);
    setAgencyId(agency.value);
    setAgencyName(agency.label);
    setSelectedAgency(agency);

    if (!selectedFacility) {
      const facilityOptions = facilityData
        ?.filter((i) => i.agencies.filter((a) => a.label === agency.label))
        .map((i) => ({ label: i.facilityName, value: i.id }));

      if (facilityOptions?.length === 1) {
        setSelectedFacility(facilityOptions[0]);
      }
    }
  };

  const handleCityChange = (city: DropdownOption): void => {
    const facilityRelated = facilityData?.filter((i) => i.city.value === city?.value);
    const facilityOptions = facilityRelated.map((i) => ({ label: i.facilityName, value: i.id }));
    const facilityAgencies = facilityRelated
      ?.map((i) => i.agencies)
      .reduce((acc, options) => acc.concat(options), []);

    setAgencies(facilityAgencies);
    setFacilities(facilityOptions);
    setSelectedAgency(facilityAgencies.length === 1 ? facilityAgencies[0] : null);
    setSelectedCity(city);
    setSelectedFacility(
      facilityOptions.length === 1
        ? {
            label: facilityRelated[0].facilityName,
            value: facilityRelated[0].id,
          }
        : null,
    );

    getAgencyOptions({ city: city.value });
  };

  const handleCountryChange = (country: DropdownOption): void => {
    // options
    getAgencyOptions({ country: country.value });
    getCityOptions(country.value);
    getFacilityOptions({ country: country.value });
    // values
    setSelectedAgency(null);
    setSelectedCity(null);
    setSelectedFacility(null);

    setSelectedCountry(country);
  };

  const handleFacilityChange = (facility: DropdownOption): void => {
    setFacilityId(facility.value);
    setSelectedFacility(facility);
  };

  useEffect(() => {
    const facilityRelated = facilityData?.find((i) => i.id === selectedFacility?.value);

    if (facilityRelated) {
      sessionStorage.setItem(SessionStorageKeys.FACILITY, facilityRelated.id);

      setAgencies(facilityRelated?.agencies!);
      setSelectedCity(facilityRelated?.city!);
      setSelectedCountry(facilityRelated?.country!);

      if (facilityRelated?.agencies.length === 1) {
        const agency = facilityRelated.agencies[0];
        setAgencyId(agency.value);
        setAgencyName(agency.label);
        sessionStorage.setItem(SessionStorageKeys.AGENCY, agency.label);
        sessionStorage.setItem(SessionStorageKeys.AGENCY_ID, agency.value);

        setSelectedAgency(agency);
      } else if (!facilityRelated?.agencies.find((i) => i.value === selectedAgency?.value)) {
        setSelectedAgency(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacility]);

  useEffect(() => {
    if (facilityData?.length === 1) {
      const { agencies: agencyData, city, country, facilityName, id } = facilityData[0];

      if (agencies.length === 1) {
        const agency = agencyData[0];
        sessionStorage.setItem(SessionStorageKeys.AGENCY_ID, agency.value);
        setAgencyId(agency.value);
        setAgencyName(agency.label);
        setSelectedCountry(country);
        setSelectedCity(city);
        setSelectedFacility({ label: facilityName, value: id });
        setSelectedAgency(agency);
      }
    }

    if (facilityData?.length) {
      const facilitySession = facilityData?.find((i) => i.id === facilityId);
      const relatedAgency = facilitySession?.agencies?.find((i) => i.label === agencyName);
      setCountries([
        ...new Map((facilityData || []).map((i) => [i.country.value, i.country])).values(),
      ]);

      if (facilitySession && relatedAgency) {
        // Options related to the country
        getCityOptions(facilitySession.country.value);
        getFacilityOptions({ city: facilitySession.city.value });
        // Session Storage
        sessionStorage.setItem(SessionStorageKeys.AGENCY_ID, relatedAgency?.value);
        // Global Storage
        setAgencyId(relatedAgency?.value);
        setAgencyName(relatedAgency?.label);
        // Predefined Form Values
        setSelectedCountry(facilitySession.country);
        setSelectedCity(facilitySession.city);
        setSelectedFacility({ label: facilitySession.facilityName, value: facilitySession.id });
        setSelectedAgency(relatedAgency);
      } else {
        setCities([...new Map(facilityData.map((i) => [i.city.value, i.city])).values()]);
        setFacilities(facilityData.map((i) => ({ label: i.facilityName, value: i.id })));
        setAgencies([
          ...new Map(
            facilityData
              .map((i) => i.agencies)
              .reduce((acc, options) => acc.concat(options), [])
              .map((a) => [a.value, a]),
          ).values(),
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityData]);

  useEffect(() => {
    if ((facilityData?.length === 1 || parsed) && selectedAgency && selectedFacility && agencyId) {
      navigate(`${DO_URL}/facility/${facilityId}/${agencyName}/${agencyId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyId, agencyName, facilityId, parsed, selectedAgency, selectedFacility]);

  useEffect(() => {
    if (parsed && userType) checkUserLogin(userType);
  }, [parsed, userType]);

  const isDisabled = !(selectedCountry && selectedCity && selectedAgency && selectedFacility);

  return (
    <section className="ddo-entry">
      <div className="ddo-entry-nav">
        <header>
          <DriverLogo />
          Welcome to the UN Mobility Digital Office
        </header>

        <div className="ddo-entry-nav-content">
          <Loader spinning={isLoading}>
            <h2>{t('common.agencySelectLabel')}</h2>

            <fieldset>
              <div className="field" data-testid="ddo-entry-page-country-dropdown">
                <Dropdown
                  className="dropdown outline"
                  isSearchable={false}
                  label={t('common.country')}
                  name="country"
                  options={countries}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                />
              </div>

              <div className="field" data-testid="ddo-entry-page-city-dropdown">
                <Dropdown
                  className="dropdown outline"
                  isSearchable={false}
                  label={t('common.city')}
                  name="city"
                  options={cities}
                  value={selectedCity}
                  onChange={handleCityChange}
                />
              </div>

              <div className="field" data-testid="ddo-entry-page-facility-dropdown">
                <Dropdown
                  className="dropdown outline"
                  isSearchable={false}
                  label={t('common.location')}
                  name="facility"
                  options={facilities}
                  value={selectedFacility}
                  onChange={handleFacilityChange}
                />
              </div>

              <div className="field" data-testid="ddo-entry-page-agency-dropdown">
                <Dropdown
                  className="dropdown outline"
                  isSearchable={false}
                  label={t('common.agency')}
                  name="agency"
                  options={agencies}
                  value={selectedAgency}
                  onChange={handleAgencyChange}
                />
              </div>
            </fieldset>

            <Button
              className="btn btn-go"
              disabled={isDisabled}
              text="Go"
              variant="primary"
              onClick={() => navigate(`${DO_URL}/facility/${facilityId}/${agencyName}/${agencyId}`)}
            />
          </Loader>
        </div>
      </div>
    </section>
  );
};

export default EntryPage;
