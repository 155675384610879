/* istanbul ignore file */
import { t } from 'i18next';
import * as yup from 'yup';

const FormSchema = () =>
  yup.object().shape({
    deleted: yup.boolean().optional(),
    start: yup.date().nullable().required(t('common.fieldRequired')),
    end: yup
      .date()
      .nullable()
      .when(['start', 'deleted'], (values, schema) => {
        const [start, deleted] = values as [Date | null, boolean | undefined];
        return start && !deleted ? schema.required(t('common.fieldRequired')) : schema;
      }),
    reason: yup.string().optional(),
    sameDay: yup.boolean().optional(),
  });

export default FormSchema;
