import { useState } from 'react';
import cn from 'classnames';
import './Tabs.styles.scss';

interface TabsProps {
  badges?: Array<number | string>;
  className?: string;
  selectedTab?: number;
  tabs: string[];
  toolbar?: JSX.Element;
  variant?: 'default' | 'outline' | 'pills';
  onTabSelect?: (tab: string) => void;
}

const Tabs = ({
  badges = [],
  className = '',
  selectedTab = 0,
  tabs,
  toolbar,
  variant = 'default',
  onTabSelect,
}: TabsProps) => {
  const [active, setActive] = useState<string>(tabs[selectedTab]);

  const onClickHandler = (tab: string): void => {
    setActive(tab);
    onTabSelect?.(tab);
  };

  return (
    <div
      className={cn(
        'tabs-wrap',
        {
          default: variant === 'default',
          outline: variant === 'outline',
          pills: variant === 'pills',
        },
        className,
      )}
    >
      <div className="tabs">
        {tabs?.map((tab: string, i: number) => (
          <button
            className={active === tab ? 'tab active' : 'tab'}
            key={tab}
            type="button"
            onClick={() => onClickHandler(tab)}
          >
            {tab}
            {badges.length ? (
              <span
                data-testid={active === tab ? 'tab-badge-active' : 'tab-badge'}
                className="badge"
              >
                {badges[i]}
              </span>
            ) : null}
          </button>
        ))}
      </div>
      <div className="toolbar">{toolbar}</div>
    </div>
  );
};

export default Tabs;
