import { type FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button as MantineButton, ButtonProps as MantineButtonProps } from '@mantine/core';
import cn from 'classnames';
import './Button.styles.scss';

export type ButtonProps = {
  className?: string;
  form?: string;
  icon?: JSX.Element;
  link?: string;
  linkStyle?: 'danger' | 'disabled' | 'icon' | 'link' | 'submit' | 'transparent' | 'warning';
  router?: boolean;
  text?: JSX.Element | string;
  type?: 'button' | 'reset' | 'submit';
  onClick?: () => void;
} & Omit<MantineButtonProps, 'onClick'>;

const Button: FC<ButtonProps> = ({
  className = '',
  icon,
  link = '',
  linkStyle = 'primary',
  router = false,
  text = '',
  type = 'button',
  variant,
  onClick,
  ...props
}) => {
  const handleClick = useCallback(() => onClick?.(), [onClick]);

  const ButtonElem = (
    <MantineButton
      className={cn(`ddo-btn ddo-btn-${variant}`, className)}
      classNames={!text ? { icon: 'icon-only' } : undefined}
      type={type}
      onClick={handleClick}
      {...props}
    >
      {icon}
      {text}
    </MantineButton>
  );

  const LinkElem = router ? (
    <Link className={cn(`ddo-btn ddo-btn-${linkStyle}`, className)} to={link}>
      {icon}
      {text}
    </Link>
  ) : (
    <a className={cn(`ddo-btn ddo-btn-${linkStyle}`, className)} href={link} rel="noreferrer">
      {icon}
      {text}
    </a>
  );

  return link ? LinkElem : ButtonElem;
};

export default Button;
