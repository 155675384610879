/* istanbul ignore file */
import { type FC } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { parse } from 'date-fns';
import type { AxiosError } from 'axios';
import { Form, type FormState } from 'informed';
import { Modal } from '@mantine/core';

import { Button, ChipGroup, Loader } from '@components';
import { DateFormat } from '@common/types';
import {
  PlDrive,
  PlDriver,
  PlVehicle,
  ShuttleConfigFormState,
  ShuttleConfigUpdateApi,
} from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import { ArrowLeft } from '@assets/svg/icons';
import { Serializer, getErrors } from '@common/utils';
import { DriverField, DropoffDateField, PickupDateField, VehicleField } from '../../fields';
import { findOption, mapDriverOptions, mapVehicleOptions, weekdays } from '../../utils';
import '../../styles/modal.scss';

interface ShuttleConfigModalProps {
  drivers: PlDriver[];
  formData: Partial<PlDrive> | null;
  vehicles: PlVehicle[];
  onCancel: () => void;
  onSubmit: () => void;
}

const ShuttleConfigModal: FC<ShuttleConfigModalProps> = ({
  drivers,
  formData,
  vehicles,
  onCancel,
  onSubmit,
}) => {
  const { facilityId } = useFacility();
  const { plannerRepo } = useRepository();
  const { t } = useTranslation();
  const { driverId, vehicleId, shuttleConfig } = formData || {};

  const { mutate: updateShuttleConfig, isLoading: isShuttleConfigUpdating } = useMutation<
    unknown,
    AxiosError,
    ShuttleConfigUpdateApi
  >('shuttle-config-update', (data) => plannerRepo.updateShuttleConfig(facilityId, data), {
    onSuccess: () => {
      toast.success(t('planner.msgShuttleConfigUpdated'));
      onSubmit();
    },
    onError: (e: any) => {
      toast.error(getErrors(e.response.data));
    },
  });

  const driverOpt = mapDriverOptions(drivers);
  const vehicleOpt = mapVehicleOptions(vehicles);

  const initShuttleConfig: Record<string, unknown> = {
    driver: findOption(driverOpt, driverId),
    dropoffDate: parse(shuttleConfig?.assignmentEndDate!, DateFormat.ApiDate, new Date()),
    pickupDate: parse(shuttleConfig?.assignmentStartDate!, DateFormat.ApiDate, new Date()),
    vehicle: findOption(vehicleOpt, vehicleId),
    weekdays: shuttleConfig?.weekdays?.map(String),
  };

  const handleSubmit = (formState: FormState) => {
    const payload = Serializer.mapShuttleConfigToUpdate(
      { ...(formState.values as unknown as ShuttleConfigFormState) },
      formData!,
    );
    updateShuttleConfig(payload);
  };

  return (
    <Modal
      classNames={{
        body: 'mp-modal-body',
        close: 'mp-modal-btn-close',
        content: 'mp-modal',
        header: 'mp-modal-header',
        title: 'mp-modal-title',
      }}
      centered
      opened={!!formData}
      size={580}
      title="Shuttle config edit"
      variant="default"
      onClose={onCancel}
    >
      {isShuttleConfigUpdating && <Loader spinning />}

      <Form className="mp-form" initialValues={initShuttleConfig} onSubmit={handleSubmit}>
        <section className="fieldset">
          <div className="row field-group">
            <PickupDateField label={t('planner.dateStart')} />
            <DropoffDateField label={t('planner.dateEnd')} />
            <VehicleField options={vehicleOpt} />
            <DriverField driver={driverId} isShuttle options={driverOpt} />
          </div>

          <div className="row field-weekdays">
            <ChipGroup name="weekdays" options={weekdays} readonly />
          </div>

          <footer>
            <Button
              className="btn-back"
              leftIcon={<ArrowLeft />}
              text={t('common.btnBackToPlanner')}
              variant="transparent"
              onClick={onCancel}
            />

            <Button
              className="btn-submit"
              text={t('common.btnConfirm')}
              type="submit"
              variant="submit"
            />
          </footer>
        </section>
      </Form>
    </Modal>
  );
};

export default ShuttleConfigModal;
