import { type FC, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Button, PageTitle } from '@unbooking/ui-modules';
import { useFacility, useFeatureFlags } from '@common/hooks';
import { useRepository } from '@context';
import { getErrors } from '@common/utils';
import { MobilityListType, VehicleCreate, VehicleType, VehicleUpdate } from '@common/interfaces';
import { Modal, Tabs, Unavailability } from '@components';
import { VehicleEdit, VehicleList } from './components';
import { useVehicleList } from './hooks';
import './VehicleListPage.styles.scss';

const VehicleListPage: FC = () => {
  const { flags } = useFeatureFlags();
  const { mobilityRepository } = useRepository();
  const { t } = useTranslation();
  const { agencyName, facility, facilityId } = useFacility();
  const { agencyId, city, country } = facility;
  const tabs = ['My Vehicles', 'Shared'];

  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [selectedVehicle, setSelectedVehicle] = useState<string | null>(null);
  const [showVehicleForm, setShowVehicleForm] = useState<boolean>(false);
  const [vehicleUnavailable, setVehicleUnavailable] = useState<VehicleType | null>(null);

  const [sharedVehiclePageSize, setSharedVehiclePageSize] = useState<number>(10);
  const [sharedVehicleSelectedPage, setSharedVehicleSelectedPage] = useState<number>(1);
  const [vehiclePageSize, setVehiclePageSize] = useState<number>(10);
  const [vehicleSelectedPage, setVehicleSelectedPage] = useState<number>(1);

  const {
    data: { count: vehicleTotal = 0, data: vehicleList = [] } = {},
    isLoading: isVehiclesLoading,
    refetch: getVehicles,
  } = useVehicleList(agencyId, facilityId, vehiclePageSize, vehicleSelectedPage, false);

  const {
    data: { count: sharedVehicleTotal = 0, data: sharedVehicleList = [] } = {},
    isLoading: isSharedVehiclesLoading,
    refetch: getSharedVehicles,
  } = useVehicleList(agencyId, facilityId, sharedVehiclePageSize, sharedVehicleSelectedPage, true);

  const { isLoading: isUnavailableProcess, mutate: deleteUnavailable } = useMutation(
    'delete-unavailable',
    ({ id, unavailable }: { id: string; unavailable: string }) =>
      mobilityRepository.deleteUnavailable(facilityId, id, unavailable, MobilityListType.Vehicle),
    {
      onSuccess: () => {
        if (selectedTab === tabs[0]) getVehicles();
        if (selectedTab === tabs[1]) getSharedVehicles();
      },
    },
  );

  const { isLoading: isVehicleCreating, mutateAsync: createVehicle } = useMutation(
    'create-vehicle',
    (data: VehicleCreate) => mobilityRepository.createVehicle(facilityId, data),
    {
      onSuccess: () => {
        toast.success(t('mobility.msgVehicleCreated'));
        setShowVehicleForm(false);
        if (selectedTab === tabs[0]) getVehicles();
        if (selectedTab === tabs[1]) getSharedVehicles();
      },
      onError: (e: any) => {
        if (e.message) toast.error(e.response.data || t('common.errorMsgDefault'));
      },
    },
  );

  const { isLoading: isVehicleUpdating, mutateAsync: updateVehicle } = useMutation(
    'update-vehicle',
    ({ vehicleId, data }: { vehicleId: string; data: VehicleUpdate }) =>
      mobilityRepository.updateVehicle(facilityId, vehicleId, data),
    {
      onSuccess: () => {
        toast.success(t('mobility.msgVehicleUpdated'));
        setShowVehicleForm(false);
        setSelectedVehicle(null);
        if (selectedTab === tabs[0]) getVehicles();
        if (selectedTab === tabs[1]) getSharedVehicles();
      },
      onError: (e: any) => {
        if (e.message) toast.error(getErrors(e.response.data) || t('common.errorMsgDefault'));
      },
    },
  );

  const handleVehicleStatusChange = (vehicle: VehicleType) => {
    if (vehicle?.currentUnavailability) {
      deleteUnavailable({ id: vehicle.id, unavailable: vehicle.currentUnavailability.id });
    } else {
      setVehicleUnavailable(vehicle);
    }
  };

  useEffect(() => {
    if (selectedVehicle) setShowVehicleForm(true);
  }, [selectedVehicle]);

  const showAddBtn = flags.addDriverAndVehicle?.is_active || undefined;
  const title =
    city && country
      ? `${t('mobility.vehiclesListTitle')} ${city}, ${country} | ${agencyName} View`
      : '';

  const renderToolbar = useMemo(
    () =>
      showAddBtn && (
        <Button
          className="btn btn-add"
          text={t('mobility.addVehicle')}
          variant="primary"
          onClick={() => setShowVehicleForm(true)}
        />
      ),
    [showAddBtn, t],
  );

  return (
    <section className="hbh-container vehicle-list">
      <PageTitle bottomLine className="vehicle-list-title" filters={renderToolbar} title={title} />

      <Tabs
        badges={[vehicleTotal, sharedVehicleTotal]}
        className="vehicle-list-tabs"
        selectedTab={tabs.indexOf(selectedTab)}
        tabs={tabs}
        onTabSelect={setSelectedTab}
      />

      {selectedTab === tabs[0] && (
        <VehicleList
          data={vehicleList}
          isEditable
          isLoading={isVehiclesLoading}
          isLoadingProcess={isUnavailableProcess}
          selectedPage={vehicleSelectedPage}
          totalItems={vehicleTotal}
          setPageSize={setVehiclePageSize}
          setSelectedPage={setVehicleSelectedPage}
          onVehicleEdit={setSelectedVehicle}
          onVehicleStatusChange={handleVehicleStatusChange}
        />
      )}

      {selectedTab === tabs[1] && (
        <VehicleList
          data={sharedVehicleList}
          isLoading={isSharedVehiclesLoading}
          selectedPage={sharedVehicleSelectedPage}
          totalItems={sharedVehicleTotal}
          setPageSize={setSharedVehiclePageSize}
          setSelectedPage={setSharedVehicleSelectedPage}
          onVehicleEdit={setSelectedVehicle}
          onVehicleStatusChange={handleVehicleStatusChange}
        />
      )}

      <Modal
        showBtnClose
        variant="light"
        visible={showVehicleForm}
        onClose={() => {
          setShowVehicleForm(false);
          if (selectedVehicle) setSelectedVehicle(null);
        }}
      >
        <VehicleEdit
          isLoading={isVehicleCreating || isVehicleUpdating}
          readOnly={selectedTab === tabs[1]}
          vehicleId={selectedVehicle}
          createVehicle={createVehicle}
          updateVehicle={updateVehicle}
        />
      </Modal>

      <Modal
        showBtnClose
        variant="dark"
        visible={!!vehicleUnavailable}
        onClose={() => setVehicleUnavailable(null)}
      >
        {vehicleUnavailable && (
          <Unavailability
            id={vehicleUnavailable.id}
            label={vehicleUnavailable.model?.name || vehicleUnavailable.label}
            type={MobilityListType.Vehicle}
            onSubmit={() => {
              if (selectedTab === tabs[0]) getVehicles();
              if (selectedTab === tabs[1]) getSharedVehicles();
              setVehicleUnavailable(null);
            }}
          />
        )}
      </Modal>
    </section>
  );
};

export default VehicleListPage;
