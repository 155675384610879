/* istanbul ignore file */
import { type FC, Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrayField, Multistep, useMultistepApi } from 'informed';

import { TextField } from '@ui-modules/informed';
import {
  BookingState,
  Drive,
  DriveFormSteps,
  DriveStatus,
  DropdownOption,
  PlDriveFormData,
} from '@common/interfaces';
import { useFacility, useFieldState } from '@common/hooks';
import { Button } from '@components';
import { ArrowLeft, ArrowRight } from '@assets/svg/icons';

import {
  GuestAgencyField,
  GuestEmailField,
  GuestNameField,
  GuestPhoneNumberField,
  GuestSurnameField,
  IndexNumberField,
  ManagerEmailField,
  NoPaxField,
  PaxAgencyField,
  PaxEmailField,
  PaxField,
  PaxNameField,
  PaxPhoneField,
  PaxRequestUnitField,
  PaxSurnameField,
  RemarksField,
  RequestUnitField,
} from '../../../fields';
import '../../../styles/modal.scss';

interface StepProps {
  bookingState: BookingState;
  drive?: Drive;
  formData: PlDriveFormData | null;
  paxOptions: DropdownOption[];
  onCancel: () => void;
  onCancelDrive: () => void;
  onTimeSlotEdit: () => void;
}

const MainStep: FC<StepProps> = ({
  bookingState,
  drive,
  formData,
  paxOptions,
  onCancel,
  onCancelDrive,
  onTimeSlotEdit,
}) => {
  const {
    agencyName,
    facility: { agencyId, emergencyLevel },
    facilityId,
  } = useFacility();
  const { t } = useTranslation();
  const { next, setCurrent } = useMultistepApi();
  const { value: pax } = useFieldState<DropdownOption>(`${DriveFormSteps.Main}.pax`);

  const [reqUnitOptions, setRegUnitOptions] = useState<DropdownOption[]>([]);
  const userAgencyOption = useMemo(
    () => ({ label: agencyName, value: agencyId }),
    [agencyId, agencyName],
  );

  const isEditProcess = !!formData?.id;
  const isNotEditable = isEditProcess && formData?.driveStatus !== DriveStatus.NotStarted;
  const isL3Emergency = emergencyLevel === 3;
  const isTimeSlotEditable =
    isEditProcess &&
    (formData?.driveStatus === DriveStatus.Completed ||
      formData?.driveStatus === DriveStatus.Finished ||
      formData?.driveStatus === DriveStatus.Started);
  const numberOfInitPax = Number(pax?.value) - 1;

  const getEmergencyFieldsSection = (): JSX.Element => (
    <ArrayField name="passengersInfo">
      {() => (
        <ArrayField.Items>
          {({ index, name }) => {
            if (index >= numberOfInitPax) return <Fragment key={name} />;

            return (
              <Fragment key={name}>
                <div className="section-title">Passenger {index + 2} details</div>

                <div className="field-group">
                  <TextField name="pk" type="hidden" />
                  <PaxNameField />
                  <PaxSurnameField />
                  <PaxEmailField />
                  <PaxAgencyField />
                  <PaxPhoneField />
                  <PaxRequestUnitField
                    defaultValue={
                      isEditProcess
                        ? drive?.booking?.passengersInfo[index].requestingUnit
                        : undefined
                    }
                    idx={index}
                    options={reqUnitOptions}
                  />
                </div>
              </Fragment>
            );
          }}
        </ArrayField.Items>
      )}
    </ArrayField>
  );

  return (
    <Multistep.Step step={DriveFormSteps.Main}>
      <nav className="steps">
        <Button
          className="btn"
          data-testid="planner-drive-form-btn-main-info"
          disabled
          text={t('planner.mainInfo')}
          variant="transparent"
          onClick={() => setCurrent(DriveFormSteps.Main)}
        />
        <Button
          className="btn"
          data-testid="planner-drive-form-btn-trip-info"
          text={t('planner.tripInfo')}
          variant="transparent"
          onClick={() => setCurrent(DriveFormSteps.Trip)}
        />
      </nav>

      <section className="fieldset">
        <div className="row">
          <div className="column">
            <GuestNameField />
            <GuestSurnameField />
            <GuestEmailField disabled={bookingState === BookingState.Ongoing} />
            <GuestPhoneNumberField />
            <PaxField disabled={isEditProcess && isL3Emergency} options={paxOptions} />
          </div>
          <div className="column">
            <GuestAgencyField userAgencyOption={userAgencyOption} />
            <IndexNumberField />
            <RequestUnitField
              defaultValue={isEditProcess ? drive?.booking?.requestingUnit : undefined}
              facilityId={facilityId}
              options={reqUnitOptions}
              onRegUnitLoad={setRegUnitOptions}
            />
            <ManagerEmailField />
            <NoPaxField isEdit={isEditProcess} pax={drive?.booking.pax} paxOptions={paxOptions} />
          </div>
        </div>
        <div className="row">
          <div className="field">
            <TextField
              className="input"
              data-testid="planner-drive-form-field-budget-code"
              label={`${t('common.budgetCode')} (${t('common.optional')})`}
              name="budgetCode"
            />
          </div>
        </div>
        <div className="row">
          <RemarksField />
        </div>

        {isL3Emergency && pax && Number(pax?.value) > 1 ? getEmergencyFieldsSection() : null}

        <footer>
          <Button
            className="btn-back"
            data-testid="planner-drive-form-btn-cancel"
            leftIcon={<ArrowLeft />}
            text={t('common.btnBackToPlanner')}
            variant="transparent"
            onClick={onCancel}
          />

          <div>
            {isNotEditable || !isEditProcess ? null : (
              <Button text={t('common.btnCancelDrive')} variant="danger" onClick={onCancelDrive} />
            )}

            {isTimeSlotEditable && (
              <Button
                text={t('planner.btnTimeSlotEdit')}
                variant="warning"
                onClick={onTimeSlotEdit}
              />
            )}

            <Button
              data-testid="planner-drive-form-btn-next"
              rightIcon={<ArrowRight />}
              text={t('common.btnNext')}
              variant="primary"
              onClick={next}
            />
          </div>
        </footer>
      </section>
    </Multistep.Step>
  );
};

export default MainStep;
