/* istanbul ignore file */
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormApi, useFormState, useFieldState } from 'informed';
import { t } from 'i18next';

import { Dropdown } from '@ui-modules/informed';
import { DriveFormSteps, DropdownOption } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

const GuestAgencyField = ({ userAgencyOption }: { userAgencyOption: DropdownOption }) => {
  const { setValue } = useFormApi();
  const { values } = useFormState() as any;
  const { value: noPax } = useFieldState('noPax');

  useEffect(() => {
    if (noPax) {
      setValue(`${DriveFormSteps.Main}.agency`, userAgencyOption);
    } else if (values?.agenciesOptions?.length === 1) {
      const [agencyValue] = values.agenciesOptions;
      setValue(`${DriveFormSteps.Main}.agency`, agencyValue);

      if (agencyValue.isThirdParty) {
        toast.warn('Please make sure that the email entered is correct');
      }
    }
  }, [noPax, setValue, userAgencyOption, values.agenciesOptions]);

  return (
    <div className="field">
      <Dropdown
        className="dropdown"
        data-testid="planner-drive-form-field-agency"
        label={t('common.agency')}
        name="agency"
        placeholder={values.agenciesOptions?.length > 1 ? 'Select...' : 'Provide user email first'}
        options={values?.agenciesOptions || []}
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default GuestAgencyField;
