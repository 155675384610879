export function ChatIconClosed() {
  return (
    <svg width="40" height="48" viewBox="0 0 40 48" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M21.6 10.4651H34.9333L36.6 12.2093V19.2907H38.3333L40 21.0349V24.4186L38.3333 26.1628H36.6V36.6279L34.9333 38.3721H24.5333L16.2 48L13.3333 46.7442V38.3721H5L3.33333 36.1395V26.1628H1.66667L0 24.4186V21.0349L1.66667 19.2907H3.33333V11.7209L5.1 10.4651H18.4333V6.48837C17.9109 6.19745 17.4719 5.76623 17.1614 5.23899C16.8509 4.71176 16.6802 4.10753 16.6667 3.48837C16.6667 2.5632 17.0179 1.67592 17.643 1.02172C18.2681 0.367524 19.1159 0 20 0C20.8841 0 21.7319 0.367524 22.357 1.02172C22.9821 1.67592 23.3333 2.5632 23.3333 3.48837C23.3059 4.08603 23.1321 4.66628 22.8286 5.17333C22.525 5.68038 22.1019 6.0972 21.6 6.38372V10.4651ZM33.3333 24.4186V13.9535H6.66667V34.3953L15 34.8837H16.6667V42.5233L22.6667 35.407L23.8333 34.8837H33.3333V24.4186ZM26.2667 26.1977C25.0185 27.5071 23.4307 28.4046 21.7 28.7791V28.7442C20.2562 29.0333 18.7668 28.9492 17.3611 28.4994C15.9553 28.0495 14.6757 27.2475 13.6333 26.1628L11.3 28.6395C12.4333 29.8256 13.7833 30.7605 15.2667 31.3953C16.7667 32.0512 18.3733 32.393 20 32.407C23.2225 32.3864 26.3094 31.0466 28.6 28.6744L26.2667 26.1977ZM14.9667 19.186H11.6333V22.6744H14.9667V19.186ZM24.9667 19.186H28.3V22.6744H24.9667V19.186Z"
      />
    </svg>
  );
}

export function ChatIconOpened() {
  return (
    <svg
      width="256"
      height="256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      transform="scale(0.75)"
    >
      <path
        fill="currentColor"
        d="M128,157.8l-81.9,81.9c-8.3,8.3-21.7,8.4-29.9,0.1c-8.3-8.3-8.2-21.6,0.1-29.9L98.2,128L16.3,46.1C8,37.8,7.9,24.4,16.2,16.2c8.3-8.3,21.6-8.2,29.9,0.1L128,98.2l81.9-81.9c8.3-8.3,21.7-8.4,29.9-0.1c8.3,8.3,8.2,21.6-0.1,29.9L157.8,128l81.9,81.9c8.3,8.3,8.4,21.7,0.1,29.9c-8.3,8.3-21.6,8.2-29.9-0.1L128,157.8z"
      />
    </svg>
  );
}
