import { useTranslation } from 'react-i18next';
import { IndInvoiceDetails } from '@common/interfaces';
import './PassengerDetails.styles.scss';

type PassengerDetailsProps = { data?: IndInvoiceDetails };

const PassengerDetails = ({ data }: PassengerDetailsProps) => {
  const { t } = useTranslation();

  const details = [
    { label: t('common.name'), value: data?.firstName },
    { label: t('common.surname'), value: data?.lastName },
    { label: t('common.email'), value: data?.email },
    { label: t('common.phoneNumber'), value: data?.phoneNumber },
    { label: t('common.agency'), value: data?.agency?.shortName || '-' },
    { label: t('common.vendorNumber'), value: data?.indexNumber || '-' },
    { label: t('invoice.requestUnit'), value: data?.requestingUnitDisplay },
    { label: t('common.purpose'), value: data?.purposeDisplay },
  ];

  return (
    <ul className="passenger-details">
      {details.map(({ label, value }) => (
        <li key={label}>
          <div className="label">{label}</div>
          <div className="value">{value || ''}</div>
        </li>
      ))}
    </ul>
  );
};

export default PassengerDetails;
