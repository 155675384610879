import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useRepository } from '@context';

interface Switcher {
  is_active: boolean;
  note: string;
}

interface MaintenanceBannerResponse {
  bannerMsg: string | null;
  closeBanner: () => void;
}

export default function useMaintenanceBanner(switchName: string): MaintenanceBannerResponse {
  const { accountRepository } = useRepository();

  const [bannerMsg, setBannerMsg] = useState<string | null>(null);
  const [isBannerClosed, setBannerClosed] = useState<boolean>(() =>
    Boolean(sessionStorage.getItem(switchName)),
  );

  const closeBanner = useCallback(() => {
    setBannerClosed(true);
    setBannerMsg(null);
    sessionStorage.setItem(switchName, 'true');
  }, [switchName]);

  useQuery('get-maintenance-mode', () => accountRepository.getMonolithFeatureFlags(), {
    enabled: !isBannerClosed,
    onSuccess: (data: Record<string, any>) => {
      const switcher = data.switches?.[switchName] as Switcher;
      if (switcher?.is_active && !!switcher?.note) setBannerMsg(switcher.note);
    },
  });

  return { bannerMsg, closeBanner };
}
