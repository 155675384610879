/* istanbul ignore file */
import type { NavigateFunction as NFunc } from 'react-router';
import type { TFunction as TFunc } from 'react-i18next';
import type { UseMutateFunction } from 'react-query';
import { format } from 'date-fns';

import { Carpooled, Edit, Electric, Manual, Pencil, ReadOnly, Vehicle } from '@assets/svg/icons';
import Device from '@assets/svg/icons/logbook/Device';
import {
  CRReportList,
  FuelType,
  InvAgencyCreate,
  SummaryList,
  SummaryType,
} from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { Button, Tooltip } from '@components';

const getSummaryTypeIcon = (record: CRReportList): JSX.Element | null => {
  const applyIcon = (icon: JSX.Element, tooltip: string): JSX.Element => (
    <Tooltip label={tooltip}>{icon}</Tooltip>
  );

  let icon: JSX.Element | null;

  switch (record.summaryType) {
    case SummaryType.DriverManualInput:
      icon = applyIcon(<Pencil />, record.summaryTypeDisplay);
      break;
    case SummaryType.FocalPointManualInput:
      icon = applyIcon(<Manual />, record.summaryTypeDisplay);
      break;
    case SummaryType.VTS:
      icon = applyIcon(<Vehicle />, record.summaryTypeDisplay);
      break;
    case SummaryType.GPS:
      icon = applyIcon(<Device />, record.summaryTypeDisplay);
      break;
    default:
      icon = null;
      break;
  }

  return icon;
};

const getOdometerDetails = (record: CRReportList): JSX.Element => {
  const data = record.vtsData || record.details;
  const odometer = data ? `${data?.startKm}/${data?.endKm}` : '';

  return <Tooltip label={odometer}>{odometer}</Tooltip>;
};

type ColumnsReportDetails = {
  isLoading: boolean;
  linkDetails: string;
  nav: NFunc;
  t: TFunc;
  setCurrentDrive: (record: CRReportList) => void;
  getDriveWithWorkflow: UseMutateFunction<unknown, unknown, string>;
};

export const getReportColumns = ({
  isLoading,
  linkDetails,
  nav,
  t,
  setCurrentDrive,
  getDriveWithWorkflow,
}: ColumnsReportDetails): Record<string, any>[] => [
  {
    dataIndex: 'pickupDate',
    key: 'pickupDate',
    title: t('report.performedOn'),
    width: 90,
  },
  {
    dataIndex: 'vehicle',
    key: 'vehicle',
    title: t('common.vehicle'),
    ellipsis: {
      showTitle: true,
    },
    width: 120,
  },
  {
    dataIndex: 'passenger',
    key: 'passenger',
    title: t('common.pax'),
    ellipsis: {
      showTitle: true,
    },
  },
  {
    dataIndex: 'agency',
    key: 'agency',
    title: t('common.agencyPax'),
    ellipsis: {
      showTitle: true,
    },
    width: 80,
  },
  {
    dataIndex: 'refCode',
    key: 'refCode',
    title: t('common.refCode'),
    width: 55,
  },
  {
    dataIndex: 'budgetCode',
    key: 'budgetCode',
    title: t('common.budgetCode'),
    width: 55,
  },
  {
    dataIndex: 'typeOfTrip',
    key: 'typeOfTrip',
    title: t('common.typeOfTrip'),
    width: 70,
  },
  {
    dataIndex: 'driverFullName',
    key: 'driverFullName',
    title: t('common.driver'),
    ellipsis: {
      showTitle: true,
    },
  },
  {
    dataIndex: 'pax',
    key: 'pax',
    title: 'Pax N°',
    width: 40,
  },
  {
    dataIndex: 'transferType',
    key: 'transferType',
    title: t('common.transferType'),
    width: 80,
  },
  {
    ellipsis: {
      showTitle: true,
    },
    key: 'odometer',
    title: 'Odometer',
    width: 100,
    render: getOdometerDetails,
  },
  {
    key: 'km',
    title: 'Km',
    width: 40,
    render: (record: CRReportList) => (
      <span>
        {!record.hasWorkflow ? (
          <Tooltip label={t('report.autoFilled')}>{record.km}</Tooltip>
        ) : (
          record.km
        )}
      </span>
    ),
  },
  {
    key: 'duration',
    title: t('common.duration'),
    width: 60,
    render: (record: CRReportList) => (
      <span>
        {!record.hasWorkflow ? (
          <Tooltip label={t('report.autoFilled')}>{record.duration}</Tooltip>
        ) : (
          record.duration
        )}
      </span>
    ),
  },
  {
    dataIndex: 'isCarpooled',
    key: 'isCarpooled',
    title: t('common.carpooled'),
    width: 70,
    render: (record: boolean) => <span>{record ? 'Yes' : 'No'}</span>,
  },
  {
    dataIndex: 'isRideShared',
    key: 'isRideShared',
    title: t('common.rideshared'),
    width: 75,
    render: (record: boolean) => <span>{record ? 'Yes' : 'No'}</span>,
  },
  {
    key: 'price',
    title: `${t('common.price')} $`,
    render: (record: CRReportList) => (
      <span
        data-testid={`cost-recovery-vehicle-details${record.isCarpooled ? '-carpooled' : ''}${
          record.isRideShared ? '-shared' : ''
        }`}
      >
        {!record.hasWorkflow ? (
          <Tooltip label={t('report.autoFilled')}>${record.price ? record.price : '0.00'}</Tooltip>
        ) : (
          `$${record.price ? record.price : '0.00'}`
        )}
      </span>
    ),
  },
  {
    key: 'status',
    title: '',
    render: (record: CRReportList) => (
      <div className="col-status">
        <div className="col-status-summary">{getSummaryTypeIcon(record)}</div>

        <div className="col-status-fuel-type">
          {record.fuelType === FuelType.Electric ? (
            <Tooltip label="Electric">
              <Electric />
            </Tooltip>
          ) : null}
        </div>

        <div className="col-status-shared" data-testid="report-details-trip-rideshared">
          {record.isRideShared ? (
            <Tooltip label={t('report.ridesharedTrip')}>
              <Carpooled />
            </Tooltip>
          ) : null}
        </div>

        <div className="col-status-carpooled" data-testid="report-details-trip-carpooled">
          {record.isCarpooled ? (
            <Tooltip label={t('report.carpooledTrip')}>
              <Carpooled />
            </Tooltip>
          ) : null}
        </div>
      </div>
    ),
  },
  {
    key: 'actions',
    title: '',
    width: 80,
    render: (record: CRReportList) => (
      <div className="col-actions">
        <Tooltip
          label={
            record?.isEditableByFocalPoint ? t('report.editTripData') : t('report.upcomingTripsMsg')
          }
        >
          <Button
            className="btn btn-edit"
            data-testid="report-details-edit"
            leftIcon={record?.isEditableByFocalPoint ? <Edit /> : <ReadOnly />}
            variant="icon"
            onClick={() => {
              if (!isLoading) {
                setCurrentDrive(record);
                getDriveWithWorkflow(record.id);
              }
            }}
          />
        </Tooltip>
        <Button
          className="btn btn-booking-details"
          variant="transparent"
          onClick={() => nav(`${linkDetails}/bookings/${record.bookingId}`)}
        />
      </div>
    ),
  },
];

type ColumnsSummary = {
  agencyId: string;
  month: string;
  year: string;
  createInvoice: UseMutateFunction<unknown, unknown, InvAgencyCreate>;
  t: TFunc;
};

export const getSummaryColumns = ({
  agencyId,
  month,
  year,
  createInvoice,
  t,
}: ColumnsSummary): Record<string, any>[] => [
  {
    dataIndex: 'agency',
    key: 'agency',
    title: t('common.agency'),
    ellipsis: {
      showTitle: false,
    },
  },
  {
    dataIndex: 'drives',
    key: 'drives',
    title: t('common.trips'),
  },
  {
    dataIndex: 'bookingsCount',
    key: 'bookingsCount',
    title: t('crumbs.bookings'),
  },
  {
    dataIndex: 'totalMileage',
    key: 'totalMileage',
    title: t('report.totalMileage'),
  },
  {
    dataIndex: 'totalTime',
    key: 'totalTime',
    title: t('report.totalTime'),
  },
  {
    dataIndex: 'airport',
    key: 'airport',
    title: t('common.airport'),
  },
  {
    dataIndex: 'inTown',
    key: 'inTown',
    title: t('common.inTown'),
  },
  {
    dataIndex: 'outOfTown',
    key: 'outOfTown',
    title: t('common.outOfTown'),
  },
  {
    dataIndex: 'amount',
    key: 'amount',
    title: `${t('common.amount')} $`,
    render: (record: string) => <span>{Number(record).toFixed(2)}</span>,
  },
  {
    dataIndex: 'greenFund',
    key: 'greenFund',
    title: `${t('common.greenFund')} $`,
  },
  {
    dataIndex: 'carpooledDrives',
    key: 'carpooledDrives',
    title: t('report.carpooledTrip'),
  },
  {
    dataIndex: 'rideSharedDrives',
    key: 'rideSharedDrives',
    title: t('report.ridesharedTrip'),
  },
  {
    key: 'actions',
    title: '',
    render: (record: SummaryList) => (
      <div className="col-actions">
        {record.pdf ? (
          <Button
            className="btn-download"
            link={record.pdf}
            linkStyle="submit"
            text={t('common.btnDownloadInvoice')}
            variant="submit"
          />
        ) : (
          <Button
            className="btn-invoice"
            text={t('invoice.createInvoice')}
            variant="primary"
            onClick={() => {
              createInvoice({
                agency: record.agencyId,
                agency_of_fp: agencyId,
                reference_date: format(new Date(+year, +month - 1, 1), DF.ApiDate),
              });
            }}
          />
        )}
      </div>
    ),
  },
];
