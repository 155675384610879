type LatLngLiteral = google.maps.LatLngLiteral;
type GoogleMapLinkProps = { origins: LatLngLiteral | null; destinations: LatLngLiteral | null };
const BASE_GOOGLE_MAPS_URL = 'https://www.google.com/maps/dir/';

const GoogleMapLink = ({ origins, destinations }: GoogleMapLinkProps) => {
  if (origins && destinations) {
    const urlParams = new URLSearchParams({
      api: '1',
      origin: `${origins.lat},${origins.lng}`,
      destination: `${destinations.lat},${destinations.lng}`,
      travelmode: 'driving',
    });

    return (
      <a
        className="ddo-btn ddo-btn-link link-google-map"
        href={`${BASE_GOOGLE_MAPS_URL}?${urlParams.toString()}`}
        rel="noreferrer"
        target="_blank"
      >
        Show on Google Map
      </a>
    );
  }

  return null;
};

export default GoogleMapLink;
