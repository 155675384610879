import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Button, Field, Form } from '@components';
import './ReviewAnswerForm.styles.scss';

export interface ReviewAnswerProps {
  confirmText?: string;
  feedback?: string;
  placeholder?: string;
  requireValidation?: boolean;
  title?: string;
  onConfirm?: (answer: string) => void;
}

const ReviewAnswerForm = ({
  confirmText,
  feedback,
  placeholder,
  requireValidation = false,
  title,
  onConfirm,
}: ReviewAnswerProps) => {
  const [answer, setAnswer] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(!requireValidation);

  useEffect(() => {
    setIsValid(requireValidation ? !!answer?.trim() : true);
  }, [answer, requireValidation]);

  const handleSubmit = (state: { answer: string }) => {
    onConfirm?.(state.answer.trim());
    setAnswer('');
  };

  return (
    <div className="review-answer">
      {title && <h2 className="review-answer-title">{title}</h2>}

      <div className="review-answer-content">
        {feedback && (
          <div className="review-answer-feedback">
            <div className="review-answer-feedback-title">Feedback</div>
            <div className="review-answer-feedback-text">{feedback}</div>
          </div>
        )}

        <Form
          className="review-answer-form"
          defaultValues={{ answer: answer || '' }}
          schema={yup.object({ answer: yup.string().optional() })}
          onSubmit={handleSubmit}
        >
          <Field
            name="answer"
            input="textarea"
            placeholder={placeholder}
            onChange={(value: string) => setAnswer(value)}
          />

          {!!confirmText && (
            <Button
              className="btn btn-confirm"
              disabled={!isValid}
              text={confirmText}
              variant="primary"
              type="submit"
            />
          )}
        </Form>
      </div>
    </div>
  );
};

export default ReviewAnswerForm;
