import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { Checkbox as CheckboxInput, type CheckboxProps as CheckboxInputProps } from '@mantine/core';
import './Checkbox.styles.scss';

export type CheckboxProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<CheckboxInputProps, 'checked' | 'defaultValue'>;

export const Checkbox = <T extends FieldValues>({
  control,
  defaultValue,
  name,
  rules,
  shouldUnregister,
  onChange,
  ...props
}: CheckboxProps<T>) => {
  const {
    field: { value, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <CheckboxInput
      classNames={{ error: 'field-error', root: 'checkbox', label: 'checkbox-label' }}
      error={fieldState.error?.message}
      value={value}
      checked={value}
      onChange={(e) => {
        fieldOnChange(e);
        onChange?.(e);
      }}
      {...field}
      {...props}
    />
  );
};
