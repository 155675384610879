import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useDebounce } from 'usehooks-ts';
import { Pagination, Table } from '@unbooking/ui-modules';

import { CURRENT_MONTH, CURRENT_YEAR, MONTHS, TABLE_ITEMS, YEARS } from '@common/constants';
import { CRDriveUpdate, CRDriveWorkflow, CRReportList, ReportParams } from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { DateFormat as DF } from '@common/types';
import { Serializer } from '@common/utils';
import { useRepository } from '@context';
import { Field, Form, Modal } from '@components';
import { ReportSchema } from '../schemas';
import { defaultOptions, getReportColumns } from '../utils';
import EditDriveForm from './EditDriveForm/EditDriveForm';

export interface FormState {
  approvedBy: string;
  certifiedBy: string;
  isCarpooled: string;
  isRideShared: string;
  month: string;
  preparedBy: string;
  search?: string;
  year: string;
}

interface ReportDetailsProps {
  username: string;
  exportDrives: (data: ReportParams) => void;
}

const ReportDetails = ({ username, exportDrives }: ReportDetailsProps) => {
  const location = useLocation();
  const nav = useNavigate();
  const { t } = useTranslation();
  const { facility, facilityId } = useFacility();
  const { reportRepo } = useRepository();

  const [currentDrive, setCurrentDrive] = useState<CRReportList | null>(null);
  const [driveData, setDriveData] = useState<CRDriveWorkflow | null>();
  const [filters, setFilters] = useState({
    carpooled: '',
    month: CURRENT_MONTH.value,
    rideShared: '',
    search: '',
    year: CURRENT_YEAR.value,
  });
  const [pagination, setPagination] = useState({ pageSize: 10, selectedPage: 1, total: 0 });
  const [reportData, setReportData] = useState<CRReportList[]>([]);
  const [columnOrder, setColumnOrder] = useState<string>('');

  const debouncedSearch = useDebounce(filters.search, 1000);

  const closeModal = () => {
    setCurrentDrive(null);
    setDriveData(null);
  };

  const { data, isLoading, refetch } = useQuery(
    [
      'get-report',
      {
        ...filters,
        columnOrder,
        page: pagination.selectedPage,
        pageSize: pagination.pageSize,
        search: debouncedSearch,
      },
    ],
    () =>
      reportRepo.getReports(facilityId, {
        agencyId: facility?.agencyId,
        isCarpooled: filters.carpooled ? filters.carpooled === 'true' : undefined,
        isRideShared: filters.rideShared ? filters.rideShared === 'true' : undefined,
        limit: pagination.pageSize,
        month: filters.month,
        offset: !debouncedSearch ? (pagination.selectedPage - 1) * 10 : undefined,
        ordering: columnOrder,
        pickupDate: format(new Date(), DF.ApiDate),
        search: debouncedSearch?.trim(),
        year: filters.year,
      }),
    { enabled: !!facility?.agencyId },
  );

  const { isLoading: isDriveLoading, mutate: getDriveWithWorkflow } = useMutation(
    'get-drive',
    (driveId: string) => reportRepo.getDriveWithWorkflow(facilityId, driveId),
    {
      onSuccess: (res: any) => setDriveData(Serializer.formatDriveWithWorkflow(res)),
      onError: (error: AxiosError) => {
        if (error.message) toast.error(t('report.errorMsgDrive'));
      },
    },
  );

  const { mutateAsync: updateDrive } = useMutation(
    'update-drive',
    ({ driveId, payload }: { driveId: string; payload: CRDriveUpdate }) =>
      reportRepo.updateDrive(facilityId, driveId, payload),
    {
      onSuccess: () => {
        toast.success(t('mobility.msgUpdateDrive'));
        refetch();
        closeModal();
      },
      onError: (error: AxiosError) => {
        if (error.message) toast.error(error.message || t('common.errorMsgDefault'));
      },
    },
  );

  useEffect(() => {
    if (data) {
      setReportData(data.results.map(Serializer.formatReportDetails));
      setPagination((s) => ({ ...s, total: data.count }));
    }
  }, [data]);

  const handleFilterChange = (field: keyof typeof filters, value: string) => {
    setFilters((s) => ({ ...s, [field]: value }));
    setPagination((s) => ({ ...s, selectedPage: 1 }));
  };
  const handlePageChange = (page: number) => setPagination((s) => ({ ...s, selectedPage: page }));
  const handlePageSizeChange = (size: number) => setPagination((s) => ({ ...s, pageSize: size }));

  const handleSubmit = useCallback(
    async (props: FormState) => {
      const { approvedBy, certifiedBy, isCarpooled, isRideShared, month, year } = props || {};

      exportDrives({
        agencyId: facility?.agencyId,
        approvedBy,
        certifiedBy,
        isCarpooled: isCarpooled === 'true',
        isRideShared: isRideShared === 'true',
        month,
        preparedBy: username,
        year,
      });
    },
    [exportDrives, facility?.agencyId, username],
  );

  const initFormData: FormState = useMemo(
    () => ({
      approvedBy: '',
      certifiedBy: '',
      isCarpooled: defaultOptions[0].value,
      isRideShared: defaultOptions[0].value,
      month: CURRENT_MONTH.value,
      preparedBy: username,
      search: '',
      year: CURRENT_YEAR.value,
    }),
    [username],
  );
  const schema = useMemo(() => ReportSchema(), []);

  return (
    <section className="report-details">
      <div className="row">
        <Form
          className="report-details-form"
          defaultValues={initFormData}
          id="report_filters_form"
          schema={schema}
          onSubmit={handleSubmit}
        >
          <div className="export-fields">
            <Field
              name="preparedBy"
              disabled
              label={t('report.inputPreparedBy')}
              value={username}
            />
            <Field name="certifiedBy" label={t('report.inputCertifiedBy')} />
            <Field name="approvedBy" label={t('report.inputApprovedBy')} />
          </div>

          <div className="filters">
            <Field
              name="search"
              className="search"
              label="&nbsp;"
              placeholder={t('report.inputSearch')}
              onChange={(e: string) => handleFilterChange('search', e)}
              data-testid="report-details-input-search"
            />

            <Field
              name="isCarpooled"
              input="dropdown"
              className="carpooled"
              id="cost_recovery_report_carpooled_dropdown"
              label={t('common.carpooled')}
              options={defaultOptions}
              onChange={(value: string) => handleFilterChange('carpooled', value)}
              data-testid="report-details-select-carpooled"
            />

            <Field
              name="isRideShared"
              input="dropdown"
              className="rideshared"
              id="cost_recovery_report_rideshared_dropdown"
              label={t('common.rideshared')}
              options={defaultOptions}
              onChange={(value: string) => handleFilterChange('rideShared', value)}
              data-testid="report-details-select-rideshared"
            />

            <Field
              name="month"
              input="dropdown"
              className="month"
              id="cost_recovery_report_month_dropdown"
              label={t('common.month')}
              options={MONTHS}
              onChange={(value: string) => handleFilterChange('month', value)}
            />

            <Field
              name="year"
              input="dropdown"
              className="year"
              id="cost_recovery_report_year_dropdown"
              label={t('common.year')}
              options={YEARS}
              onChange={(value: string) => handleFilterChange('year', value)}
            />
          </div>
        </Form>
      </div>

      <div className="report-details-table">
        <Table
          columns={getReportColumns({
            isLoading: isDriveLoading,
            linkDetails: location.pathname.split('/').slice(0, -2).join('/'),
            nav,
            t,
            setCurrentDrive,
            getDriveWithWorkflow,
          })}
          data={reportData}
          isLoading={isLoading}
          variant="light"
          onChangeColumnOrder={setColumnOrder}
        />
      </div>

      {pagination.total > TABLE_ITEMS && (
        <Pagination
          className="pagination"
          selectedPage={pagination.selectedPage}
          showJumper
          showPageSize
          totalPages={pagination.total}
          variant="light"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      )}

      <Modal showBtnClose variant="light" visible={!!driveData} onClose={closeModal}>
        {currentDrive && driveData && (
          <EditDriveForm
            data={driveData}
            readOnly={!currentDrive.isEditableByFocalPoint}
            updateDrive={(payload: CRDriveUpdate) =>
              updateDrive({ driveId: currentDrive.id, payload })
            }
          />
        )}
      </Modal>
    </section>
  );
};

export default ReportDetails;
