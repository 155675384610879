/* istanbul ignore file */
export const LocalStorageKeys = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  AUTH_TYPE: 'AUTH_TYPE',
  EMAIL: 'email',
  EXPIRATION: 'expiration',
  ID_TOKEN: 'ID_TOKEN',
  IS_AUTHENTICATED: 'IS_AUTHENTICATED',
  LOCATION: 'location',
  LOGIN_IN_PROGRESS: 'LOGIN_IN_PROGRESS',
  USER_EMAIL: 'USER_EMAIL',
  USER_NAME: 'USER_NAME',
  USER_TYPE: 'userType',
  X_USER_TOKEN: 'X-User-Token',
};

export const SessionStorageKeys = {
  AGENCY: 'agency',
  AGENCY_ID: 'agencyId',
  FACILITY: 'facility',
  REDIRECT_URL: 'redirectUrl',
  SELECTED_LANGUAGE: 'selectedLang',
  SUCCESS_REDIRECT_URI: 'successRedirectUri',
};
