/* istanbul ignore file */
import { FC, Fragment } from 'react';
import { ArrayField, Multistep, useFormApi, useFormState, useMultistepApi } from 'informed';
import { useTranslation } from 'react-i18next';

import { Button } from '@ui-modules';
import {
  Drive,
  DriveFormSteps,
  DriveFormState,
  DropdownOption,
  PlDrive,
  PlDriveFormData,
  PlVehicle,
} from '@common/interfaces';
import { calcSharedDrive } from '@common/utils';

import RecurringDrive from './RecurringDrive';
import {
  ButtonSubmit,
  CommentField,
  DriverField,
  DropoffDateField,
  DropoffLocationField,
  DropoffTimeField,
  DropoffTownField,
  PickupDateField,
  PickupLocationField,
  PickupTimeField,
  PickupTownField,
  VehicleField,
} from './fields';
import '../DriveForm.styles.scss';

interface StepProps {
  drive?: Drive;
  drives: PlDrive[];
  driverOptions: DropdownOption[];
  dropoffLocationChoices?: { [x: string]: DropdownOption[] };
  dropoffTownOptions?: DropdownOption[];
  formData: PlDriveFormData | null;
  pickupLocationChoices?: { [x: string]: DropdownOption[] };
  pickupTownOptions?: DropdownOption[];
  vehicleOptions: DropdownOption[];
  vehicles: PlVehicle[];
}

const ReturnTripStep: FC<StepProps> = ({
  drive,
  drives,
  driverOptions,
  dropoffLocationChoices,
  dropoffTownOptions,
  formData,
  pickupLocationChoices,
  pickupTownOptions,
  vehicleOptions,
  vehicles,
}) => {
  const formApi = useFormApi();
  const { values } = useFormState() as any;
  const { setCurrent } = useMultistepApi();
  const { t } = useTranslation();

  const { driver } = drive! || {};

  const isEditProcess = !!formData?.id;
  const fieldGroup = 'driveList';
  const formState = { ...(values as DriveFormState)?.[DriveFormSteps.ReturnTrip] };

  const dropoffDateReturn = formState?.[fieldGroup]?.[0].dropoffDate!;
  const dropoffTimeReturn = formState?.[fieldGroup]?.[0].dropoffTime!;
  const pickupDateReturn = formState?.[fieldGroup]?.[0].pickupDate!;
  const pickupTimeReturn = formState?.[fieldGroup]?.[0].pickupTime!;
  const vehicleReturn = formState?.[fieldGroup]?.[0].vehicle!;

  const sharedDrive =
    dropoffDateReturn && dropoffTimeReturn && pickupDateReturn && pickupTimeReturn && vehicleReturn
      ? calcSharedDrive({
          drives,
          dropoffDate: dropoffDateReturn,
          dropoffTime: dropoffTimeReturn,
          pickupDate: pickupDateReturn,
          pickupTime: pickupTimeReturn,
          vehicle: vehicleReturn,
        })
      : null;

  return (
    <Multistep.Step step={DriveFormSteps.ReturnTrip}>
      {isEditProcess && (
        <div className="steps">
          <Button
            className="btn"
            data-testid="planner-drive-form-btn-main-info"
            text={t('planner.mainInfo')}
            onClick={() => setCurrent(DriveFormSteps.Main)}
          />
          <Button
            className="btn"
            data-testid="planner-drive-form-btn-trip-info"
            disabled
            text={t('planner.tripInfo')}
            onClick={() => setCurrent(DriveFormSteps.Trip)}
          />
        </div>
      )}

      <section className="fieldset">
        <ArrayField name={fieldGroup}>
          {() => (
            <ArrayField.Items>
              {({ index, name }) => (
                <Fragment key={name}>
                  <div className="section-title">2. {t('planner.returnTrip')}</div>

                  <div className="row field-group">
                    <PickupDateField fieldGroup={name} />
                    <PickupTimeField fieldGroup={name} index={index} returnTrip />
                    <PickupTownField fieldGroup={name} options={pickupTownOptions} returnTrip />
                    <PickupLocationField
                      choices={pickupLocationChoices}
                      fieldGroup={name}
                      index={index}
                      returnTrip
                    />
                    <DropoffDateField />
                    <DropoffTimeField fieldGroup={name} returnTrip />
                    <DropoffTownField fieldGroup={name} options={dropoffTownOptions} returnTrip />
                    <DropoffLocationField
                      choices={dropoffLocationChoices}
                      fieldGroup={name}
                      index={index}
                      returnTrip
                    />
                    <VehicleField vehiclesOptions={vehicleOptions} />
                    <DriverField
                      driver={driver}
                      fieldGroup={name}
                      isEdit={isEditProcess}
                      options={driverOptions}
                      sharedDrive={isEditProcess ? null : sharedDrive}
                      vehicles={vehicles}
                    />

                    <CommentField label={t('planner.commentPax')} name="commentToPax" />
                    <CommentField label={t('planner.commentDriver')} name="commentToDriver" />
                  </div>
                </Fragment>
              )}
            </ArrayField.Items>
          )}
        </ArrayField>

        {values.addRecurring ? (
          <div className="row">
            <RecurringDrive currentDate={formData?.resDate} step={DriveFormSteps.ReturnTrip} />
          </div>
        ) : null}

        <div className="buttons">
          <Button
            data-testid="planner-drive-form-btn-back"
            text={t('common.btnBack')}
            variant="secondary"
            onClick={() => setCurrent(DriveFormSteps.Trip)}
          />

          {values.addRecurring ? (
            <Button
              onClick={() => formApi.setValue('addRecurring', false)}
              text={t('common.recurring.cancelRecurringBtn')}
              variant="primary"
            />
          ) : (
            <Button
              onClick={() => formApi.setValue('addRecurring', true)}
              text={t('common.recurring.addRecurringBtn')}
              variant="primary"
            />
          )}

          {sharedDrive ? (
            <Button
              data-testid="planner-drive-form-btn-save"
              text={t('common.btnSave')}
              variant="primary"
              onClick={() => setCurrent(DriveFormSteps.ApprovingSharedDrive)}
            />
          ) : (
            <ButtonSubmit />
          )}
        </div>
      </section>
    </Multistep.Step>
  );
};

export default ReturnTripStep;
