/* istanbul ignore file */
import * as yup from 'yup';

const CountryReportSchema = () =>
  yup.object().shape({
    month: yup.string().nullable().optional(),
    year: yup.string().nullable().optional(),
  });

export default CountryReportSchema;
