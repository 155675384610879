import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { NumberInput, type NumberInputProps } from '@mantine/core';

export type InputNumberProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<NumberInputProps, 'value' | 'defaultValue'>;

export function InputNumber<T extends FieldValues>({
  control,
  defaultValue,
  name,
  required,
  rules,
  shouldUnregister,
  onChange,
  ...props
}: InputNumberProps<T>) {
  const {
    field: { value, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <NumberInput
      classNames={{
        description: 'input-number-description',
        input: 'input-number',
        label: 'input-number-label',
        root: 'input-number-root',
        wrapper: 'input-number-wrapper',
        error: 'field-error',
      }}
      error={fieldState.error?.message}
      value={value}
      withAsterisk={!!required}
      onChange={(val) => {
        fieldOnChange(val);
        onChange?.(val);
      }}
      {...field}
      {...props}
    />
  );
}
