import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { DatePicker, type DateValue, type DatePickerProps } from '@mantine/dates';
import './DatePickerCalendar.styles.scss';

export type DatePickerCalendarProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<DatePickerProps, 'value' | 'defaultValue'> & { label?: string };

export function DatePickerCalendar<T extends FieldValues>({
  control,
  defaultValue,
  label,
  name,
  rules,
  shouldUnregister,
  onChange,
  ...props
}: DatePickerCalendarProps<T>) {
  const {
    field: { value, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <div className="date-picker">
      {label && <label htmlFor="date-picker-calendar">{label}</label>}
      <DatePicker
        {...field}
        {...props}
        classNames={{ calendar: 'date-picker-calendar' }}
        id="date-picker-calendar"
        hideOutsideDates
        value={value}
        onChange={(date: DateValue | null) => {
          fieldOnChange(date);
          onChange?.(date);
        }}
      />
      {fieldState.error && <span className="field-error">{fieldState.error.message}</span>}
    </div>
  );
}
