/* istanbul ignore file */
import { t } from 'i18next';
import { FieldState, useFormApi } from 'informed';
import { CheckboxField } from '@components';
import { DriveFormSteps, DropdownOption } from '@common/interfaces';
import '../styles/modal.scss';

interface FieldProps {
  isEdit: boolean;
  pax?: number;
  paxOptions: DropdownOption[];
}

const NoPaxField = ({ isEdit, pax, paxOptions }: FieldProps) => {
  const { setValue } = useFormApi();

  return (
    <div className="field field-checkbox">
      <CheckboxField
        data-testid="planner-drive-form-field-no-pax"
        disabled={isEdit}
        label={`${t('common.no')} ${t('common.passengers')}`}
        name="noPax"
        type="primary"
        onChange={(state: FieldState) => {
          if (state.value) {
            setValue(
              `${DriveFormSteps.Main}.pax`,
              paxOptions.find((i) => +i.value === pax),
            );
          } else {
            setValue(`${DriveFormSteps.Main}.agency`, '');
          }
        }}
      />
    </div>
  );
};

export default NoPaxField;
