/* istanbul ignore file */
import { isAfter, isBefore, isWithinInterval, parse } from 'date-fns';
import { PlDrive, ResViewType } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';

// Drag & Drop

type DropParams = {
  drives: PlDrive[];
  start: Date;
  end: Date;
  resourceId: string;
  resourceType: ResViewType;
};

const getShuttleConfig = (shuttle: PlDrive, resourceType: ResViewType) => {
  const { driverId, vehicleId, pickupDate, pickupTime, dropoffDate, dropoffTime } = shuttle;
  const resource = resourceType === ResViewType.Vehicle ? vehicleId : driverId;
  const shuttleStart = parse(`${pickupDate} ${pickupTime}`, DF.ApiDateAltFull, new Date());
  const shuttleEnd = parse(`${dropoffDate} ${dropoffTime}`, DF.ApiDateAltFull, new Date());

  return { resource, shuttleStart, shuttleEnd };
};

export const checkOverlapEventAllow = ({
  drives,
  start,
  end,
  resourceId,
  resourceType,
}: DropParams): boolean => {
  const resKey = resourceType === ResViewType.Driver ? 'driverId' : 'vehicleId';
  const relevantDrives = drives.filter((drive) => drive[resKey] === resourceId);

  const overlap = relevantDrives.filter((drive) => {
    const driveStart = parse(drive.pickupTime, DF.ApiTimeFull, start);
    const driveEnd = parse(drive.dropoffTime, DF.ApiTimeFull, end);

    if (isAfter(driveStart, driveEnd)) return false;

    return isBefore(driveStart, end) && isAfter(driveEnd, start);
  });

  const resOverlapKey = resourceType === ResViewType.Driver ? 'vehicleId' : 'driverId';
  const hasMultipleOverlaps = overlap.length >= 2;
  const hasSameDriver = overlap.every((i) => i[resOverlapKey] === overlap[0][resOverlapKey]);

  return !(hasMultipleOverlaps && !hasSameDriver);
};

export const checkSharedDriveEvent = ({
  drives,
  start,
  end,
  resourceId,
  resourceType,
}: DropParams): PlDrive | null => {
  const resKey = resourceType === ResViewType.Driver ? 'driverId' : 'vehicleId';

  return (
    drives
      .filter((drive) => drive[resKey] === resourceId)
      .map((drive) => {
        const driveStart = parse(drive.pickupTime, DF.ApiTimeFull, start);
        const driveEnd = parse(drive.dropoffTime, DF.ApiTimeFull, end);

        return { drive, isOverlapping: isBefore(driveStart, end) && isAfter(driveEnd, start) };
      })
      .find(({ isOverlapping }) => isOverlapping)?.drive || null
  );
};

export const checkShuttleEventOverlap = ({
  drives,
  start,
  end,
  resourceId,
  resourceType,
}: DropParams): boolean =>
  drives.some((shuttle) => {
    const { resource, shuttleStart, shuttleEnd } = getShuttleConfig(shuttle, resourceType);

    return (
      resource === resourceId &&
      (isWithinInterval(start, { start: shuttleStart, end: shuttleEnd }) ||
        isWithinInterval(end, { start: shuttleStart, end: shuttleEnd }) ||
        (isBefore(start, shuttleStart) && isAfter(end, shuttleEnd)))
    );
  });

export const checkShuttleWithinRange = ({
  drives,
  start,
  end,
  resourceId,
  resourceType,
}: DropParams): PlDrive | null =>
  drives.find((shuttle) => {
    const { resource, shuttleStart, shuttleEnd } = getShuttleConfig(shuttle, resourceType);

    return (
      resource === resourceId &&
      isWithinInterval(start, { start: shuttleStart, end: shuttleEnd }) &&
      isWithinInterval(end, { start: shuttleStart, end: shuttleEnd })
    );
  }) || null;
