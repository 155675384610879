import { t } from 'i18next';
import { Crumb } from '@common/interfaces';
import {
  AvailableLocationsPage,
  BookingDetailsPage,
  BookingFormPage,
  BookingListPage,
  BookingsPage,
  ChecklistDetailsPage,
  CostRecoveryReportPage,
  CountryReportPage,
  CreateAgencyInvoicePage,
  CreateIndividualInvoicePage,
  DashboardPage,
  DriverListPage,
  EntryPage,
  FacilityMenuPage,
  FeedbacksPage,
  FinancialsMenuPage,
  IndividualInvoiceListPage,
  InvoiceHistoryPage,
  MobilityLogbookDetailsPage,
  MobilityLogbookPage,
  MobilityMenuPage,
  MobilityPlannerPage,
  PrivateVehicleFormPage,
  ReportingAreaMenuPage,
  ResourcesPage,
  ServiceCostSettingsPage,
  TravelRequestList,
  TravelRequestTripEdit,
  TravelRequestProfile,
  VehicleListPage,
  VehiclesChecklistPage,
} from '@pages';

import bgImg from '@assets/img/default/background.jpg';
import bgChecklist from '@assets/img/default/bg_checklist_report_page.jpeg';

interface RouteElement extends Crumb {
  children?: Crumb[];
}

const routesSchema = [
  // Booking Form
  {
    path: '/',
    Component: AvailableLocationsPage,
    bgImg,
  },
  {
    path: '/booking-form/:id',
    Component: BookingFormPage,
    bgImg,
  },

  // Digital Office
  {
    path: '/secure/office',
    Component: EntryPage,
    bgImg,
  },
  {
    path: '/secure/office/facility/:facilityId/:agencyName/:agencyId',
    Component: FacilityMenuPage,
    bgImg,
    name: t('crumbs.facilityMenu'),
    showNav: true,
    children: [
      {
        path: '/bookings',
        Component: BookingListPage,
        bgImg,
        name: t('crumbs.bookingList'),
        showNav: true,
        children: [
          {
            path: '/:id',
            Component: BookingDetailsPage,
            bgImg,
            name: t('crumbs.bookingDetails'),
            showNav: true,
          },
          {
            path: '/:id/edit-drives/:driveId',
            Component: MobilityPlannerPage,
            bgImg,
            name: t('common.mobilityPlanner'),
            showNav: true,
          },
          {
            path: '/booking-accept/:id',
            Component: MobilityPlannerPage,
            bgImg,
            name: t('common.mobilityPlanner'),
            showNav: true,
          },
        ],
      },
      {
        path: '/booking-list',
        Component: BookingsPage,
        bgImg,
        name: t('crumbs.bookingList'),
        showNav: true,
        children: [
          {
            path: '/:id',
            Component: BookingDetailsPage,
            bgImg,
            name: t('crumbs.bookingDetails'),
            showNav: true,
          },
          {
            path: '/:id/edit-drives/:driveId',
            Component: MobilityPlannerPage,
            bgImg,
            name: t('common.mobilityPlanner'),
            showNav: true,
          },
          {
            path: '/booking-accept/:id',
            Component: MobilityPlannerPage,
            bgImg,
            name: t('common.mobilityPlanner'),
            showNav: true,
          },
        ],
      },
      {
        path: '/feedbacks',
        Component: FeedbacksPage,
        name: t('common.customerSatisfaction'),
        showNav: true,
      },
      {
        path: '/financials',
        Component: FinancialsMenuPage,
        bgImg,
        name: t('common.financials'),
        showNav: true,
        children: [
          {
            path: '/cost-recovery-report',
            Component: CostRecoveryReportPage,
            bgImg,
            name: t('common.costRecoveryReport'),
            showNav: true,
          },
          {
            path: '/cost-recovery-report/invoice/:id',
            Component: CreateAgencyInvoicePage,
            bgImg,
            name: t('crumbs.createAgencyInvoice'),
            showNav: true,
          },
          {
            path: '/create-invoice-list',
            Component: IndividualInvoiceListPage,
            bgImg,
            name: t('crumbs.bookings'),
            showNav: true,
          },
          {
            path: '/create-invoice-list/:id',
            Component: CreateIndividualInvoicePage,
            bgImg,
            name: t('invoice.createInvoice'),
            showNav: true,
          },
          {
            path: '/history-invoice-list',
            Component: InvoiceHistoryPage,
            bgImg,
            name: t('invoice.createInvoice'),
            showNav: true,
          },
          {
            path: '/service-cost-setting',
            Component: ServiceCostSettingsPage,
            bgImg,
            name: t('common.serviceCostSettings'),
            showNav: true,
          },
        ],
      },
      {
        path: '/mobility-management',
        Component: MobilityMenuPage,
        bgImg,
        name: t('common.myMobility'),
        showNav: true,
        children: [
          {
            path: '/driver-list',
            Component: DriverListPage,
            name: t('mobility.myDrivers'),
            showNav: true,
          },
          {
            path: '/logbook',
            Component: MobilityLogbookPage,
            name: t('common.mobilityLogbook'),
            showNav: true,
          },
          {
            path: '/logbook/details/:id',
            Component: MobilityLogbookDetailsPage,
            name: t('common.mobilityLogbookDetails'),
            showNav: true,
          },
          {
            path: '/vehicle-list',
            Component: VehicleListPage,
            name: t('mobility.myVehicles'),
            showNav: true,
          },
          {
            path: '/vehicles-checklist',
            Component: VehiclesChecklistPage,
            bgImage: bgChecklist,
            name: t('mobility.vehiclesChecklist'),
            showNav: true,
          },
          {
            path: '/vehicles-checklist/:id',
            Component: ChecklistDetailsPage,
            bgImage: bgChecklist,
            name: t('mobility.vehiclesChecklist'),
            showNav: true,
          },
        ],
      },
      {
        path: '/mobility-planner',
        Component: MobilityPlannerPage,
        bgImg,
        name: t('common.mobilityPlanner'),
        showNav: true,
      },
      {
        path: '/reporting-area',
        Component: ReportingAreaMenuPage,
        bgImg,
        name: t('common.reportingArea'),
        showNav: true,
        children: [
          {
            path: '/cost-recovery-report',
            Component: CostRecoveryReportPage,
            bgImg,
            name: t('common.costRecoveryReport'),
            showNav: true,
          },
          {
            path: '/cost-recovery-report/invoice/:id',
            Component: CreateAgencyInvoicePage,
            bgImg,
            name: t('crumbs.createAgencyInvoice'),
            showNav: true,
          },
          {
            path: '/country-reporting',
            Component: CountryReportPage,
            bgImg,
            name: t('crumbs.countryReport'),
            showNav: true,
          },
          {
            path: '/dashboard',
            Component: DashboardPage,
            bgImg,
            name: t('crumbs.dashboard'),
            showNav: true,
          },
        ],
      },
      {
        path: '/documents',
        Component: ResourcesPage,
        bgImg,
        name: t('common.documents'),
        showNav: true,
      },
      {
        path: '/manuals',
        Component: ResourcesPage,
        bgImg,
        name: t('common.manuals'),
        showNav: true,
      },
      {
        path: '/trainings',
        Component: ResourcesPage,
        bgImg,
        name: t('common.trainings'),
        showNav: true,
      },
    ],
  },

  {
    path: '/private-vehicle-form',
    Component: PrivateVehicleFormPage,
    bgImg,
  },

  // UNDSS Travel Request
  {
    path: '/undss-travel-request/profile',
    Component: TravelRequestProfile,
    bgImg,
  },
  {
    path: '/undss-travel-request/trip-list',
    Component: TravelRequestList,
    bgImg,
  },
  {
    path: '/undss-travel-request/trip-edit/:id',
    Component: TravelRequestTripEdit,
    bgImg,
  },
] as RouteElement[];

const buildRoutePaths = (routes: RouteElement[], parentPath = ''): any =>
  routes.map((route) => {
    const path =
      parentPath.replace(/\/$/, '') + route.path.replace(new RegExp(`^${parentPath}`), '');

    return {
      ...route,
      path,
      ...(route.children && { children: buildRoutePaths(route.children, path) }),
    };
  });

const flattenRoutes = (routes: RouteElement[]): Crumb[] =>
  buildRoutePaths(routes)
    .map((route: RouteElement) => [route.children ? flattenRoutes(route.children) : [], route])
    .flat(Infinity)
    .sort((a: Crumb, b: Crumb) => a.path.localeCompare(b.path));

export const routes: Crumb[] = flattenRoutes(routesSchema);
