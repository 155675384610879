/* istanbul ignore file */
import { BFShuttleRoute } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { addDays, endOfDay, format, startOfDay } from 'date-fns';

const addDatesToSet = (dates: string[] | null, dateSet: Set<string>) =>
  dates?.forEach((date) => {
    dateSet.add(format(new Date(date), DF.ApiDate));
  });

const processDateRange = (
  startDate: Date,
  endDate: Date,
  weekdays: number[],
  allExcludedDates: Set<string>,
  allowedDates: Set<string>,
  excludedDates: Set<string>,
  includedDates: string[],
) => {
  let currentDate = startOfDay(startDate);

  addDatesToSet(includedDates, allowedDates);

  while (currentDate <= endOfDay(endDate)) {
    const formattedDate = format(currentDate, DF.ApiDate);
    const adjustedDayOfWeek = (currentDate.getDay() || 7) - 1;

    if (excludedDates.has(formattedDate)) {
      allExcludedDates.add(formattedDate);
    } else if (weekdays.includes(adjustedDayOfWeek)) {
      allowedDates.add(formattedDate);
    } else {
      allExcludedDates.add(formattedDate);
    }

    currentDate = addDays(currentDate, 1);
  }
};

export const getExcludedDates = (shuttleRoutes: BFShuttleRoute[]): string[] => {
  const allExcludedDates: Set<string> = new Set();
  const allowedDates: Set<string> = new Set();

  shuttleRoutes.forEach(({ startDate, endDate, excludedDates, includedDates, weekdays }) => {
    const excludedDatesSet = new Set<string>();
    addDatesToSet(excludedDates, excludedDatesSet);
    processDateRange(
      new Date(startDate),
      new Date(endDate),
      weekdays,
      allExcludedDates,
      allowedDates,
      excludedDatesSet,
      includedDates,
    );
  });

  allowedDates.forEach((allowedDate) => {
    allExcludedDates.delete(allowedDate);
  });

  return Array.from(allExcludedDates);
};

export const uniqOptions = (d: string[]) => [...new Set(d)].map((i) => ({ label: i, value: i }));
