const EMAIL_REGEXP =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const commentValid = {
  name: 'length',
  message: 'Comment length should be less than 500 characters',
  test: (val: string | null | undefined) => !val || val.length <= 500,
};

export const emailValid = {
  name: 'email',
  message: 'Email should be correct',
  test: (value: unknown) => !value || EMAIL_REGEXP.test(value as string),
};

export const emailValidator = (value: unknown): void | string => {
  if (!EMAIL_REGEXP.test(value as string)) return 'Email should be correct';
};

/* eslint-disable no-useless-escape */
export const notEmptyValidator = (value: unknown): void | string => {
  if (value === undefined || value === null || value === '') {
    return 'This field is required';
  }
};

export const phoneValidator = (value: unknown): void | string => {
  const PHONE_REGEXP = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

  if (!value) {
    return 'Phone number is required';
  }

  const phoneNumber = value as string;
  if (!PHONE_REGEXP.test(phoneNumber)) {
    return 'Phone number should be correct';
  }
};

export const percentageField = (value: string): void | string => {
  const digitValue = parseFloat(value);

  if (!value && digitValue !== 0) {
    return 'Field is required';
  }

  if (digitValue > 100) {
    return 'Should less than 100';
  }

  if (digitValue < -100) {
    return 'Should more than -100';
  }
};

export const charValidator = (value: unknown, max?: number): void | string => {
  const maxLength = max || 255;
  if (value && (value as string).length > maxLength) {
    return `Should be less than ${maxLength} characters`;
  }
};

export const requiredField = (value: unknown): string | void => {
  if (!value) return 'This field is required';
};
