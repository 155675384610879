/* istanbul ignore file */
import { PropsWithChildren } from 'react';
import { AuthProvider as Auth, TAuthConfig } from '@unbooking/ui-auth';
import { SessionStorageKeys } from '@common/constants';
import { axios } from '@common/utils';

const getEnv = (key: string, fallback = '') => process.env[key] ?? fallback;

const scope = ['email', 'openid', 'profile'].join(' ');
const tokenFederatedUser = sessionStorage.getItem('X-User-Token');

export const authConfig: TAuthConfig = {
  clientId: getEnv('REACT_APP_AUTH_CLIENT_ID'),
  authorizationEndpoint: getEnv('REACT_APP_AUTH_AUTHORIZATION_ENDPOINT'),
  autoAuthorize: false,
  skipInterceptors: !!tokenFederatedUser,
  unbhPermissionsEndpoint: '',
  tokenEndpoint: getEnv('REACT_APP_AUTH_TOKEN_ENDPOINT'),
  redirectUri: getEnv('REACT_APP_AUTH_REDIRECT_URI'),
  logoutEndpoint: getEnv('REACT_APP_AUTH_LOGOUT_ENDPOINT'),
  logoutUri: getEnv('REACT_APP_AUTH_LOGOUT_URI'),
  scope,
  configurations: {
    b2c: {
      authorizationEndpoint: getEnv('REACT_APP_B2C_AUTHORIZATION_ENDPOINT'),
      tokenEndpoint: getEnv('REACT_APP_B2C_TOKEN_ENDPOINT'),
      clientId: getEnv('REACT_APP_B2C_CLIENT_ID'),
      logoutEndpoint: getEnv('REACT_APP_B2C_LOGOUT_ENDPOINT'),
      logoutUri: getEnv('REACT_APP_B2C_LOGOUT_URI'),
      scope: `${scope} offline_access`,
    },
    google: {
      authorizationEndpoint: getEnv('REACT_APP_GOOGLE_AUTHORIZATION_ENDPOINT'),
      tokenEndpoint: getEnv('REACT_APP_GOOGLE_TOKEN_ENDPOINT'),
      clientId: getEnv('REACT_APP_GOOGLE_AUTH_CLIENT_ID'),
      clientSecret: getEnv('REACT_APP_GOOGLE_AUTH_CLIENT_SECRET'),
      logoutUri: '/?is_google_user=true',
      scope,
    },
  },
};

const AuthProvider = ({ children }: PropsWithChildren) => {
  const currentPath = window.location.pathname;
  const isRedirectPage = ['/callback', '/error', '/forbidden', '/login'].includes(currentPath);

  if (!isRedirectPage) {
    sessionStorage.setItem(SessionStorageKeys.SUCCESS_REDIRECT_URI, window.location.href);
  }

  authConfig.successRedirectUri =
    sessionStorage.getItem(SessionStorageKeys.SUCCESS_REDIRECT_URI) ?? '/';

  return (
    <Auth authConfig={authConfig} axiosInstance={axios}>
      {children}
    </Auth>
  );
};

export default AuthProvider;
