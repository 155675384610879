export const formatNumber = (value: string): string =>
  !Number.isNaN(parseFloat(value)) ? value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : '';

export const parseNumber = (value: string | number) => {
  if (typeof value !== 'string') {
    return value;
  }

  if (value?.includes('.') && value[value.length - 1] === '.') {
    return value.substring(0, value.length - 1);
  }

  return value.replaceAll(',', '');
};
