import { type FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Select } from '@mantine/core';

import { DropdownOption } from '@common/interfaces';
import { useAvailableLocations } from '@common/hooks';
import { Button, Panel } from '@components';

const BOOKING_FORM_URL = process.env.REACT_APP_BOOKING_FORM_URL;

const AvailableLocationsPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading, locations } = useAvailableLocations();

  const [cities, setCities] = useState<DropdownOption[]>([]);
  const [countries, setCountries] = useState<DropdownOption[]>([]);
  const [facilities, setFacilities] = useState<DropdownOption[]>([]);

  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedFacility, setSelectedFacility] = useState<string | null>(null);

  useEffect(() => {
    const countryOptions = Array.from(new Set(locations.map((i) => i.country))).map((country) => ({
      value: country,
      label: country,
    }));

    setCountries(countryOptions);
  }, [locations]);

  const handleCountryChange = (country: string | null): void => {
    const cityOptions = Array.from(
      new Set(locations.filter((i) => i.country === country).map((i) => i.city)),
    ).map((city) => ({ value: city, label: city }));

    setCities(cityOptions);
    setSelectedCountry(country);
    setSelectedCity(null);
    setSelectedFacility(null);
  };

  const handleCityChange = (city: string | null): void => {
    const facilityOptions = Array.from(
      new Set(
        locations
          .filter((i) => i.city === city)
          .map((i) => ({ label: i.facilityName, value: String(i.id) })),
      ),
    );

    setFacilities(facilityOptions);
    setSelectedCity(city);
    setSelectedFacility(null);
  };

  const handleGoButtonClick = (): void => {
    if (selectedFacility) {
      navigate(`/${BOOKING_FORM_URL}/${selectedFacility}`, { replace: true });
      navigate(0);
    }
  };

  return (
    <Panel className="booking-location-section" isLoading={isLoading}>
      <Select
        className="field"
        data={countries}
        label={t('common.country')}
        placeholder="Select..."
        searchable
        value={selectedCountry}
        onChange={handleCountryChange}
        data-testid="bf-country-select"
      />
      <Select
        className="field"
        data={cities}
        label={t('common.city')}
        placeholder="Select country first"
        searchable
        value={selectedCity}
        onChange={handleCityChange}
        data-testid="bf-city-select"
      />
      <Select
        className="field"
        data={facilities}
        label={t('common.location')}
        placeholder="Select country first"
        value={selectedFacility}
        onChange={setSelectedFacility}
        data-testid="bf-facility-select"
      />
      <Button
        disabled={!selectedFacility}
        text="GO"
        onClick={handleGoButtonClick}
        data-testid="bf-go-button"
      />
    </Panel>
  );
};

export default AvailableLocationsPage;
