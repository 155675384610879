/* istanbul ignore file */
import { DropdownOption } from '@common/interfaces';

const DEFAULT_MAX_CAPACITY = 30;
const MIN_CAPACITY = 1;

export const createPaxOptions = (paxNumber?: number): DropdownOption[] => {
  const options = [];
  const maxCapacity = paxNumber || DEFAULT_MAX_CAPACITY;

  // eslint-disable-next-line no-plusplus
  for (let count = MIN_CAPACITY; count <= maxCapacity; count++) {
    options.push({ label: count.toString(), value: count.toString() });
  }

  return options;
};

export const getFieldValue = (field: DropdownOption | string): string =>
  typeof field === 'object' && field !== null ? field?.value : field;
