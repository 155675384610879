import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { Pagination, Table } from '@unbooking/ui-modules';

import { CURRENT_MONTH, CURRENT_YEAR, MONTHS, TABLE_ITEMS, YEARS } from '@common/constants';
import { CRMonthlySummary, InvAgencyCreate, SummaryList, ReportParams } from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { Serializer } from '@common/utils';
import { useRepository } from '@context';
import { Field, Form } from '@components';
import { getSummaryColumns } from '../utils';
import MonthlySummary from './MonthlySummary';
import { SummarySchema } from '../schemas';

interface FormState {
  month: string;
  preparedBy: string;
  year: string;
}

interface SummaryProps {
  username: string;
  exportDrives: (data: ReportParams) => void;
}

const Summary = ({ username, exportDrives }: SummaryProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { financialRepo, reportRepo } = useRepository();
  const { facility, facilityId: fid } = useFacility();
  const { agencyId } = facility;

  const [monthlySummary, setMonthlySummary] = useState<CRMonthlySummary | null>(null);
  const [summaryData, setSummaryData] = useState<SummaryList[]>([]);

  const [columnOrder, setColumnOrder] = useState<string>('');
  const [filters, setFilters] = useState({ month: CURRENT_MONTH.value, year: CURRENT_YEAR.value });
  const [pagination, setPagination] = useState({ pageSize: 10, selectedPage: 1, total: 0 });

  const { data, isLoading: isReportDataLoading } = useQuery(
    [
      'get-summary-list',
      { ...filters, columnOrder, page: pagination.selectedPage, pageSize: pagination.pageSize },
    ],
    () =>
      reportRepo.getSummary(fid, {
        agencyId,
        limit: pagination.pageSize,
        month: filters.month,
        offset: (pagination.selectedPage - 1) * 10,
        ordering: columnOrder,
        year: filters.year,
      }),
    { enabled: !!agencyId },
  );

  const { data: monthlySummaryData, isLoading: isMonthlySummaryLoading } = useQuery(
    ['get-monthly-summary', { ...filters }],
    () => reportRepo.getMonthlySummary(fid, { agencyId, month: filters.month, year: filters.year }),
    { enabled: !!agencyId },
  );

  const { mutateAsync: createInvoice } = useMutation(
    'create-agency-invoice',
    (payload: InvAgencyCreate) => financialRepo.createAgencyInvoice(fid, payload),
    {
      onSuccess: ({ id }: { id: string }) => {
        if (id) navigate(`invoice/${id}`);
      },
      onError: (error: AxiosError) => {
        if (error.message) toast.error(t('common.errorMsgDefault'));
      },
    },
  );

  useEffect(() => {
    if (data) {
      setSummaryData(data.map(Serializer.formatSummary));
      setPagination((s) => ({ ...s, total: data.count }));
    }

    if (monthlySummaryData) {
      setMonthlySummary(Serializer.formatMonthlySummary(monthlySummaryData));
    } else {
      setMonthlySummary(null);
    }
  }, [data, monthlySummaryData]);

  const handleFilterChange = (field: keyof typeof filters, value: string) => {
    setFilters((s) => ({ ...s, [field]: value }));
    setPagination((s) => ({ ...s, selectedPage: 1 }));
  };
  const handlePageChange = (page: number) => setPagination((s) => ({ ...s, selectedPage: page }));
  const handlePageSizeChange = (size: number) => setPagination((s) => ({ ...s, pageSize: size }));
  const handleSubmit = useCallback(
    async ({ month, preparedBy, year }: FormState) =>
      exportDrives({ agencyId, month, preparedBy, year }),
    [agencyId, exportDrives],
  );

  const initFormData: FormState = useMemo(
    () => ({ month: CURRENT_MONTH.value, preparedBy: username, year: CURRENT_YEAR.value }),
    [username],
  );
  const schema = useMemo(() => SummarySchema(), []);

  return (
    <section className="summary">
      <section className="summary-list">
        <div className="row">
          <Form
            className="summary-list-form"
            defaultValues={initFormData}
            id="report_filters_form"
            schema={schema}
            onSubmit={handleSubmit}
          >
            <div className="export-fields">
              <Field
                name="preparedBy"
                disabled
                label={t('report.inputPreparedBy')}
                value={username}
              />
            </div>

            <div className="filters">
              <Field
                name="month"
                input="dropdown"
                className="month"
                label={t('common.month')}
                options={MONTHS}
                onChange={(value: string) => handleFilterChange('month', value)}
              />

              <Field
                name="year"
                input="dropdown"
                className="year"
                label={t('common.year')}
                options={YEARS}
                onChange={(value: string) => handleFilterChange('year', value)}
              />
            </div>
          </Form>
        </div>

        <div className="summary-list-table">
          <Table
            columns={getSummaryColumns({
              agencyId,
              month: filters.month,
              year: filters.year,
              createInvoice,
              t,
            })}
            data={summaryData}
            isLoading={isReportDataLoading}
            rowKey="agencyId"
            variant="light"
            onChangeColumnOrder={setColumnOrder}
          />
        </div>

        {pagination.total > TABLE_ITEMS ? (
          <Pagination
            className="pagination"
            selectedPage={pagination.selectedPage}
            showJumper
            showPageSize
            totalPages={pagination.total}
            variant="light"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        ) : null}
      </section>

      <section className="summary-monthly">
        <MonthlySummary data={monthlySummary} loading={isMonthlySummaryLoading} />
      </section>
    </section>
  );
};

export default Summary;
