import type { Dispatch, FC, SetStateAction } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { startOfYear, subYears } from 'date-fns';

import type { DropdownOption, FilterReport } from '@common/interfaces';
import { Dropdown } from '@unbooking/ui-modules';
import { Button, DatePicker } from '@components';
import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import './Filters.styles.scss';

interface FiltersApi {
  agencies: DropdownOption[];
  facilities: DropdownOption[];
}

interface FiltersProps {
  selectedFilters: FilterReport[];
  toolbar?: JSX.Element;
  setFilters: Dispatch<SetStateAction<FilterReport[]>>;
}

const Filters: FC<FiltersProps> = ({ selectedFilters, toolbar, setFilters }) => {
  const { reportRepo } = useRepository();
  const { facility, facilityId } = useFacility();

  const { data: filtersData, isLoading: isFiltersLoading } = useQuery<unknown, void, FiltersApi>(
    'get-dashboard-filters',
    () => reportRepo.getDashboardFilters(facilityId, { agencyId: facility?.agencyId }),
    { enabled: !!facility?.agencyId },
  );

  const handleFilterChange = (option: unknown, field: string) => {
    setFilters((prevFilters: FilterReport[]) => {
      const filterIdx = prevFilters.findIndex((i) => Object.keys(i)[0] === field);

      const newFilter = Array.isArray(option) ? { customPeriod: option } : { [field]: option };

      let updatedFilters =
        filterIdx !== -1
          ? [...prevFilters.slice(0, filterIdx), newFilter, ...prevFilters.slice(filterIdx + 1)]
          : [...prevFilters, newFilter];

      if (field === 'period' && (option as { value: string }).value !== 'custom') {
        updatedFilters = updatedFilters.filter((filter) => !filter.customPeriod);
      }

      return updatedFilters as FilterReport[];
    });
  };

  const handleFilterClear = () => {
    setFilters([]);
  };

  const periodOpt = useMemo(
    () => [
      { label: 'All period', value: 'all' },
      { label: 'Last 7 Days', value: 'week' },
      { label: 'This Month', value: 'month' },
      { label: 'Previous Month', value: 'monthPrev' },
      { label: 'This Year', value: 'year' },
      { label: 'Previous Year', value: 'yearPrev' },
      { label: 'Custom', value: 'custom' },
    ],
    [],
  );

  const agencyOpt = useMemo(
    () => [
      { label: 'All agencies', value: '' },
      ...(filtersData?.agencies ? filtersData.agencies : []),
    ],
    [filtersData],
  );

  const locationOpt = useMemo(
    () => [
      { label: 'All locations', value: '' },
      ...(filtersData?.facilities ? filtersData.facilities : []),
    ],
    [filtersData],
  );

  const isPeriodCustom = useMemo(
    () => selectedFilters.find((f) => f.period)?.period?.value === 'custom',
    [selectedFilters],
  );

  return (
    <section className="dashboard-filters">
      <Dropdown
        className="dropdown outline"
        isDisabled={isFiltersLoading}
        options={periodOpt}
        placeholder="Time period"
        value={selectedFilters.find((filter) => filter.period)?.period || null}
        onChange={(v) => handleFilterChange(v, 'period')}
      />
      {isPeriodCustom && (
        <DatePicker
          format="DD MMM YYYY"
          minDate={startOfYear(subYears(new Date(), 1))}
          placeholder="Choose dates range"
          type="range"
          variant="outline"
          value={
            selectedFilters.find((filter) => filter.customPeriod)?.customPeriod || [null, null]
          }
          onChange={(v) => handleFilterChange(v, 'customPeriod')}
        />
      )}
      <Dropdown
        className="dropdown outline"
        containerClassName="field-pax"
        isDisabled={isFiltersLoading}
        options={agencyOpt}
        placeholder="Passenger Agency"
        value={selectedFilters.find((filter) => filter.agency)?.agency || null}
        onChange={(v) => handleFilterChange(v, 'agency')}
      />
      <Dropdown
        className="dropdown outline"
        isDisabled={isFiltersLoading}
        options={locationOpt}
        placeholder="Location: All"
        value={selectedFilters.find((filter) => filter.location)?.location || null}
        onChange={(v) => handleFilterChange(v, 'location')}
      />

      <Button
        className="btn btn-clear"
        text="Clear filters"
        type="button"
        variant="primary"
        onClick={handleFilterClear}
      />

      <div className="toolbar">{toolbar}</div>
    </section>
  );
};

export default Filters;
