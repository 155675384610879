import { type FC, useCallback, useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { t } from 'i18next';
import { format, subYears } from 'date-fns';
import { SegmentedControl } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import cn from 'classnames';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import type {
  Dashboard,
  DashboardDataApi,
  DashboardDriveApi,
  FilterReport,
} from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import { Button, Loader, Tabs, Tooltip } from '@components';
import { Info, PDF } from '@assets/svg/icons';

import { COLORS } from './charts/consts';
import { BarChart, PieChart } from './charts';
import { FiguresSection, Filters } from './components';
import { UtilType, dataAdapter, filterAdapter, prepareDriveData } from './utils';
import './DashboardPage.styles.scss';

const TOP_N = 30;
const TOOLTIP_TEXT = 'Booking requests including all status in this period';

const DashboardPage: FC = () => {
  const { reportRepo } = useRepository();
  const { facility, facilityId } = useFacility();
  const { agencyId } = facility;

  const tabs = useMemo(() => ['Mobility KPI', 'Vehicles / Drivers Utilisation'], []);

  const [data, setData] = useState<Dashboard>();
  const [filters, setFilters] = useState<FilterReport[]>([]);
  const [isFileLoading, setFileLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [utilType, setUtilType] = useToggle([UtilType.trips, UtilType.mileage]);

  const {
    isFetching: isDataFetching,
    isLoading: isDataLoading,
    refetch: getDashboardData,
  } = useQuery<DashboardDataApi>(
    ['get-dashboard-data'],
    () => reportRepo.getDashboardData(facilityId, { agencyId, ...filterAdapter(filters) }),
    {
      enabled: !!agencyId && !filters.length,
      onSuccess: (res) => {
        if (res) {
          setData((prevData) => ({
            ...prevData,
            ...dataAdapter<Dashboard>(res, ['requestingUnit'], { state: 7 }),
          }));
        }
      },
    },
  );

  const {
    isFetching: isDriversFetching,
    isLoading: isDriversLoading,
    refetch: getDashboardDrivers,
  } = useQuery<DashboardDriveApi[]>(
    'get-dashboard-drivers',
    () => reportRepo.getDashboardDrivers(facilityId, { agencyId, ...filterAdapter(filters) }),
    {
      enabled: !!agencyId && !filters.length && !isDataLoading,
      onSuccess: (res) => {
        if (res) {
          const { drives, mileage } = prepareDriveData(res);

          setData((prevData) => ({
            ...prevData,
            ...dataAdapter<Dashboard>(
              { driverDrives: drives.slice(0, TOP_N), driverMileage: mileage.slice(0, TOP_N) },
              ['driverDrives', 'driverMileage'],
              { driverDrives: 0, driverMileage: 0 },
            ),
          }));
        }
      },
    },
  );

  const {
    isFetching: isVehiclesFetching,
    isLoading: isVehiclesLoading,
    refetch: getDashboardVehicles,
  } = useQuery<DashboardDriveApi[]>(
    'get-dashboard-vehicles',
    () => reportRepo.getDashboardVehicles(facilityId, { agencyId, ...filterAdapter(filters) }),
    {
      enabled: !!agencyId && !filters.length && !isDataLoading,
      onSuccess: (res) => {
        if (res) {
          const { drives, mileage } = prepareDriveData(res);

          setData((prevData) => ({
            ...prevData,
            ...dataAdapter<Dashboard>(
              { vehicleDrives: drives.slice(0, TOP_N), vehicleMileage: mileage.slice(0, TOP_N) },
              ['vehicleDrives', 'vehicleMileage'],
              { vehicleDrives: 0, vehicleMileage: 0 },
            ),
          }));
        }
      },
    },
  );

  useEffect(() => {
    const isPeriodCustom = filters.some((f) => f.period && f.period.value === 'custom');
    const isPeriodSelected = isPeriodCustom && filters.some((f) => f.customPeriod);
    const isDateRangeFilled = (arr: Date[]) =>
      Array.isArray(arr) && arr.length === 2 && arr.every((value) => value !== null);

    if (
      filters.length &&
      (!isPeriodCustom ||
        (isPeriodSelected &&
          isDateRangeFilled((filters.find((f) => f.customPeriod) as FilterReport).customPeriod)))
    ) {
      getDashboardData();
      getDashboardDrivers();
      getDashboardVehicles();
    }
  }, [agencyId, filters, getDashboardData, getDashboardDrivers, getDashboardVehicles]);

  const handleExport = useCallback(async (): Promise<void> => {
    const elem = document.querySelector('.dashboard') as HTMLElement;

    if (elem) {
      setFileLoading(true);
      await html2canvas(elem, { logging: true, allowTaint: false, useCORS: true })
        .then((canvas) => {
          // eslint-disable-next-line new-cap
          const pdf = new jsPDF('p', 'mm', 'a4');

          const imgData = canvas.toDataURL('image/png');
          const imgWidth = 206;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
          pdf.save('dashboard.pdf');
        })
        .finally(() => {
          setFileLoading(false);
        });
    }
  }, []);

  const hasPeriod = filters.some((f) => f.period);
  const isLoading =
    isDataFetching ||
    isDataLoading ||
    isDriversFetching ||
    isDriversLoading ||
    isVehiclesFetching ||
    isVehiclesLoading;

  const drivers = utilType === UtilType.trips ? data?.driverDrives : data?.driverMileage;
  const vehicles = utilType === UtilType.trips ? data?.vehicleDrives : data?.vehicleMileage;
  const driversTitle =
    Number(drivers?.data?.[0]?.data?.length!) - 1 >= TOP_N
      ? `Top ${TOP_N} Drivers ${utilType}`
      : `Drivers ${utilType}`;
  const vehiclesTitle =
    Number(vehicles?.data?.[0]?.data?.length!) - 1 >= TOP_N
      ? `Top ${TOP_N} Vehicle ${utilType}`
      : `Vehicle ${utilType}`;

  return (
    <section className="hbh-container">
      <Loader fullScreen spinning={isLoading} />

      <Tabs
        className="tabs"
        selectedTab={tabs.indexOf(selectedTab)}
        tabs={tabs}
        variant="outline"
        onTabSelect={setSelectedTab}
      />

      <div
        className={cn('dashboard', {
          'dashboard-utilisation': tabs.indexOf(selectedTab) === 1,
        })}
      >
        <h1>
          Analytics Dashboard{' '}
          {!hasPeriod && (
            <sup className="dashboard-period">
              * Data provided since 1 January {format(subYears(new Date(), 1), 'yyyy')}
            </sup>
          )}
        </h1>

        <Filters
          selectedFilters={filters}
          setFilters={setFilters}
          toolbar={
            <Button
              className="btn btn-export"
              disabled={isFileLoading}
              leftIcon={<PDF />}
              text={`${t('common.btnExport')} PDF`}
              variant="submit"
              onClick={handleExport}
            />
          }
        />

        <h2>Overview for the selected Period</h2>
        {selectedTab === tabs[0] ? (
          <>
            <FiguresSection data={data?.total} />

            <section className="charts">
              <figure>
                <PieChart
                  title={
                    <Tooltip label={TOOLTIP_TEXT}>
                      Booking Request status <Info />
                    </Tooltip>
                  }
                  dataset={data?.state}
                  colors={COLORS}
                  emptyText={t('report.noData')}
                  height={320}
                  totalText="Requests"
                />
              </figure>

              <figure>
                <PieChart
                  title={
                    <Tooltip label={TOOLTIP_TEXT}>
                      Transfer Type of Booking Requests <Info />
                    </Tooltip>
                  }
                  dataset={data?.transferType}
                  colors={COLORS}
                  emptyText={t('report.noData')}
                  height={320}
                  totalText="Requests"
                />
              </figure>

              <figure>
                <PieChart
                  title={
                    <Tooltip label={TOOLTIP_TEXT}>
                      Trip Type of Booking Requests <Info />
                    </Tooltip>
                  }
                  dataset={data?.typeOfTrip}
                  colors={COLORS}
                  emptyText={t('report.noData')}
                  height={320}
                  totalText="Requests"
                />
              </figure>

              <figure>
                <PieChart
                  title={
                    <Tooltip label={TOOLTIP_TEXT}>
                      Bookings Requests by Passengers’ agency <Info />
                    </Tooltip>
                  }
                  dataset={data?.agencyShortName}
                  colors={COLORS}
                  emptyText={t('report.noData')}
                  height={320}
                  totalText="Requests"
                />
              </figure>

              <figure>
                <PieChart
                  title={
                    <Tooltip label={TOOLTIP_TEXT}>
                      Carpooled/Rideshared trips <Info />
                    </Tooltip>
                  }
                  dataset={data?.driveType}
                  colors={COLORS}
                  emptyText={t('report.noData')}
                  height={320}
                  totalText="Trips"
                />
              </figure>

              <figure>
                <PieChart
                  title={
                    <Tooltip label={TOOLTIP_TEXT}>
                      Number of Recurring by Types <Info />
                    </Tooltip>
                  }
                  dataset={data?.recurringTypeOfTrip}
                  colors={COLORS}
                  emptyText={t('report.noData')}
                  height={320}
                  totalText="Requests"
                />
              </figure>

              <figure>
                <BarChart
                  title="Number of trips by Requesting units"
                  agenda={data?.requestingUnit?.agenda!}
                  axis="y"
                  dataset={data?.requestingUnit?.data}
                  colors={COLORS}
                  emptyText={t('report.noData')}
                  height={320}
                  hideLegend
                />
              </figure>
            </section>
          </>
        ) : null}

        {selectedTab === tabs[1] ? (
          <>
            <div className="select-util-type">
              <SegmentedControl
                color="lime"
                size="xs"
                value={utilType}
                onChange={() => setUtilType()}
                data={[
                  { label: UtilType.trips, value: UtilType.trips },
                  { label: UtilType.mileage, value: UtilType.mileage },
                ]}
              />
            </div>
            <section className="charts">
              <figure>
                <BarChart
                  title={driversTitle}
                  agenda={drivers?.agenda!}
                  axis="y"
                  dataset={drivers?.data}
                  colors={COLORS}
                  emptyText={t('report.noData')}
                  hideLegend
                />
              </figure>

              <figure>
                <BarChart
                  title={vehiclesTitle}
                  agenda={vehicles?.agenda!}
                  axis="y"
                  dataset={vehicles?.data}
                  colors={COLORS}
                  emptyText={t('report.noData')}
                  hideLegend
                />
              </figure>
            </section>
          </>
        ) : null}
      </div>
    </section>
  );
};

export default DashboardPage;
