import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { CheckMark, GPSConnect, VehiclePlaceholder } from '@assets/svg/icons';
import { useRepository } from '@context';
import { useDrivers, useFacility } from '@common/hooks';
import { createPaxOptions, Serializer } from '@common/utils';
import {
  ApiList,
  DropdownOption,
  LocationApi,
  OwnerType,
  ProtType,
  VehicleCreate,
  VehicleDetails,
  VehicleDetailsApi,
  VehicleModelListApi,
  VehicleUpdate,
} from '@common/interfaces';
import { Button, Field, Form, Loader } from '@components';
import { VehicleFormSchema } from '@schemas';
import './VehicleEdit.styles.scss';

export interface FormState {
  agency: string;
  chassisNumber: string;
  city: string;
  country: string;
  gvlpNumber: string;
  maxCapacity: string;
  plateNumber: string;
  preferredDriver: string | null;
  protection: string;
  roofNumber: string;
  sharedLocations: string[];
  vehicleModel: string;
  vehicleOwner: string;
}

export interface VehicleEditProps {
  isLoading: boolean;
  readOnly?: boolean;
  vehicleId: string | null;
  createVehicle: (data: VehicleCreate) => void;
  updateVehicle: ({ vehicleId, data }: { vehicleId: string; data: VehicleUpdate }) => void;
}

const VehicleEdit = ({
  isLoading,
  readOnly = false,
  vehicleId,
  createVehicle,
  updateVehicle,
}: VehicleEditProps) => {
  const { t } = useTranslation();
  const { mobilityRepository } = useRepository();
  const { driverOptions } = useDrivers();
  const { agencyName, facility, facilityId: fid } = useFacility();
  const { agencyId, city, country } = facility;
  const isEdit = !!vehicleId;

  const [details, setDetails] = useState<VehicleDetails | null>(null);
  const [gpsError, setGpsError] = useState<string | null>(null);
  const [isGPSConnected, setIsGPSConnected] = useState<boolean>(false);
  const [sharedAvailableLocations, setSharedAvailableLocations] = useState<DropdownOption[]>();
  const [vehicleModelOptions, setVehicleModelOptions] = useState<DropdownOption[]>();

  useQuery('get-shared-locations', () => mobilityRepository.getSharedLocations(fid, { agencyId }), {
    enabled: !isEdit,
    onSuccess: (data: LocationApi[]) => {
      setSharedAvailableLocations(data?.map(Serializer.formatSharedLocations));
    },
  });

  const { isFetching: isVehicleDetailsLoading } = useQuery(
    'get-vehicle-details',
    () => mobilityRepository.getVehicleDetails(fid, vehicleId!, { agencyId }),
    {
      enabled: isEdit,
      onSuccess: (data: VehicleDetailsApi) => {
        setDetails(Serializer.formatVehicleDetails(data));
        setIsGPSConnected(data.connected_to_gps);
      },
      onError: (error: AxiosError) => {
        if (error.message) toast.error(error.message || t('common.errorMsgDefault'));
      },
    },
  );

  useQuery('get-vehicle-models', () => mobilityRepository.getVehicleModels(fid, { limit: 250 }), {
    enabled: !isEdit,
    onSuccess: (data: ApiList<VehicleModelListApi>) => {
      setVehicleModelOptions(data.results?.map(Serializer.formatVehicleModels));
    },
  });

  const { mutate: onGPSConnection, isLoading: isGPSLoading } = useMutation<unknown, AxiosError>(
    'connect-gps',
    () => mobilityRepository.vehicleConnectGPS(fid, details?.id!, details?.connectedToGps!),
    {
      onSuccess: () => {
        setIsGPSConnected(!isGPSConnected);
        setGpsError(null);
      },
      onError: (error) => {
        if (error) setGpsError(t('common.errorMsgGps'));
      },
    },
  );

  const handleSubmit = useCallback(
    async ({
      chassisNumber,
      gvlpNumber,
      maxCapacity,
      plateNumber,
      preferredDriver,
      protection,
      roofNumber,
      sharedLocations,
      vehicleModel,
      vehicleOwner,
    }: FormState) => {
      const data = {
        chassis_number: chassisNumber || '',
        gvlp_number: gvlpNumber || null,
        max_capacity: Number(maxCapacity),
        model: vehicleModel,
        plate_number: plateNumber,
        preferred_driver: preferredDriver || null,
        protection: protection as ProtType,
        roof_number: roofNumber || null,
        shared_locations: (sharedLocations || [])?.map(Serializer.mapSharedLocationToUpdate),
        ...(vehicleOwner ? { vehicle_owner: vehicleOwner as OwnerType } : {}),
      };

      if (isEdit) {
        updateVehicle({ vehicleId: details?.id!, data });
      } else {
        createVehicle({ agency: agencyId, model_fk: vehicleModel, ...data });
      }
    },
    [agencyId, createVehicle, details?.id, isEdit, updateVehicle],
  );

  const paxOptions: DropdownOption[] = createPaxOptions();
  const protectionOptions: DropdownOption[] = useMemo(
    () => [
      { value: ProtType.Soft, label: t('common.soft') },
      { value: ProtType.Armoured, label: t('common.armoured') },
    ],
    [t],
  );
  const ownerOptions: DropdownOption[] = useMemo(
    () => [
      { value: OwnerType.Owned, label: t('mobility.owned') },
      { value: OwnerType.Rented, label: t('mobility.rented') },
      { value: OwnerType.External, label: t('mobility.externalProvider') },
    ],
    [t],
  );

  const {
    agency,
    chassisNumber,
    gvlpNumber,
    label,
    locations,
    maxCapacity,
    model,
    modelChoices,
    plateNumber,
    preferredDriver,
    protection,
    roofNumber,
    vehicleOwner,
    sharedLocationOptions,
    sharedLocations,
  } = details || {};

  const initFormData = isLoading
    ? {}
    : {
        agency: isEdit ? agency?.shortName ?? '' : '',
        chassisNumber: isEdit ? chassisNumber : '',
        gvlpNumber: isEdit ? gvlpNumber : '',
        maxCapacity: isEdit ? paxOptions?.find((i) => +i.value === maxCapacity)?.value : undefined,
        plateNumber: isEdit ? plateNumber : '',
        preferredDriver: isEdit ? preferredDriver?.value : '',
        protection: isEdit
          ? protectionOptions?.find((i) => i.value === protection)?.value
          : undefined,
        roofNumber: isEdit ? roofNumber : '',
        sharedLocations: isEdit ? sharedLocations : [],
        vehicleModel: isEdit ? modelChoices?.find((i) => i.value === model?.id)?.value : undefined,
        vehicleOwner: isEdit
          ? ownerOptions.find((i) => i.value === vehicleOwner)?.value
          : undefined,
      };

  const schema = useMemo(() => VehicleFormSchema(), []);

  const ownedAgency = locations?.length ? locations[0].agency : agencyName;
  const ownedCity = locations?.length ? locations[0].city : city;
  const ownedCountry = locations?.length ? locations[0].country : country;

  return (
    <Loader spinning={isVehicleDetailsLoading || isLoading}>
      <Form
        className="form-vehicle-edit"
        defaultValues={initFormData}
        schema={schema}
        onSubmit={handleSubmit}
      >
        {({ setValue, watch }) => (
          <>
            <h2>{isEdit ? t('mobility.editVehicle') : t('mobility.addVehicle')}</h2>

            {isEdit && (
              <div className="row vehicle">
                <div className="vehicle-photo">
                  {model?.image ? (
                    <img src={model.image} alt={model.name} />
                  ) : (
                    <div className="vehicle-photo-placeholder">
                      <VehiclePlaceholder />
                    </div>
                  )}
                </div>

                <div className="vehicle-info">
                  <div className="name">{model ? model.name : label}</div>
                  {!readOnly ? (
                    <div className="gps-provider">
                      {isGPSConnected ? (
                        <>
                          <Button
                            className="btn-connected"
                            icon={<CheckMark />}
                            text={t('mobility.btnConnected')}
                            variant="submit"
                          />
                          <Button
                            className="btn-disconnect"
                            text={t('mobility.btnDisconnect')}
                            variant="link"
                            onClick={onGPSConnection}
                          />
                        </>
                      ) : (
                        <>
                          {isGPSLoading ? (
                            <Button variant="secondary" text="Connecting…" />
                          ) : (
                            <Button
                              icon={<GPSConnect />}
                              text={t('mobility.btnConnectToGPS')}
                              variant="primary"
                              onClick={onGPSConnection}
                            />
                          )}
                          <div className="gps-error">{gpsError}</div>
                        </>
                      )}
                    </div>
                  ) : null}
                </div>

                <Field
                  name="preferredDriver"
                  input="dropdown"
                  className="field-preferred"
                  clearable
                  disabled={readOnly}
                  label={t('mobility.preferredDriver')}
                  options={driverOptions}
                />
              </div>
            )}

            <h3>{t('mobility.carInfo')}</h3>
            <div className="row">
              <Field
                name="vehicleModel"
                input="dropdown"
                className="field-vehicle-model"
                clearable
                disabled={isEdit}
                label={t('mobility.vehicleModel')}
                required
                options={isEdit ? modelChoices! : vehicleModelOptions!}
              />
              <Field name="chassisNumber" disabled={readOnly} label={t('mobility.chassisNumber')} />
              {isEdit && <Field name="agency" disabled label={t('common.agency')} />}
              <Field name="plateNumber" disabled={readOnly} label={t('mobility.plateNumber')} />
            </div>

            <div className="row">
              <Field
                name="maxCapacity"
                input="dropdown"
                className="field-pax"
                disabled={readOnly}
                label="Pax N°"
                options={paxOptions}
              />

              <Field
                name="protection"
                input="dropdown"
                className="field-protection"
                disabled={readOnly}
                label={t('mobility.skinType')}
                options={protectionOptions}
                onChange={(value: string) => {
                  if (value === ProtType.Armoured) setValue('sharedLocations', []);
                }}
              />

              {isEdit && (
                <Field
                  name="vehicleOwner"
                  input="dropdown"
                  className="field-owner"
                  disabled={readOnly}
                  label={t('mobility.vehicleOwner')}
                  options={ownerOptions}
                />
              )}

              <Field name="roofNumber" disabled={readOnly} label={t('mobility.roofNumber')} />
              <Field name="gvlpNumber" disabled={readOnly} label="GVLP Number" />
            </div>

            <h3>{t('mobility.shared')}</h3>
            <div className="row">
              <Field
                name="sharedLocations"
                input="multi-select"
                className="field-shared"
                clearable
                description={
                  watch('protection') === ProtType.Armoured && 'Armoured vehicle can not be shared'
                }
                disabled={watch('protection') === ProtType.Armoured || readOnly}
                options={isEdit ? sharedLocationOptions : sharedAvailableLocations}
              />
            </div>

            <div className="footer">
              <div className="info">
                {t('mobility.ownedBy')}: {ownedCity}, {ownedCountry} - {ownedAgency}
              </div>

              {!readOnly ? (
                <Button
                  className="btn btn-submit"
                  disabled={isLoading}
                  text={t('common.btnConfirmChanges')}
                  type="submit"
                  variant="submit"
                />
              ) : null}
            </div>
          </>
        )}
      </Form>
    </Loader>
  );
};

export default VehicleEdit;
