import { useTranslation } from 'react-i18next';
import { DropdownOption, IUserInfo } from '@common/interfaces';
import { FileUpload, InputText, Select } from '@components';

type SectionProps = {
  agencies: DropdownOption[];
  ctrl: any;
  isBFC: boolean;
  pax: DropdownOption[];
  requestUnits: DropdownOption[];
  userInfo: IUserInfo;
};

const MAX_FILE_NUMBER = 5;
const MAX_FILE_SIZE = 10485760; // 10mb

const MainSection = ({ agencies, ctrl, isBFC, pax, requestUnits, userInfo }: SectionProps) => {
  const { t } = useTranslation();

  const commonFields = (
    <>
      <InputText
        name={isBFC ? 'travellerDetails.firstName' : 'bookingDetails.firstName'}
        className="field"
        control={ctrl}
        defaultValue={isBFC ? '' : undefined}
        disabled={!isBFC}
        label={isBFC ? `${t('bookingForm.traveller')} ${t('common.name')}` : t('common.name')}
        required
        data-testid="bf-first-name"
      />
      <InputText
        name={isBFC ? 'travellerDetails.lastName' : 'bookingDetails.lastName'}
        className="field"
        control={ctrl}
        defaultValue={isBFC ? '' : undefined}
        disabled={!isBFC}
        label={isBFC ? `${t('bookingForm.traveller')} ${t('common.surname')}` : t('common.surname')}
        required
        data-testid="bf-last-name"
      />
      <InputText
        name={isBFC ? 'travellerDetails.email' : 'bookingDetails.email'}
        className="field"
        control={ctrl}
        defaultValue={isBFC ? '' : undefined}
        disabled={!isBFC}
        label={isBFC ? `${t('bookingForm.traveller')} ${t('common.email')}` : t('common.email')}
        required
        data-testid="bf-email"
      />
      <InputText
        name={isBFC ? 'travellerDetails.phoneNumber' : 'bookingDetails.phoneNumber'}
        className="field"
        control={ctrl}
        defaultValue={isBFC ? '' : undefined}
        label={
          isBFC
            ? `${t('bookingForm.traveller')} ${t('common.phoneNumber')}`
            : t('common.phoneNumber')
        }
        required
        data-testid="bf-phone-number"
      />
      <Select
        name={isBFC ? 'travellerDetails.agency' : 'bookingDetails.agency'}
        className="field"
        control={ctrl}
        defaultValue={isBFC ? '' : undefined}
        label={isBFC ? `${t('bookingForm.traveller')} ${t('common.agency')}` : t('common.agency')}
        options={agencies}
        required
        data-testid="bf-agency"
      />
      <InputText
        name={isBFC ? 'travellerDetails.indexNumber' : 'bookingDetails.indexNumber'}
        className="field"
        control={ctrl}
        defaultValue={isBFC ? '' : undefined}
        label={
          isBFC
            ? `${t('bookingForm.traveller')} ${t('common.indexNumber')} (${t('common.optional')})`
            : `${t('common.indexNumber')} (${t('common.optional')})`
        }
        data-testid="bf-index-number"
      />
      <Select
        name={isBFC ? 'travellerDetails.requestingUnit' : 'bookingExtra.requestingUnit'}
        className="field"
        control={ctrl}
        defaultValue={isBFC ? '' : undefined}
        label={t('common.requestingUnit')}
        options={requestUnits}
        required
        data-testid="bf-requesting-unit"
      />
      <InputText
        name={isBFC ? 'bookingExtra.travellerManagerEmail' : 'bookingExtra.managerEmail'}
        className="field"
        control={ctrl}
        label={`${t('bookingForm.managerEmail')} (${t('common.optional')})`}
        data-testid="bf-manager-email"
      />
      <Select
        name="bookingExtra.pax"
        className="field"
        control={ctrl}
        label={t('bookingForm.pax')}
        options={pax}
        required
        data-testid="bf-pax"
      />
      <InputText
        name="bookingExtra.budgetCode"
        className="field"
        control={ctrl}
        label={`${t('common.budgetCode')} (${t('common.optional')})`}
        data-testid="bf-budget-code"
      />
      <FileUpload
        name="bookingDetails.attachments"
        accept=".jpg,.jpeg,.png,.pdf,.zip"
        className="field"
        control={ctrl}
        label={t('bookingForm.attachments')}
        multiple
        maxFileNumber={MAX_FILE_NUMBER}
        maxFileSize={MAX_FILE_SIZE}
        data-testid="bf-attachments"
      />
    </>
  );

  return (
    <fieldset>
      <legend>
        {isBFC ? t('bookingForm.bookerDetails') : t('bookingForm.bookingPassengerDetails')}
      </legend>

      {isBFC ? (
        <section>
          <InputText
            name="agentDetails.firstName"
            className="field"
            control={ctrl}
            defaultValue={userInfo?.firstName}
            disabled
            label={`${t('bookingForm.your')} ${t('common.name')}`}
            required
            data-testid="bf-agent-first-name"
          />
          <InputText
            name="agentDetails.lastName"
            className="field"
            control={ctrl}
            defaultValue={userInfo?.lastName}
            disabled
            label={`${t('bookingForm.your')} ${t('common.surname')}`}
            required
            data-testid="bf-agent-last-name"
          />
          <InputText
            name="agentDetails.email"
            className="field"
            control={ctrl}
            defaultValue={userInfo?.email}
            disabled
            label={`${t('bookingForm.your')} ${t('common.email')}`}
            required
            data-testid="bf-agent-email"
          />

          <h2>{t('bookingForm.travellerDetails')}</h2>
          {commonFields}
        </section>
      ) : (
        commonFields
      )}
    </fieldset>
  );
};

export default MainSection;
