/* istanbul ignore file */
/* eslint-disable no-nested-ternary */
import { addMinutes, parse } from 'date-fns';
import { TIME_INTERVAL } from '@common/constants';
import { Drive, DropdownOption, PlDriveFormData } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { getSoonTime } from '@common/utils';
import { findOption } from './options';

const parseLocation = (
  location: DropdownOption | string | undefined,
): { address: string; location: DropdownOption | string } => {
  if (!location) return { address: '', location: '' };
  if (typeof location === 'object' && 'value' in location) return { address: '', location };
  if (!location.includes('|')) return { address: '', location: location.trim() };

  const [address, loc] = location.split('|').map((str) => str.trim());
  return { address, location: loc };
};

export const getInitDriveData = ({
  city,
  formData,
  drive,
  isEditProcess,
  options,
  showGoogleLocation,
}: {
  city?: string;
  drive?: Drive;
  formData?: PlDriveFormData | null;
  isEditProcess?: boolean;
  options?: Record<string, DropdownOption[]>;
  showGoogleLocation?: boolean;
}): Record<string, unknown> => {
  const {
    commentToDriver,
    commentToPax,
    driver,
    dropoffDate,
    dropoffLatitude,
    dropoffLocation,
    dropoffLongitude,
    dropoffTime,
    dropoffTown,
    pickupDate,
    pickupLatitude,
    pickupLocation,
    pickupLongitude,
    pickupTime,
    pickupTown,
    vehicle,
  } = drive || {};

  const { driverOpt, locOpt, vehicleOpt } = options ?? {};
  const { driverId, resDate, scheduled, vehicleId } = formData ?? {};
  const stops = scheduled?.shuttleConfig?.stops || formData?.stops;

  const dropoffLoc =
    stops || scheduled
      ? findOption(locOpt, scheduled?.dropoffLocation || dropoffLocation)
      : isEditProcess
      ? dropoffLocation
      : '';

  const pickupLoc =
    stops || scheduled
      ? findOption(locOpt, scheduled?.pickupLocation || pickupLocation)
      : isEditProcess
      ? pickupLocation
      : '';

  const { address: dropoffAddress, location: dropoffLocationParsed } = parseLocation(
    dropoffLoc as string,
  );
  const { address: pickupAddress, location: pickupLocationParsed } = parseLocation(
    pickupLoc as string,
  );

  return {
    commentToDriver,
    commentToPax,
    driver: findOption(driverOpt, isEditProcess ? driver : scheduled?.driverId || driverId!),
    dropoffAddress: dropoffAddress || drive?.dropoffAddress,
    dropoffDate:
      isEditProcess || scheduled
        ? parse(isEditProcess ? dropoffDate! : scheduled?.dropoffDate!, DF.ApiDateAlt, new Date())
        : resDate!,
    dropoffLocation: dropoffLocationParsed,
    dropoffTime:
      isEditProcess || scheduled
        ? parse(isEditProcess ? dropoffTime! : scheduled?.dropoffTime!, DF.ApiTimeFull, new Date())
        : addMinutes(getSoonTime(resDate!), TIME_INTERVAL),
    dropoffTown: isEditProcess ? dropoffTown : scheduled?.dropoffTown || city,
    pickupAddress: pickupAddress || drive?.pickupAddress,
    pickupDate:
      isEditProcess || scheduled
        ? parse(isEditProcess ? pickupDate! : scheduled?.pickupDate!, DF.ApiDateAlt, new Date())
        : resDate!,
    pickupLocation: pickupLocationParsed,
    pickupTime:
      isEditProcess || scheduled
        ? parse(isEditProcess ? pickupTime! : scheduled?.pickupTime!, DF.ApiTimeFull, new Date())
        : addMinutes(getSoonTime(resDate!), TIME_INTERVAL),
    pickupTown: isEditProcess ? pickupTown : scheduled?.pickupTown || city,
    vehicle: findOption(vehicleOpt, isEditProcess ? vehicle : vehicleId),
    ...(showGoogleLocation && {
      dropoffLat: isEditProcess ? dropoffLatitude ?? 0 : null,
      dropoffLng: isEditProcess ? dropoffLongitude ?? 0 : null,
      pickupLat: isEditProcess ? pickupLatitude ?? 0 : null,
      pickupLng: isEditProcess ? pickupLongitude ?? 0 : null,
    }),
  };
};

export const getInitPaxData = (pax: number) =>
  Array.from({ length: pax }, () => ({
    agency: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    requestingUnit: '',
  }));

export const getLocationChoices = (data: {
  [key: string]: string[];
}): { [key: string]: DropdownOption[] } =>
  Object.fromEntries(
    Object.entries(data).map(([key, values]) => [
      key,
      values.map((value) => ({ label: value, value })),
    ]),
  ) ?? {};
