import { IMobilityRequestsRepository, PrivateVehicleCreateApi } from '@common/interfaces';
import AxiosRepository from './axios.repository';

class MobilityRequestsRepository extends AxiosRepository implements IMobilityRequestsRepository {
  async createPrivateVehicle(payload: PrivateVehicleCreateApi) {
    const { data } = await this.post(`/mobility-requests/submit/`, payload);

    return data;
  }

  async getMobilityRequestsOptions() {
    const { data } = await this.get(`/mobility-requests/options/`);

    return data;
  }
}

export default MobilityRequestsRepository;
