/* istanbul ignore file */
import { FC, useEffect, useState } from 'react';
import { useFormApi } from 'informed';
import { t } from 'i18next';

import { Dropdown, TextField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';
import { DriveFormSteps, DropdownOption } from '@common/interfaces';
import '../../DriveForm.styles.scss';

interface DropoffLocationFieldProps {
  choices?: { [x: string]: DropdownOption[] };
  fieldGroup?: string;
  index?: number;
  returnTrip?: boolean;
}

const DropoffLocationField: FC<DropoffLocationFieldProps> = ({
  choices,
  fieldGroup,
  index,
  returnTrip = false,
}) => {
  const formApi = useFormApi();
  const formState = formApi.getFormState().values as any;
  const tripData = formState?.[DriveFormSteps.Trip];
  const pickupLocation =
    tripData?.driveList && typeof index === 'number'
      ? tripData.driveList[index]?.pickupLocation
      : null;

  const dropoffTown = fieldGroup ? `${fieldGroup}.dropoffTown` : 'dropoffTown';
  const dropoffTownCurrent = formApi.getValue(dropoffTown) as DropdownOption;

  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    if (choices && dropoffTownCurrent && dropoffTownCurrent.value in choices) {
      setOptions(choices[dropoffTownCurrent.value]);
    } else {
      setOptions([]);
    }
  }, [choices, dropoffTownCurrent]);

  useEffect(() => {
    if (returnTrip && pickupLocation) {
      formApi.setValue(
        fieldGroup ? `${fieldGroup}.dropoffLocation` : 'dropoffLocation',
        pickupLocation,
      );
    }
  }, [fieldGroup, formApi, pickupLocation, returnTrip]);

  return choices ? (
    <div className="field" data-testid="planner-drive-form-field-dropoff-location">
      <Dropdown
        className="dark-border small-list"
        isClearable
        isCreatable
        label={t('bookingDetails.dropoffLocation')}
        name="dropoffLocation"
        options={options}
        placeholder={`${t('common.select')} ${t('bookingDetails.dropoffTown')}...`}
        validate={notEmptyValidator}
      />
    </div>
  ) : (
    <div className="field">
      <TextField
        className="input"
        data-testid="planner-drive-form-field-dropoff-location"
        disabled={returnTrip}
        label={t('bookingDetails.dropoffLocation')}
        name="dropoffLocation"
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default DropoffLocationField;
