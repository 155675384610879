/* istanbul ignore file */
/* eslint-disable no-nested-ternary */
import { type FC, Fragment, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrayField, Multistep, useFormApi, useFormState, useMultistepApi } from 'informed';

import { Button } from '@ui-modules';
import {
  Drive,
  DriveFormSteps,
  DriveFormState,
  DropdownOption,
  PlDrive,
  PlDriveFormData,
  PlVehicle,
  TransferType,
  TripType,
} from '@common/interfaces';
import { calcSharedDrive } from '@common/utils';
import { useFacility, useFieldState } from '@common/hooks';
import { Add } from '@assets/svg/icons';

import RecurringDrive from './RecurringDrive';
import {
  ButtonSubmit,
  CommentField,
  DriverField,
  DropoffDateField,
  DropoffLocationField,
  DropoffTimeField,
  DropoffTownField,
  PickupDateField,
  PickupLocationField,
  PickupTimeField,
  PickupTownField,
  PurposeField,
  TransferTypeField,
  TripTypeField,
  VehicleField,
} from './fields';
import '../DriveForm.styles.scss';

const multiLegLimit = 20;
let multiLegCount = 0;

interface StepProps {
  drive?: Drive;
  drives: PlDrive[];
  driverOptions: DropdownOption[];
  dropoffLocationChoices?: { [x: string]: DropdownOption[] };
  dropoffTownOptions?: DropdownOption[];
  formData: PlDriveFormData | null;
  initMultileg: Record<string, unknown>;
  pickupLocationChoices?: { [x: string]: DropdownOption[] };
  pickupTownOptions?: DropdownOption[];
  purposeOptions: DropdownOption[];
  tripTypeOptions: DropdownOption[];
  vehicleOptions: DropdownOption[];
  vehicles: PlVehicle[];
  setStepReturnTripEnabled: (value: boolean) => void;
}

const TripStep: FC<StepProps> = ({
  drive,
  drives,
  driverOptions,
  dropoffLocationChoices,
  dropoffTownOptions,
  formData,
  initMultileg,
  pickupLocationChoices,
  pickupTownOptions,
  purposeOptions,
  tripTypeOptions,
  vehicleOptions,
  vehicles,
  setStepReturnTripEnabled,
}) => {
  const { facility } = useFacility();
  const { values } = useFormState() as any;
  const { next, setCurrent } = useMultistepApi();
  const { t } = useTranslation();

  const formApi = useFormApi();

  const { value: transferType } = useFieldState<TransferType>(
    `${DriveFormSteps.Trip}.transferType`,
  );
  const { value: typeOfTrip } = useFieldState<DropdownOption>(`${DriveFormSteps.Trip}.typeOfTrip`);

  const isEditProcess = !!formData?.id;
  const isRoundTrip = typeOfTrip?.value === TripType.RoundTrip;
  const isMultiLeg =
    typeOfTrip?.value === TripType.MultiLeg && transferType !== TransferType.Airport;

  const fieldGroup = isMultiLeg ? 'driveListMultileg' : 'driveList';

  const { bookedRouteOrder, driver } = drive! || {};

  const formState = { ...(values as DriveFormState)?.[DriveFormSteps.Trip] };

  const {
    driver: driverOpt,
    dropoffDate,
    dropoffTime,
    pickupDate,
    pickupTime,
    vehicle,
  } = formState?.[fieldGroup]?.[0] || {};
  const drivesRest = drives.filter((i: PlDrive) => i.id !== drive?.id);

  const sharedDrive = useMemo(
    () => calcSharedDrive({ drives: drivesRest, ...formState?.[fieldGroup]?.[0] }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [driverOpt, dropoffDate, dropoffTime, pickupDate, pickupTime, vehicle],
  );

  useEffect(() => {
    if (typeOfTrip) setStepReturnTripEnabled(typeOfTrip?.value === TripType.RoundTrip);
  }, [setStepReturnTripEnabled, typeOfTrip]);

  const getFieldsSection = (): JSX.Element => {
    const sectionTitle =
      transferType !== TransferType.Airport && isRoundTrip
        ? isEditProcess
          ? bookedRouteOrder === 1
            ? t('bookingDetails.outwardTrip')
            : t('bookingDetails.returnTrip')
          : `1. ${t('bookingDetails.outwardTrip')}`
        : null;

    let fieldset: JSX.Element;

    if (!isMultiLeg) {
      fieldset = (
        <ArrayField name={fieldGroup}>
          {() => (
            <ArrayField.Items>
              {({ index, name }) => {
                const isReturnTrip = !isMultiLeg && index === 1;
                const data = formState?.[fieldGroup];

                if (data && index >= data?.length) return <Fragment key={name} />;

                return (
                  <Fragment key={name}>
                    {sectionTitle && <div className="section-title">{sectionTitle}</div>}

                    <div className="row field-group">
                      <PickupDateField fieldGroup={name} />
                      <PickupTimeField fieldGroup={name} />
                      <PickupTownField fieldGroup={name} options={pickupTownOptions} />
                      <PickupLocationField
                        choices={pickupLocationChoices}
                        fieldGroup={name}
                        index={index}
                        returnTrip={isReturnTrip}
                      />
                      <DropoffDateField />
                      <DropoffTimeField fieldGroup={name} />
                      <DropoffTownField fieldGroup={name} options={dropoffTownOptions} />
                      <DropoffLocationField
                        choices={dropoffLocationChoices}
                        fieldGroup={name}
                        index={index}
                        returnTrip={isReturnTrip}
                      />
                      <VehicleField vehiclesOptions={vehicleOptions} />
                      <DriverField
                        driver={driver}
                        fieldGroup={name}
                        isEdit={isEditProcess}
                        options={driverOptions}
                        sharedDrive={isEditProcess ? null : sharedDrive}
                        vehicles={vehicles}
                      />
                      <CommentField label={t('planner.commentPax')} name="commentToPax" />
                      <CommentField label={t('planner.commentDriver')} name="commentToDriver" />
                    </div>
                  </Fragment>
                );
              }}
            </ArrayField.Items>
          )}
        </ArrayField>
      );
    } else {
      fieldset = (
        <ArrayField name={fieldGroup}>
          {({ addWithInitialValue }) => (
            <>
              <ArrayField.Items>
                {({ name, remove, index }) => {
                  multiLegCount = index + 2;
                  return (
                    <Fragment key={name}>
                      {isMultiLeg && (
                        <div className="section-title">
                          Leg {isEditProcess ? bookedRouteOrder : index + 1}
                          {index > 1 && !isEditProcess && (
                            <Button
                              className="btn-multileg-remove"
                              icon={<div>&#215;</div>}
                              variant="icon"
                              onClick={remove}
                            />
                          )}
                        </div>
                      )}

                      <div className="field-group">
                        <PickupDateField fieldGroup={name} />
                        <PickupTimeField fieldGroup={name} />
                        <PickupTownField fieldGroup={name} options={pickupTownOptions} />
                        <PickupLocationField
                          choices={pickupLocationChoices}
                          fieldGroup={name}
                          index={index}
                        />
                        <DropoffDateField />
                        <DropoffTimeField fieldGroup={name} />
                        <DropoffTownField fieldGroup={name} options={dropoffTownOptions} />
                        <DropoffLocationField
                          choices={dropoffLocationChoices}
                          fieldGroup={name}
                          index={index}
                        />
                        <VehicleField vehiclesOptions={vehicleOptions} />
                        <DriverField
                          driver={driver}
                          fieldGroup={name}
                          isEdit={isEditProcess}
                          options={driverOptions}
                          sharedDrive={isEditProcess ? null : sharedDrive}
                          vehicles={vehicles}
                        />
                      </div>

                      <div className="row">
                        <CommentField label={t('planner.commentPax')} name="commentToPax" />
                        <CommentField label={t('planner.commentDriver')} name="commentToDriver" />
                      </div>
                    </Fragment>
                  );
                }}
              </ArrayField.Items>

              {isMultiLeg && !isEditProcess && multiLegCount <= multiLegLimit && (
                <div className="row">
                  <Button
                    className="btn-multileg-add"
                    icon={<Add />}
                    text={t('planner.addLeg')}
                    variant="primary"
                    onClick={() => addWithInitialValue(initMultileg)}
                  />
                </div>
              )}
            </>
          )}
        </ArrayField>
      );
    }

    return fieldset;
  };

  const getNextBtn = (): JSX.Element | null => {
    let nextBtn: JSX.Element | null = null;

    if (transferType) {
      if (isEditProcess) {
        nextBtn = <ButtonSubmit />;
      } else if (isRoundTrip) {
        nextBtn = (
          <Button
            data-testid="planner-drive-form-btn-next"
            text={t('common.btnNext')}
            variant="primary"
            onClick={next}
          />
        );
      } else if (!isRoundTrip && sharedDrive) {
        nextBtn = (
          <Button
            data-testid="planner-drive-form-btn-save"
            text={t('common.btnSave')}
            variant="primary"
            onClick={next}
          />
        );
      } else {
        nextBtn = <ButtonSubmit />;
      }
    }

    return nextBtn;
  };

  const showRecurringBtn =
    !isEditProcess &&
    (transferType === TransferType.Airport ||
      (typeOfTrip && typeOfTrip?.value !== TripType.RoundTrip));

  return (
    <Multistep.Step step={DriveFormSteps.Trip}>
      {isEditProcess && (
        <div className="steps">
          <Button
            className="btn"
            data-testid="planner-drive-form-btn-main-info"
            text={t('planner.mainInfo')}
            onClick={() => setCurrent(DriveFormSteps.Main)}
          />
          <Button
            className="btn"
            data-testid="planner-drive-form-btn-trip-info"
            disabled
            text={t('planner.tripInfo')}
            onClick={() => setCurrent(DriveFormSteps.Trip)}
          />
        </div>
      )}

      <section className={`fieldset ${isMultiLeg ? 'multi-leg' : ''}`}>
        <div className="row">
          <TransferTypeField city={facility.city} fieldGroup={fieldGroup} isEdit={isEditProcess} />
        </div>

        {transferType && (
          <>
            {transferType !== TransferType.Airport ? (
              <div className="row">
                <TripTypeField isEdit={isEditProcess} options={tripTypeOptions} />
              </div>
            ) : null}

            {(typeOfTrip || transferType === TransferType.Airport) && (
              <>
                {getFieldsSection()}

                {transferType !== TransferType.Airport && (
                  <div className="row">
                    <PurposeField purposeOptions={purposeOptions} />
                  </div>
                )}

                {values.addRecurring &&
                (transferType === TransferType.Airport ||
                  typeOfTrip?.value !== TripType.RoundTrip) ? (
                  <div className="row">
                    <RecurringDrive
                      currentDate={formData?.resDate}
                      isMultileg={isMultiLeg}
                      step={DriveFormSteps.Trip}
                    />
                  </div>
                ) : null}
              </>
            )}

            <div className="buttons">
              <Button
                data-testid="planner-drive-form-btn-back"
                text={t('common.btnBack')}
                variant="secondary"
                onClick={() => setCurrent(DriveFormSteps.Main)}
              />

              {showRecurringBtn &&
                (values.addRecurring ? (
                  <Button
                    onClick={() => formApi.setValue('addRecurring', false)}
                    text={t('common.recurring.cancelRecurringBtn')}
                    variant="primary"
                  />
                ) : (
                  <Button
                    onClick={() => formApi.setValue('addRecurring', true)}
                    text={t('common.recurring.addRecurringBtn')}
                    variant="primary"
                  />
                ))}

              {getNextBtn()}
            </div>
          </>
        )}
      </section>
    </Multistep.Step>
  );
};

export default TripStep;
