/* istanbul ignore file */
export enum MobilityRequestsType {
  PRIVATE_VEHICLE = 'private_vehicle_request',
}

export interface MROptionsApi {
  name: string;
  options: {
    [x: string]: string[];
  };
}

export interface PrivateVehicleCreateApi {
  type_of_request: string;
  data: {
    agency: string;
    contact_email: string[];
    contract_type: string;
    country: string;
    date_from: string;
    date_to: string;
    duty_station: string;
    entry_on_duty: string;
    first_name: string;
    last_name: string;
    is_booking_on_behalf_of_colleague: boolean;
    is_driver_needed: boolean;
    not_to_exceed: string;
    position: string;
    preferred_type_of_vehicle: string;
    profile_email: string;
    purpose: string;
    reason: string;
  };
}
