/* istanbul ignore file */
import { TFunction } from 'i18next';

export enum FormType {
  Self = 'self',
  Colleague = 'colleague',
}

export const getFormTypes = (t: TFunction) => [
  { label: t('bookingForm.tabSelf'), value: FormType.Self },
  { label: t('bookingForm.tabColleague'), value: FormType.Colleague },
];
