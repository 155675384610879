/* istanbul ignore file */
import { t } from 'i18next';
import * as yup from 'yup';

const SummarySchema = () =>
  yup.object().shape({
    month: yup.string().nullable().optional(),
    preparedBy: yup.string().required(t('common.fieldRequired')),
    year: yup.string().nullable().optional(),
  });

export default SummarySchema;
