/* istanbul ignore file */
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { FeedbackLatestListApi, LastRating } from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import AverageRating from '@components/AverageRating';

const FacilityAverageRating: FC = () => {
  const { feedbackRepo } = useRepository();
  const { facility, facilityId } = useFacility();
  const { agencyId, aspAvgRating, categoryName, city, country } = facility;

  const [feedbacks, setFeedbacks] = useState<LastRating[]>([]);

  useQuery('get-feedbacks', () => feedbackRepo.getLatestFeedbacks(facilityId, { agencyId }), {
    enabled: Boolean(agencyId && facilityId),
    retry: false,
    onSuccess: (data: FeedbackLatestListApi[]) => {
      setFeedbacks(
        data?.map(
          (f: FeedbackLatestListApi): LastRating => ({
            date: f.created,
            id: f.uuid,
            refCode: f.reference_code,
            value: f.rate,
          }),
        ),
      );
    },
  });

  return city && country ? (
    <AverageRating
      backgroundColor="badge-color"
      facilityName={`${city} ${categoryName}, ${country}`}
      ratings={feedbacks}
      type="full"
      value={Number(aspAvgRating?.toFixed(1)) || 0}
    />
  ) : null;
};

export default FacilityAverageRating;
