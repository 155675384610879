import { type FC, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Download } from '@assets/svg/icons';
import { ReportParams } from '@common/interfaces';
import { useFacility, useUserInfo } from '@common/hooks';
import { useRepository } from '@context';
import { Button, PageTitle, Tabs } from '@components';
import { ReportDetails, Summary } from './components';
import './CostRecoveryReportPage.styles.scss';

const useTabs = (init: string, key: string) => {
  const [tab, setTab] = useState<string>(sessionStorage.getItem(key) ?? init);
  const onTabChange = (selectedTab: string) => {
    sessionStorage.setItem(key, tab);
    setTab(selectedTab);
  };

  useEffect(
    () => () => {
      sessionStorage.removeItem(key);
    },
    [key],
  );

  return { tab, onTabChange };
};

const CostRecoveryReportPage: FC = () => {
  const { t } = useTranslation();
  const { reportRepo } = useRepository();
  const { firstName, lastName } = useUserInfo();
  const { agencyName, facility, facilityId } = useFacility();
  const { city, country } = facility;

  const tabs = useMemo(() => ['Report Details', 'Summary'], []);
  const { tab, onTabChange } = useTabs(tabs[0], 'selectedTabCostRecoveryReport');

  const username = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName]);
  const pageTitle = useMemo(
    () => `${t('report.costRecoveryTitle')} ${city}, ${country} | ${agencyName}`,
    [t, city, country, agencyName],
  );

  const { mutate: exportDrives, isLoading: fileLoading } = useMutation(
    'export-drives',
    (params: ReportParams) => reportRepo.exportDrives(facilityId, params),
    {
      onError: () => {
        toast.error(t('report.errorMsgDrives'));
      },
    },
  );

  return (
    <section className="hbh-container cost-recovery-report-page">
      <PageTitle
        title={pageTitle}
        bottomLine
        tools={
          <Button
            className="btn btn-export"
            disabled={fileLoading}
            form="report_filters_form"
            leftIcon={<Download />}
            text={t('common.btnExportData')}
            type="submit"
            variant="submit"
          />
        }
      />

      <Tabs
        className="tabs-white"
        tabs={tabs}
        selectedTab={tabs.indexOf(tab)}
        variant="outline"
        onTabSelect={onTabChange}
      />

      {tab === tabs[0] && <ReportDetails username={username} exportDrives={exportDrives} />}
      {tab === tabs[1] && <Summary username={username} exportDrives={exportDrives} />}
    </section>
  );
};

export default CostRecoveryReportPage;
