/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { useFormApi } from 'informed';
import { t } from 'i18next';

import { Dropdown, TextField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';
import { DriveFormSteps, DropdownOption } from '@common/interfaces';
import '../../DriveForm.styles.scss';

interface FieldProps {
  choices?: { [x: string]: DropdownOption[] };
  fieldGroup?: string;
  index?: number;
  returnTrip?: boolean;
}

const PickupLocationField = ({ choices, fieldGroup, index, returnTrip = false }: FieldProps) => {
  const formApi = useFormApi();
  const formState = formApi.getFormState().values as any;
  const tripData = formState?.[DriveFormSteps.Trip];
  const dropoffLocation =
    tripData?.driveList && typeof index === 'number'
      ? tripData.driveList[index]?.dropoffLocation
      : null;

  const pickupTown = fieldGroup ? `${fieldGroup}.pickupTown` : 'pickupTown';
  const pickupTownCurrent = formApi.getValue(pickupTown) as DropdownOption;

  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    if (choices && pickupTownCurrent && pickupTownCurrent.value in choices) {
      setOptions(choices[pickupTownCurrent.value]);
    } else {
      setOptions([]);
    }
  }, [choices, pickupTownCurrent]);

  useEffect(() => {
    if (returnTrip && dropoffLocation) {
      formApi.setValue(
        fieldGroup ? `${fieldGroup}.pickupLocation` : 'pickupLocation',
        dropoffLocation,
      );
    }
  }, [dropoffLocation, fieldGroup, formApi, returnTrip]);

  return choices ? (
    <div className="field" data-testid="planner-drive-form-field-pickup-location">
      <Dropdown
        className="dark-border small-list"
        isClearable
        isCreatable
        label={t('bookingDetails.pickupLocation')}
        name="pickupLocation"
        options={options}
        placeholder={`${t('common.select')} ${t('bookingDetails.pickupTown')}...`}
        validate={notEmptyValidator}
      />
    </div>
  ) : (
    <div className="field">
      <TextField
        className="input"
        data-testid="planner-drive-form-field-pickup-location"
        disabled={returnTrip}
        label={t('bookingDetails.pickupLocation')}
        name="pickupLocation"
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default PickupLocationField;
