/* istanbul ignore file */
import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BookingTripType, BookingDetails } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { formatDateString } from '@common/utils';
import { Button } from '@components';
import { ArrowLeft, Details } from '@assets/svg/icons';
import '../BookingPanel.styles.scss';

type BookingInfoProps = { booking: BookingDetails; showShuttle: boolean };

const BookingInfo = ({ booking, showShuttle }: BookingInfoProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { agencyFk, firstName, lastName, pax, routes, typeOfTrip, typeOfTripDisplay } = booking;
  const isRoundTrip = typeOfTrip === BookingTripType.RoundTrip;
  const isMultiLeg = typeOfTrip === BookingTripType.MultiLeg;
  const linkBookings = pathname.split('/').slice(0, -2).join('/');

  const getRouteLabel = (idx: number) =>
    isRoundTrip ? `${idx ? t('bookingDetails.return') : t('bookingDetails.outward')} ` : '';

  const formatDate = (date: string): string =>
    formatDateString(date, `yyyy-MM-dd HH:mm`, `dd MMM yyyy HH:mm`);

  return showShuttle ? (
    <div className="booking-info theme-dark">
      <Button className="btn-back" icon={<ArrowLeft />} link={linkBookings} linkStyle="icon" />
      <div className="title">
        <h2>
          {booking.typeOfTripDisplay} &#x2022; {booking.transferTypeDisplay} transfer
        </h2>
        <span>
          {firstName} {lastName}, {agencyFk?.shortName} - {pax} Pax
        </span>
      </div>
      <Button
        className="link-details"
        rightIcon={<Details />}
        link={`${linkBookings}/${booking.id}`}
        text={t('bookingForm.details')}
        linkStyle="transparent"
      />
    </div>
  ) : (
    <ul className="booking-info">
      <li>
        <div>{t('common.firstName')}</div> {firstName}
      </li>
      <li>
        <div>{t('common.lastName')}</div> {lastName}
      </li>
      <li>
        <div>{t('common.agency')}</div> {agencyFk?.shortName}
      </li>
      <li>
        <div>{t('common.pax')}</div> {pax}
      </li>

      {routes.map((r, idx: number) =>
        isMultiLeg ? (
          <li key={r.id}>
            <div>
              {t('bookingDetails.leg')} {idx + 1}
            </div>
            <dl>
              <dt>{formatDate(`${r.pickupDate} ${r.pickupTime}`)}</dt>
              <dd>{`${r.pickupTown}, ${r.pickupLocation}`}</dd>
              <dt>{formatDateString(r.dropoffDate, DF.ApiDate, DF.BookingInfoDate)}</dt>
              <dd>{`${r.dropoffTown}, ${r.dropoffLocation}`}</dd>
            </dl>
          </li>
        ) : (
          <Fragment key={r.id}>
            <li>
              <div>
                {getRouteLabel(idx)}
                {t('bookingDetails.pickupAt')}
              </div>
              <span>{formatDate(`${r.pickupDate} ${r.pickupTime}`)}</span>
              <span>{`${r.pickupTown}, ${r.pickupLocation}`}</span>
            </li>
            <li>
              <div>
                {getRouteLabel(idx)}
                {t('bookingDetails.dropoffAt')}
              </div>
              <span>{formatDateString(r.dropoffDate, DF.ApiDate, DF.BookingInfoDate)}</span>
              <span>{`${r.dropoffTown}, ${r.dropoffLocation}`}</span>
            </li>
          </Fragment>
        ),
      )}

      <li>
        <div>{t('common.transferType')}</div> {typeOfTripDisplay}
      </li>
    </ul>
  );
};

export default BookingInfo;
