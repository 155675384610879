/* istanbul ignore file */
import { t } from 'i18next';
import * as yup from 'yup';

const ReportSchema = () =>
  yup.object().shape({
    approvedBy: yup.string().required(t('common.fieldRequired')),
    certifiedBy: yup.string().required(t('common.fieldRequired')),
    isCarpooled: yup.string().nullable().optional(),
    isRideShared: yup.string().nullable().optional(),
    month: yup.string().nullable().optional(),
    preparedBy: yup.string().required(t('common.fieldRequired')),
    search: yup.string().nullable().optional(),
    year: yup.string().nullable().optional(),
  });

export default ReportSchema;
