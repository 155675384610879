/* istanbul ignore file */
import { useEffect } from 'react';
import { t } from 'i18next';
import { Dropdown } from '@ui-modules/informed';
import { useFieldState } from '@common/hooks';
import { DropdownOption, TransferType } from '@common/interfaces';
import '../styles/modal.scss';

interface FieldProps {
  isEdit: boolean;
  options: DropdownOption[];
}

let isCollapsed = false;

const validateField = (type: TransferType, value: string): string | void => {
  if (!isCollapsed && type !== TransferType.Airport && !value) {
    return `${t('common.fieldRequired')}`;
  }
};

const TripTypeField = ({ isEdit, options }: FieldProps) => {
  const { value: transferType } = useFieldState<TransferType>('transferType');

  useEffect(
    () => () => {
      isCollapsed = true;
    },
    [],
  );

  return (
    <div className="field" data-testid="planner-drive-form-field-trip-type">
      <Dropdown
        className="dropdown"
        isDisabled={isEdit}
        label={isEdit ? t('common.tripType') : `${t('common.select')} ${t('common.tripType')}`}
        name="typeOfTrip"
        options={options}
        validate={(value) => validateField(transferType, value as string)}
      />
    </div>
  );
};

export default TripTypeField;
