/* istanbul ignore file */
import { t } from 'i18next';
import * as yup from 'yup';

const EditDriveSchema = () =>
  yup.object().shape({
    distance: yup
      .number()
      .required(t('common.fieldRequired'))
      .test('is-positive', "Distance can't be 0", (value) => value !== undefined && value > 0),
    dropoffDate: yup.date().required(t('common.fieldRequired')),
    dropoffTime: yup.string().required(t('common.fieldRequired')),
    durationDrive: yup.string().required(t('common.fieldRequired')),
    pickupDate: yup.date().required(t('common.fieldRequired')),
    pickupTime: yup.string().required(t('common.fieldRequired')),
  });

export default EditDriveSchema;
