import { FC, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Pagination, Table } from '@ui-modules';

import { History, Search } from '@assets/svg/icons';
import { MONTHS, YEARS, CURRENT_MONTH, CURRENT_YEAR, TABLE_ITEMS } from '@common/constants';
import { ApiList, InvoiceHistory, InvoiceHistoryApi } from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { getErrors, Serializer } from '@common/utils';
import { Button, Field, Form } from '@components';
import { useRepository } from '@context';
import { getInvoiceHistoryColumns } from './utils';
import './InvoiceHistoryPage.styles.scss';

enum ReducerActionType {
  CLEAR = 'CLEAR',
  KEYWORD = 'KEYWORD',
  MONTH = 'MONTH',
  PAGE = 'PAGE',
  YEAR = 'YEAR',
}

type ReducerAction = { type: ReducerActionType; payload?: string };

interface FormData {
  keyword: string;
  month: string;
  year: string;
}

interface SearchData {
  keyword: string;
  month: string;
  page: string;
  year: string;
}

const initSearchData: SearchData = {
  keyword: '',
  month: CURRENT_MONTH.value,
  year: CURRENT_YEAR.value,
  page: '1',
};

const searchDataReducer = (state: SearchData, action: ReducerAction): SearchData => {
  switch (action.type) {
    case ReducerActionType.KEYWORD:
    case ReducerActionType.MONTH:
    case ReducerActionType.YEAR:
      return { ...state, [action.type.toLowerCase()]: action.payload || '', page: '1' };
    case ReducerActionType.PAGE:
      return { ...state, page: action.payload || '' };
    case ReducerActionType.CLEAR:
      return { ...initSearchData };
    default:
      return state;
  }
};

const HistoryInvoicePage: FC = () => {
  const { facilityId } = useFacility();
  const { financialRepo } = useRepository();
  const { t } = useTranslation();

  const [invoiceData, setInvoiceData] = useState<InvoiceHistory[]>([]);
  const [searchData, dispatch] = useReducer(searchDataReducer, initSearchData);
  const [totalItems, setTotalItems] = useState(0);

  const { isLoading } = useQuery(
    ['get-invoices', searchData],
    () =>
      financialRepo.getInvoices(facilityId, {
        limit: 10,
        offset: (+searchData.page - 1) * 10,
        current_month: searchData.month,
        current_year: searchData.year,
        search: searchData.keyword,
      }),
    {
      onSuccess(data: ApiList<InvoiceHistoryApi>) {
        setTotalItems(data.count);
        setInvoiceData(data.results.map(Serializer.formatInvoiceHistory));
      },
      onError(error: any) {
        if (error.response) toast.error(getErrors(error.response.data));
      },
    },
  );

  return (
    <div className="invoice-history">
      <div className="title">
        <History />
        {t('invoice.individualInvoiceListTitle')}
      </div>

      <div className="filters-container">
        <Form
          className="form"
          defaultValues={initSearchData}
          schema={yup.object().shape({
            keyword: yup.string().nullable().optional(),
            month: yup.string().nullable().optional(),
            year: yup.string().nullable().optional(),
          })}
          onSubmit={({ keyword }: FormData) => {
            dispatch({ type: ReducerActionType.KEYWORD, payload: keyword });
          }}
        >
          {({ setValue }) => (
            <>
              <div className="search">
                <Field
                  name="keyword"
                  className="search-input"
                  placeholder={t('invoice.inputSearchByInvoiceNumber')}
                />
                <Button
                  className="btn-search"
                  rightIcon={<Search />}
                  type="submit"
                  variant="primary"
                />
                <Button
                  className="btn-reset"
                  text={t('common.btnResetFilters')}
                  variant="link"
                  onClick={() => {
                    setValue('keyword', initSearchData.keyword);
                    setValue('month', CURRENT_MONTH.value);
                    setValue('year', CURRENT_YEAR.value);
                    dispatch({ type: ReducerActionType.CLEAR });
                  }}
                />
              </div>

              <div className="filters">
                <Field
                  name="month"
                  input="dropdown"
                  className="month"
                  label={t('common.month')}
                  options={[{ value: '', label: t('common.allMonths') }, ...MONTHS]}
                  onChange={(value: string) =>
                    dispatch({ type: ReducerActionType.MONTH, payload: value })
                  }
                />

                <Field
                  name="year"
                  input="dropdown"
                  className="year"
                  label={t('common.year')}
                  options={[{ value: '', label: t('common.allYears') }, ...YEARS]}
                  onChange={(value: string) =>
                    dispatch({ type: ReducerActionType.YEAR, payload: value })
                  }
                />
              </div>
            </>
          )}
        </Form>
      </div>

      <div className="row">
        <Table
          columns={getInvoiceHistoryColumns(t)}
          data={invoiceData}
          isLoading={isLoading}
          rowKey="invoiceNumber"
          variant="light"
        />
      </div>

      <div className="footer">
        {totalItems > TABLE_ITEMS && (
          <Pagination
            selectedPage={+searchData.page}
            showJumper
            totalPages={totalItems}
            onPageChange={(selected: number): void =>
              dispatch({ type: ReducerActionType.PAGE, payload: selected.toString() })
            }
          />
        )}
      </div>
    </div>
  );
};

export default HistoryInvoicePage;
