/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import { Pagination, Table } from '@ui-modules';

import { InvAgencyApi } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { DeleteBlock } from '@components';
import './TripsDetails.styles.scss';

interface TripsDetailsProps {
  data?: InvAgencyApi;
  selectedPage: number;
  deleteTrip: ({ drives }: { drives: string[] }) => void;
  onPageChange: (page: number) => void;
}

interface TableProps {
  actions: JSX.Element;
  agency: string;
  budget_code: string;
  cost: string;
  driver: string;
  duration: string;
  green_fund: string;
  mileage: number;
  passenger: string;
  pax: string;
  pickup_date: string;
  reference_code: string;
  requesting_unit: string;
  transfer_type: string;
  type_of_trip: string;
  vehicle: string;
}

const TripsDetails = ({ data, selectedPage, deleteTrip, onPageChange }: TripsDetailsProps) => {
  const { t } = useTranslation();

  const [tripsDetails, setTripsDetails] = useState<TableProps[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  useEffect(() => {
    if (data?.drives.results.length) {
      const tableData = data?.drives.results.map((drive) => {
        const {
          booking_agency,
          booking_first_name,
          booking_last_name,
          budget_code,
          cost,
          driver_first_name,
          driver_last_name,
          green_fund,
          id,
          pickup_date,
          reference_code,
          type_of_trip,
          vehicle_label,
        } = drive;

        return {
          ...drive,
          actions: (
            <DeleteBlock
              deleteAction={(driveId) => deleteTrip({ drives: [driveId as string] })}
              id={id}
              key={id}
            />
          ),
          agency: booking_agency || '',
          budget_code: budget_code || '',
          cost: cost ? `$${cost}` : '$0',
          driver: `${driver_first_name} ${driver_last_name}`,
          green_fund: green_fund ? `$${green_fund}` : '$0',
          passenger: `${booking_first_name} ${booking_last_name}`,
          pickup_date: format(parse(pickup_date, DF.ApiDateAlt, new Date()), DF.DateByDots),
          reference_code,
          type_of_trip,
          vehicle: vehicle_label || '',
        };
      });

      setTripsDetails(tableData);
      setTotalItems(data.drives.count);
    } else {
      setTripsDetails([]);
      setTotalItems(0);
    }
  }, [data, deleteTrip]);

  const columns = useMemo(
    () => [
      { key: 'pickup_date', dataIndex: 'pickup_date', title: 'Date' },
      { key: 'passenger', dataIndex: 'passenger', title: t('common.passenger') },
      {
        key: 'agency',
        dataIndex: 'agency',
        title: t('common.agency'),
        ellipsis: {
          showTitle: true,
        },
      },
      { key: 'reference_code', dataIndex: 'reference_code', title: t('common.refCode') },
      { key: 'budget_code', dataIndex: 'budget_code', title: t('common.budgetCode') },
      { key: 'requesting_unit', dataIndex: 'requesting_unit', title: t('common.unit') },
      { key: 'pax', dataIndex: 'pax', title: 'PAX' },
      { key: 'transfer_type', dataIndex: 'transfer_type', title: t('common.transferType') },
      { key: 'type_of_trip', dataIndex: 'type_of_trip', title: t('common.typeOfTrip') },
      {
        key: 'vehicle',
        dataIndex: 'vehicle',
        title: t('common.vehicle'),
        ellipsis: {
          showTitle: true,
        },
      },
      {
        key: 'driver',
        dataIndex: 'driver',
        title: t('common.driver'),
        ellipsis: {
          showTitle: true,
        },
      },
      { key: 'duration', dataIndex: 'duration', title: t('common.duration') },
      { key: 'mileage', dataIndex: 'mileage', title: 'Km' },
      { key: 'cost', dataIndex: 'cost', title: `${t('common.price')} $` },
      { key: 'green_fund', dataIndex: 'green_fund', title: `${t('common.greenFund')} $` },
      { key: 'actions', dataIndex: 'actions', title: '' },
    ],
    [t],
  );

  return (
    <section className="trips-details">
      <div className="table">
        <Table columns={columns} data={tripsDetails} />
      </div>

      {totalItems > 10 && (
        <div className="row">
          <Pagination
            selectedPage={selectedPage}
            showJumper
            totalPages={totalItems}
            onPageChange={(selected: number): void => onPageChange(selected)}
          />
        </div>
      )}
    </section>
  );
};

export default TripsDetails;
