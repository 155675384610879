import { type FC, useMemo, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import type { DropdownOption, IndInvoicePatch } from '@common/interfaces';
import './styles.scss';

interface SendBlockProps {
  defaultValue?: string | string[];
  updateInvoice: (data: Partial<IndInvoicePatch>) => void;
}

const SendCopy: FC<SendBlockProps> = ({ defaultValue = '', updateInvoice }) => {
  const initEmails = useMemo(
    () =>
      defaultValue?.length
        ? (Array.isArray(defaultValue) ? defaultValue : [defaultValue]).map((i: string) => ({
            label: i,
            value: i,
          }))
        : [],
    [defaultValue],
  );

  const [emails, setEmails] = useState<DropdownOption[]>(initEmails);

  const handleEmailsChange = (value: unknown) => {
    setEmails(value as DropdownOption[]);
    updateInvoice({
      recipients: (value as DropdownOption[]).map((i: DropdownOption) => i.value),
    });
  };

  return (
    <div className="invoice-send-copy">
      <CreatableSelect
        className="select"
        data-testid="invoice-send-copy"
        isMulti
        options={emails}
        value={emails}
        onChange={handleEmailsChange}
      />
    </div>
  );
};

export default SendCopy;
