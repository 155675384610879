import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DropdownCaret,
  FeedbackCalendar,
  FeedbackDriver,
  FeedbackUser,
  Ride,
  Vehicle,
} from '@assets/svg/icons';
import { FeedbackStatus, IFeedbacksList, FeedbackUpdateApi } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { formatDateString } from '@common/utils';
import { Modal, ReviewRating } from '@components';
import ReviewAnswerForm from '../ReviewAnswerForm/ReviewAnswerForm';
import StatusSelector from '../StatusSelector/StatusSelector';
import './Feedback.styles.scss';

export interface FeedbackProps {
  color: 'red' | 'yellow' | 'green' | 'grey';
  item: IFeedbacksList;
  showRating?: boolean;
  updateFeedbackStatus?: ({
    feedbackId,
    feedbackData,
  }: {
    feedbackId: string;
    feedbackData: FeedbackUpdateApi;
  }) => void;
}

const Feedback = ({ color, item, showRating = true, updateFeedbackStatus }: FeedbackProps) => {
  const { t } = useTranslation();

  const [commentVisible, setCommentVisible] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<FeedbackStatus>();

  const getReviewStarHexColorCode = (colorType: string): string => {
    const colors: Record<string, string> = {
      red: '#b21217',
      yellow: '#c6bb1a',
      green: '#0c9415',
      grey: '#444546',
    };
    return colors[colorType] || '#fff';
  };

  interface StatusDetails {
    actionText: string;
    buttonText: string;
    placeholder: string;
    title: string;
  }

  const dictionary: Record<FeedbackStatus, StatusDetails> = {
    not_processed: {
      actionText: '',
      buttonText: '',
      placeholder: '',
      title: '',
    },
    under_process: {
      actionText: t('feedback.processing'),
      buttonText: t('feedback.btnConfirmRemark'),
      placeholder: t('feedback.placeholderStatusUnderProcess'),
      title: t('feedback.insertRemark'),
    },
    forwarded_for_action: {
      actionText: t('feedback.forwardForAction'),
      buttonText: t('feedback.btnForward'),
      placeholder: t('feedback.placeholderStatusForwarded'),
      title: t('feedback.suggestion'),
    },
    closed: {
      actionText: t('feedback.closed'),
      buttonText: t('feedback.btnConfirmRemark'),
      placeholder: t('feedback.placeholderStatusClosed'),
      title: t('feedback.confirmOrEditRemark'),
    },
  };

  const getConfirmText = (s: FeedbackStatus): string => dictionary[s]?.buttonText || '';
  const getFormTitle = (s: FeedbackStatus): string => dictionary[s]?.title || '';
  const getPlaceholder = (s: FeedbackStatus): string => dictionary[s]?.placeholder || '';
  const getActionText = (s: FeedbackStatus): string => dictionary[s]?.actionText || '';

  const { comment, created, drive, id, lastStatusChangedBy, rate, remarkSet, status } = item;
  const {
    bookingReferenceCode,
    driverFullName,
    dropoffLocation,
    pickupDate,
    pickupLocation,
    vehiclePlateNumber,
  } = drive;

  const getTriggerText = (): string => {
    const subject =
      comment && remarkSet.length > 0 ? t('feedback.commentAndRemark') : t('feedback.comment');
    const action = commentVisible ? t('feedback.close') : t('feedback.see');

    return `${action} ${subject}`;
  };

  const onPendingStatusChange = (newStatus: FeedbackStatus): void => {
    setPendingStatus(newStatus);
    setModalVisible(true);
  };

  const handleConfirm = (value: string) => {
    if (pendingStatus) {
      updateFeedbackStatus?.({
        feedbackId: id,
        feedbackData: {
          comment: value?.trim() || '',
          status: pendingStatus,
        },
      });
    }
    setPendingStatus(undefined);
  };

  return (
    <section className="feedback-container">
      <section className="feedback">
        <div className="feedback-created">
          <div className="feedback-created-icon">
            <FeedbackUser />
          </div>
          <div className="feedback-created-date">
            {formatDateString(created, DF.DateFullAlt, DF.BookingInfoDate)}
          </div>
        </div>

        {showRating && (
          <div className="feedback-rate">
            <ReviewRating
              backgroundColor={getReviewStarHexColorCode(color)}
              orientation="left"
              value={rate}
            />
          </div>
        )}

        <div className="feedback-details">
          <div className="feedback-details-header">
            <div className="feedback-details-review">
              <ul className="feedback-details-review-subject">
                {[
                  { label: 'common.driver', value: driverFullName, icon: <FeedbackDriver /> },
                  { label: 'feedback.dateOfRide', value: pickupDate, icon: <FeedbackCalendar /> },
                  {
                    label: 'feedback.ride',
                    value: `${pickupLocation} - ${dropoffLocation}`,
                    icon: <Ride />,
                  },
                  { label: 'mobility.plateNumber', value: vehiclePlateNumber, icon: <Vehicle /> },
                ].map(({ label, value, icon }) => (
                  <li key={label}>
                    <div className="icon">{icon}</div>
                    <div className="text">
                      <div className="label">{t(label)}</div>
                      <div className="value">{value}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="feedback-status-selector">
              <StatusSelector status={status} onChange={onPendingStatusChange} />
            </div>
          </div>

          <div
            className={commentVisible || !comment ? 'feedback-comment open' : 'feedback-comment'}
          >
            <div className="comment">
              <div className="question">{comment}</div>

              {remarkSet
                .filter((i) => i.remark.trim().length > 0)
                .map((i, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="feedback-answer-wrap" key={`${i.created}-${idx}`}>
                    <div className="feedback-answer">
                      <div className="feedback-answer-by">
                        <div className="feedback-answer-by-prefix">{t('feedback.remarkedBy')}</div>
                        <div className="feedback-answer-by-name">{i.remarkedBy}</div>
                        <div className="feedback-answer-date">{created}</div>
                      </div>

                      <div className="feedback-answer-text">
                        <span
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: i.remark.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="feedback-footer">
            <div className="feedback-id">
              {bookingReferenceCode ? `ID #${bookingReferenceCode}` : ''}
            </div>

            <div className="comment-trigger">
              {(comment || (remarkSet && remarkSet.length > 0)) && (
                <button
                  className={commentVisible ? 'btn-trigger enabled' : 'btn-trigger'}
                  type="button"
                  onClick={() => {
                    setCommentVisible(!commentVisible);
                  }}
                >
                  <span className="text">{getTriggerText()}</span>
                  <span className="icon-caret">
                    <DropdownCaret />
                  </span>
                </button>
              )}
            </div>

            <div className="last-action">
              {!!lastStatusChangedBy && (
                <>
                  {`${status && getActionText(status)} by `}
                  <span className="last-action-by">{lastStatusChangedBy}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <Modal
        showBtnClose
        variant="light"
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      >
        <ReviewAnswerForm
          confirmText={pendingStatus && getConfirmText(pendingStatus)}
          feedback={(pendingStatus === 'forwarded_for_action' && comment) || undefined}
          placeholder={pendingStatus && getPlaceholder(pendingStatus)}
          requireValidation={pendingStatus === 'forwarded_for_action'}
          title={pendingStatus && getFormTitle(pendingStatus)}
          onConfirm={handleConfirm}
        />
      </Modal>
    </section>
  );
};

export default Feedback;
