/* eslint-disable react/jsx-props-no-spreading */
/* istanbul ignore file */
import type { FC } from 'react';
import { DateField, Dropdown, TextField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';
import { DropdownOption } from '@common/interfaces';
import { FormField } from './types';

function getField(field: FormField) {
  switch (field.type) {
    case 'date': {
      const props = {
        disabled: field.readonly,
        label: field.label || '',
        name: field.name || '',
        validate: field.required ? notEmptyValidator : undefined,
      };

      return <DateField {...props} showMonthDropdown showYearDropdown />;
    }
    case 'dropdown': {
      const { onChange, onInputChange } = field;
      const props = {
        disabled: field.readonly,
        label: field.label || '',
        name: field.name || '',
        options: field.options,
        validate: field.required ? notEmptyValidator : undefined,
      };

      return (
        <Dropdown
          {...props}
          onInputChange={(value) => {
            onInputChange?.(value);
          }}
          onChange={(option: DropdownOption) => {
            onChange?.(option);
          }}
        />
      );
    }
    case 'text': {
      const props = {
        disabled: field.readonly,
        initialValue: field.defaultValue,
        label: field.label || '',
        name: field.name || '',
        placeholder: field.placeholder || '',
        validate: field.required ? notEmptyValidator : undefined,
      };

      return <TextField {...props} />;
    }
    default: {
      return <div />;
    }
  }
}

interface FieldsetProps {
  fields: FormField[];
  footer?: JSX.Element | string;
  title?: JSX.Element | string;
}

const Fieldset: FC<FieldsetProps> = ({ fields, footer, title }) => (
  <fieldset>
    {title && <h2>{title}</h2>}
    {fields?.map((field: FormField) => (
      <div className="field" key={field.name}>
        {getField(field)}
      </div>
    ))}
    {footer && <footer>{footer}</footer>}
  </fieldset>
);

export default Fieldset;
