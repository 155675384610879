/* istanbul ignore file */
import { DateFormat as DF } from '@common/types';
import { format, parse, differenceInMinutes } from 'date-fns';

export const calcDurationDrive = ({
  startDate,
  startTime,
  endDate,
  endTime,
}: {
  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;
}): string => {
  const start = parse(`${format(startDate, DF.ApiDate)} ${startTime}`, DF.DateFull, new Date());
  const end = parse(`${format(endDate, DF.ApiDate)} ${endTime}`, DF.DateFull, new Date());
  const duration = differenceInMinutes(end, start);

  if (duration < 0 || duration > 6000) return '00:00';

  const fullHours = Math.floor(duration / 60);
  const hours = fullHours > 10 ? fullHours : `0${fullHours}`;
  const minutes = duration % 60 > 10 ? (duration % 60).toString() : `0${duration % 60}`;

  return `${hours}:${minutes}`;
};
