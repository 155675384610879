import { useTranslation } from 'react-i18next';
import { LastRating } from '@common/interfaces';
import { formatDateString } from '@common/utils';
import { RatingStars } from '@components';
import './LastRatings.styles.scss';

interface RatingProps {
  activeStarColor?: string;
  inactiveStarColor?: string;
  ratings?: LastRating[];
}

const LastRatings = ({
  activeStarColor = 'rgba(255,255,255,1)',
  inactiveStarColor = 'rgba(255,255,255,0.4)',
  ratings = [],
}: RatingProps) => {
  const { t } = useTranslation();

  return (
    <div className="last-ratings">
      <div className="last-ratings-header">{t('common.lastRatings')}</div>
      {ratings.map((item: LastRating) => (
        <div className="last-ratings-item" data-testid="last-ratings-item" key={item.id}>
          <div className="row">
            <div className="last-ratings-item-rating">{item.value}</div>
            <RatingStars
              activeColor={activeStarColor}
              inactiveColor={inactiveStarColor}
              readOnly
              value={item.value}
            />
          </div>
          <div className="row">
            <div className="last-ratings-item-ref">Id #{item.refCode}</div>
            <div className="last-ratings-item-date">
              {formatDateString(item.date, 'dd/MM/yyyy', 'dd MMM yyyy')}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LastRatings;
