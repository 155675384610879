import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useRepository } from '@context';
import { useFacility } from '@common/hooks';
import { Serializer } from '@common/utils';
import { DropdownOption, VehicleType } from '@common/interfaces';

interface IVehicles {
  isLoading: boolean;
  vehicleOptions: DropdownOption[];
  vehicles: VehicleType[];
}

const useVehicles = (): IVehicles => {
  const { mobilityRepository } = useRepository();

  const {
    facility: { agencyId },
    facilityId,
  } = useFacility();

  const [vehicles, setVehicles] = useState<VehicleType[]>([]);
  const [vehicleOptions, setVehicleOptions] = useState<DropdownOption[]>([]);

  const { data: vehicleData, isLoading } = useQuery(
    'get-vehicles',
    () =>
      mobilityRepository.getVehicles(facilityId, {
        agencyId,
        limit: 500,
      }),
    { enabled: !!agencyId, refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (vehicleData) {
      setVehicles(vehicleData.results.map(Serializer.formatVehicle));
    }
  }, [vehicleData, setVehicles]);

  useEffect(() => {
    if (vehicles) {
      setVehicleOptions(
        vehicles.map(
          (v: VehicleType): DropdownOption => ({
            label: v.label,
            value: v.id.toString(),
          }),
        ),
      );
    }
  }, [vehicles, setVehicleOptions]);

  return { isLoading, vehicles, vehicleOptions };
};

export default useVehicles;
