import type { FC } from 'react';
import { t } from 'i18next';
import { Close } from '@assets/svg/icons';
import { Button } from '@components';
import './Modal.styles.scss';

interface ModalProps {
  children?: React.ReactNode;
  className?: string;
  showBtnClose?: boolean;
  title?: string;
  variant?: 'default' | 'dark' | 'light' | 'confirm';
  visible?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

const Modal: FC<ModalProps> = ({
  children,
  className,
  showBtnClose = false,
  title,
  variant = 'default',
  visible = false,
  onClose,
  onConfirm,
}) =>
  visible ? (
    <div className={`modal ${className || ''}`}>
      <div
        aria-hidden="true"
        className="modal-bg"
        data-testid="modal-bg"
        onClick={() => onClose?.()}
      />
      <div className={`modal-box-${variant}`}>
        <div className="modal-header">
          {title && <div className="title">{title}</div>}

          {showBtnClose && (
            <Button
              className="btn-close"
              data-testid="modal-btn-close"
              icon={<Close />}
              variant="icon"
              onClick={() => onClose?.()}
            />
          )}
        </div>

        <div className="modal-content">{children}</div>

        {variant === 'confirm' && (
          <div className="modal-footer">
            <Button
              className="btn btn-close"
              text={t('common.no')}
              variant="warning"
              onClick={() => onClose?.()}
            />
            <Button
              className="btn btn-confirm"
              text={t('common.yes')}
              variant="submit"
              onClick={() => onConfirm?.()}
            />
          </div>
        )}
      </div>
    </div>
  ) : null;

export default Modal;
