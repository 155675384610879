/* istanbul ignore file */
import type { NavigateFunction as NFunc } from 'react-router';
import type { TFunction as TFunc } from 'react-i18next';
import { format, parse } from 'date-fns';

import { IndInvoiceList, InvoiceDriveSetTable } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { addTimeToTime } from '@common/utils';
import { Button } from '@components';

const getTotalDuration = (record: IndInvoiceList): string => {
  const init = '00:00:00';
  const totalDuration = record.driveSet
    .map((drive) => drive.duration)
    .filter((duration) => !!duration)
    .reduce((total, duration) => addTimeToTime(total, duration), init);

  return totalDuration !== init ? totalDuration : '-';
};

export const getIndInvoiceColumns = (nav: NFunc, t: TFunc): Record<string, any>[] => [
  {
    dataIndex: 'pickupDate',
    key: 'pickupDate',
    title: t('report.performedOn'),
    render: (record: string) => (
      <span>{record ? format(parse(record, DF.ApiDateAlt, new Date()), DF.DateByDots) : ''}</span>
    ),
  },
  {
    key: 'name',
    title: t('common.passenger'),
    ellipsis: {
      showTitle: true,
    },
    render: (record: IndInvoiceList) => <span>{`${record.firstName} ${record.lastName}`}</span>,
  },
  {
    dataIndex: 'paxAgency',
    key: 'agency',
    title: t('common.paxAgency'),
    ellipsis: {
      showTitle: true,
    },
    render: (record: string) => <span className="text">{record || '-'}</span>,
  },
  {
    dataIndex: 'requestingUnit',
    key: 'requestingUnit',
    title: t('common.unit'),
    ellipsis: {
      showTitle: true,
    },
    render: (record: string) => <span>{record || '-'}</span>,
  },
  {
    dataIndex: 'nOfPassengers',
    key: 'nOfPassengers',
    title: 'Pax',
    render: (record: string) => <span>{record || '-'}</span>,
  },
  {
    dataIndex: 'transferType',
    key: 'transferType',
    title: t('common.transferType'),
    render: (record: string) => <span>{record ? record.replace('_', ' ') : '-'}</span>,
  },
  {
    dataIndex: 'driveSet',
    key: 'vehicle',
    title: t('common.vehicle'),
    render: (record: InvoiceDriveSetTable[]) => (
      <span className="text">{record ? record[0]?.vehicle : '-'}</span>
    ),
  },
  {
    key: 'driver',
    title: t('common.driver'),
    ellipsis: {
      showTitle: true,
    },
    render: (record: IndInvoiceList) => {
      const driver =
        record.driveSet.length && record.driveSet[0].driver ? `${record.driveSet[0].driver}` : '';
      return <span>{driver || '-'}</span>;
    },
  },
  {
    dataIndex: 'refCode',
    key: 'refCode',
    title: t('common.refCode'),
  },
  {
    dataIndex: 'driveSet',
    key: 'km',
    title: 'Km',
    render: (record: InvoiceDriveSetTable[]) => (
      <span>
        {record.length > 0 && record[0].km
          ? record.reduce((prev: number, current: InvoiceDriveSetTable) => prev + current.km, 0)
          : '-'}
      </span>
    ),
  },
  {
    key: 'duration',
    title: t('common.duration'),
    render: getTotalDuration,
  },
  {
    key: 'price',
    title: `${t('common.price')} $`,
    render: (record: IndInvoiceList) => (
      <span>
        {record.driveSet.length && record.driveSet[0].price !== undefined
          ? `$${record.driveSet.reduce(
              (prev: number, current: InvoiceDriveSetTable) => prev + current.price,
              0,
            )}`
          : '-'}
      </span>
    ),
  },
  {
    key: 'greenFund',
    title: `${t('common.greenFund')} ($)`,
    render: (record: IndInvoiceList) => (
      <span>
        {record.driveSet.length && record.driveSet[0].greenFund !== undefined
          ? `$${record.driveSet.reduce(
              (prev: number, current: InvoiceDriveSetTable) => prev + current.greenFund,
              0,
            )}`
          : '-'}
      </span>
    ),
  },
  {
    key: 'actions',
    title: '',
    render: (record: IndInvoiceList) => (
      <div className="actions">
        {record.pdf ? (
          <Button
            className="btn-download"
            onClick={() => window.open(record.pdf)}
            text={t('common.btnDownloadInvoice')}
            variant="submit"
          />
        ) : (
          <Button
            className="btn-create"
            text={t('invoice.createInvoice')}
            variant="primary"
            onClick={() => nav(record.id)}
          />
        )}
      </div>
    ),
  },
];
