import { useQuery } from 'react-query';
import { useRepository } from '@context';
import type { FeatureFlags } from '@common/interfaces';

const INITIAL = { flags: {}, samples: {}, switches: {} } as FeatureFlags;

const useMonolithFeatureFlags = (): FeatureFlags => {
  const { accountRepository } = useRepository();
  const { data } = useQuery(
    'get-monolith-feature-flags',
    () => accountRepository.getMonolithFeatureFlags(),
    { initialData: INITIAL },
  );
  return data;
};

export default useMonolithFeatureFlags;
