import './styles.scss';

interface FormPanelProps {
  children?: JSX.Element | JSX.Element[] | null;
  className?: string;
  header?: JSX.Element;
  title?: JSX.Element;
}

const FormPanel = ({ children, className, header, title }: FormPanelProps) => (
  <section className={`invoice-form-panel ${className}`} data-testid="invoice-form-panel">
    {header && <div className="invoice-form-panel-header">{header}</div>}
    <div className="invoice-form-panel-content">
      {title && (
        <div className="invoice-form-panel-title-wrap">
          <h3 className="invoice-form-panel-title">{title}</h3>
        </div>
      )}
      {children}
    </div>
  </section>
);

export default FormPanel;
