import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { Table } from '@ui-modules';

import { CloseRound as Close, Plus } from '@assets/svg/icons';
import { InvAgencyPatch, InvAgencyServiceApi } from '@common/interfaces';
import { DateFormat, DateInputFormat } from '@common/types';
import { formatNumber, parseNumber } from '@common/utils';
import { Button, DeleteBlock, Field, Form } from '@components';
import './InvoiceCharges.styles.scss';

export interface FormState {
  date: Date | null;
  description: string;
  quantity: string;
  unitPrice: string;
}

interface ChargesProps {
  data: InvAgencyServiceApi[];
  updateInvoice: (data: Partial<InvAgencyPatch>) => void;
}

const InvoiceCharges = ({ data, updateInvoice }: ChargesProps) => {
  const { t } = useTranslation();

  const [forms, setForms] = useState<string[]>([]);

  const add = () => setForms((prev) => [...prev, uuid()]);
  const remove = (id: string) => setForms((prev) => [...prev].filter((i) => i !== id));

  const handleSubmit = useCallback(
    async ({ date, description, quantity, unitPrice }: FormState) => {
      updateInvoice({
        services: [
          ...data.map((item) => ({ ...item, id: undefined })),
          {
            date: format(date!, DateFormat.ApiDate),
            description,
            quantity: +parseNumber(quantity),
            unit_price: +parseNumber(unitPrice),
          },
        ],
      });

      setForms([]);
    },
    [data, updateInvoice],
  );

  const tableData = useMemo(
    () =>
      data.map(({ date, description, quantity, unit_price }, idx) => ({
        actions: (
          <DeleteBlock
            key={description}
            id={idx}
            deleteAction={() => {
              updateInvoice({
                services: data
                  .filter((_, i) => i !== idx)
                  .map((item) => ({ ...item, id: undefined })),
              });
            }}
          />
        ),
        date: format(parse(date!, DateFormat.ApiDate, new Date()), DateFormat.DateByDots),
        description: description || '',
        id: idx,
        quantity,
        unitPrice: unit_price,
      })),
    [data, updateInvoice],
  );

  const columns = useMemo(
    () => [
      { key: 'date', dataIndex: 'date', Header: 'Date' },
      { key: 'description', dataIndex: 'description', Header: 'Service' },
      { key: 'quantity', dataIndex: 'quantity', Header: t('invoice.quantity') },
      { key: 'unitPrice', dataIndex: 'unitPrice', Header: t('common.price') },
      { key: 'actions', dataIndex: 'actions', Header: '' },
    ],
    [t],
  );

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        date: yup.date().nullable().required(t('common.fieldRequired')),
        description: yup.string().nullable().required(t('common.fieldRequired')),
        quantity: yup.string().nullable().required(t('common.fieldRequired')),
        unitPrice: yup.string().nullable().required(t('common.fieldRequired')),
      }),
    [t],
  );

  const initFormData: FormState = useMemo(
    () => ({ date: null, description: '', quantity: '', unitPrice: '' }),
    [],
  );

  return (
    <section className="agency-invoice-charges-container">
      <div className="table">
        <Table columns={columns} data={tableData} locale={{ emptyText: 'No items' }} />
      </div>

      {forms.map((id: string) => (
        <Form
          className="form"
          defaultValues={initFormData}
          key={id}
          schema={formSchema}
          onSubmit={handleSubmit}
        >
          <div className="form-row">
            <div className="form-cell">
              <Field
                name="date"
                input="date-picker"
                placeholder="Date"
                valueFormat={DateInputFormat.DateByDots}
                data-testid="invoice-charges-date-picker"
              />
            </div>
            <div className="form-cell">
              <Field name="description" placeholder={t('common.description')} />
            </div>
            <div className="form-cell">
              <Field
                name="quantity"
                input="number"
                formatter={formatNumber}
                hideControls
                max={9999999}
                min={1}
                parser={(value: string) => value.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('invoice.quantity')}
              />
            </div>
            <div className="form-cell">
              <Field
                name="unitPrice"
                input="number"
                formatter={formatNumber}
                hideControls
                max={9999999}
                min={1}
                parser={(value: string) => value.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('common.price')}
              />
            </div>

            <div className="form-actions">
              <Button
                className="btn btn-confirm"
                text={t('common.btnConfirm')}
                type="submit"
                variant="primary"
              />
              <Button
                className="btn btn-delete"
                icon={<Close />}
                variant="icon"
                onClick={() => remove(id)}
              />
            </div>
          </div>
        </Form>
      ))}

      <div className="row">
        <Button leftIcon={<Plus />} text="Add Service" variant="secondary" onClick={() => add()} />
      </div>
    </section>
  );
};

export default InvoiceCharges;
