/* istanbul ignore file */
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useMemo, useCallback } from 'react';
import { useFieldState, useFormApi } from 'informed';
import { t } from 'i18next';
import { Dropdown } from '@ui-modules/informed';

import type { DropdownOption, PlDriver, PlResource, PlVehicle } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../../BookingPanel.styles.scss';

type FieldProps = {
  disabled?: boolean;
  driver?: string;
  drivers: PlDriver[];
  leg: number;
  resource?: [PlResource | null, Dispatch<SetStateAction<PlResource | null>>];
  value?: string;
  vehicles?: PlVehicle[];
  onChange?: (value: DropdownOption) => void;
};

const DriverField = ({
  disabled,
  driver,
  drivers,
  leg,
  resource = [null, () => {}],
  value,
  vehicles,
  onChange,
}: FieldProps) => {
  const formApi = useFormApi();
  const { value: vehicle } = useFieldState(`${leg}.vehicle`);

  const driverOpt = useMemo(
    () =>
      drivers.map(
        ({ id, lastName, name }: PlDriver): DropdownOption => ({
          label: `${name} ${lastName}`,
          value: id,
        }),
      ),
    [drivers],
  );

  const getOption = useCallback(
    (val: string) => driverOpt.find((i: DropdownOption) => i.value === val),
    [driverOpt],
  );

  const [scheduledResource, setScheduledResource] = resource;

  useEffect(() => {
    if (scheduledResource?.driverId) {
      formApi.setValue(`${leg}.driver`, getOption(scheduledResource?.driverId!));
      setScheduledResource(null);
      return;
    }

    if (value) formApi.setValue(`${leg}.driver`, getOption(value));
  }, [formApi, getOption, leg, scheduledResource, setScheduledResource, value]);

  useEffect(() => {
    const selectedVehicle = vehicles?.find(
      (v: PlVehicle) => v.id === (vehicle as DropdownOption)?.value,
    );

    if (selectedVehicle?.preferredDriver) {
      const preferredDriver = getOption(selectedVehicle?.preferredDriver);

      if (preferredDriver) formApi.setValue(`${leg}.driver`, preferredDriver);
    }
  }, [formApi, getOption, leg, vehicle, vehicles]);

  return (
    <div className="field" data-testid={`planner-booking-panel-driver-${leg}`}>
      <Dropdown
        className="dropdown"
        defaultValue={getOption(driver!)}
        isDisabled={disabled}
        label={t('mobility.selectDriver')}
        name={`${leg}.driver`}
        options={driverOpt}
        validate={notEmptyValidator}
        onChange={onChange}
      />
    </div>
  );
};

export default DriverField;
