/* istanbul ignore file */
export * from './auth';
export * from './api';
export * from './date';
export * from './filters';
export * from './logbook';

export const SUPPORT_EMAIL = process.env.REACT_APP_EMAIL_SUPPORT ?? 'mobility@unbooking.org';
export const TABLE_ITEMS = 10;
export const TIME_INTERVAL = 15;
