/* istanbul ignore file */
import { DropdownOption } from '@common/interfaces';

const currentDate = new Date();
const thisYear = currentDate.getFullYear();
const prevYear = thisYear - 1;

export const MONTHS: DropdownOption[] = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const YEARS: DropdownOption[] = [
  { value: String(thisYear), label: String(thisYear) },
  { value: String(prevYear), label: String(prevYear) },
];

export const CURRENT_MONTH = MONTHS.filter(
  (i) => i.value === (currentDate.getMonth() + 1).toString(),
)[0];
export const CURRENT_YEAR = YEARS.filter(
  (i) => i.value === currentDate.getFullYear().toString(),
)[0];
