import { useTranslation } from 'react-i18next';
import { BalanceWallet } from '@assets/svg/icons';
import { CRMonthlySummary } from '@common/interfaces';
import { Loader } from '@components';

type MonthlySummaryProps = { data: CRMonthlySummary | null; loading: boolean };

const MonthlySummary = ({ data, loading }: MonthlySummaryProps) => {
  const { t } = useTranslation();

  const items = [
    { label: t('report.monthlyTrips'), value: data?.drives || '0' },
    { label: t('report.monthlyBookings'), value: data?.bookingsCount || '0' },
    { label: t('report.monthlyTripsDistance'), value: `${data?.totalMileage || '0'} km` },
    { label: t('report.monthlyTripsDuration'), value: data?.totalTime || '00:00' },
    { label: t('common.greenFund'), value: `$${data?.greenFund || '0.0'}` },
  ];

  return (
    <section className="monthly-summary-box">
      <div className="header">
        <BalanceWallet />
        {t('report.monthlySummary')}
      </div>

      <Loader spinning={loading}>
        {items.map(({ label, value }) => (
          <div className="row" key={label}>
            <div className="label">{label}</div>
            <div className="value">{value}</div>
          </div>
        ))}
        <div className="footer">
          <div className="label">{t('report.monthlyCostRecoveries')}</div>
          <div className="value">${data?.amount || '0.0'}</div>
        </div>
      </Loader>
    </section>
  );
};

export default MonthlySummary;
