import { ChangeEvent, useState } from 'react';
import { t } from 'i18next';
import * as yup from 'yup';

import { IndInvoicePatch } from '@common/interfaces';
import { commentValid } from '@common/utils';
import { Field, Form } from '@components';
import css from './styles.scss';

interface CommentsProps {
  value?: string;
  updateInvoice: (comment: Partial<IndInvoicePatch>) => void;
}

const Comments = ({ value, updateInvoice }: CommentsProps) => {
  const [isChanged, setIsChanged] = useState(false);

  return (
    <Form
      defaultValues={{ invoice_comment_text: value || '' }}
      schema={yup.object().shape({
        invoice_comment_text: yup.string().nullable().optional().test(commentValid),
      })}
      onSubmit={() => {}}
    >
      <Field
        name="invoice_comment_text"
        input="textarea"
        className={css.textfield}
        placeholder={t('invoice.insertComment')}
        onBlur={(e: ChangeEvent<HTMLTextAreaElement>) => {
          if (isChanged) {
            const val = e.target.value as string;
            setIsChanged(false);
            updateInvoice({ invoice_comment_text: val });
          }
        }}
        onChange={() => setIsChanged(true)}
      />
    </Form>
  );
};

export default Comments;
