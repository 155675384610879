/* istanbul ignore file */
import { useEffect } from 'react';
import { t } from 'i18next';
import { useFormApi } from 'informed';

import { Dropdown } from '@ui-modules/informed';
import { DriveFormSteps, DropdownOption } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

interface FieldProps {
  defaultValue?: DropdownOption | string;
  idx: number;
  options?: DropdownOption[];
}

const PaxRequestUnitField = ({ defaultValue, idx, options }: FieldProps) => {
  const formApi = useFormApi();

  useEffect(() => {
    if (defaultValue && options?.length) {
      formApi.setValue(
        `${DriveFormSteps.Main}.passengersInfo[${idx}].requestingUnit`,
        options.find((option) => option.value === defaultValue),
      );
    }
  }, [defaultValue, formApi, idx, options]);

  return (
    <div className="field">
      <Dropdown
        label={`${t('common.requestingUnit')} *`}
        name="requestingUnit"
        options={options}
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default PaxRequestUnitField;
