/* istanbul ignore file */
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { t } from 'i18next';
import { useFieldState, useFormApi } from 'informed';
import { Dropdown } from '@ui-modules/informed';

import { DropdownOption, PlAcceptDrive, PlDriver, PlResource, PlVehicle } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../../BookingPanel.styles.scss';

type FieldProps = {
  data?: PlAcceptDrive;
  disabled?: boolean;
  drivers?: PlDriver[];
  leg: number;
  resource?: [PlResource | null, Dispatch<SetStateAction<PlResource | null>>];
  value?: string;
  vehicle?: string;
  vehicles: PlVehicle[];
  onChange?: (value: DropdownOption) => void;
};

const VehicleField = ({
  data,
  disabled,
  drivers,
  leg,
  resource = [null, () => {}],
  value,
  vehicle,
  vehicles,
  onChange,
}: FieldProps) => {
  const formApi = useFormApi();
  const { value: driver } = useFieldState(`${leg}.driver`);
  const { passengerPax: pax } = data || {};

  const [scheduledResource, setScheduledResource] = resource;
  const [warning, setWarning] = useState<string | null>(null);

  const options = useMemo(
    () => vehicles.map(({ id, title }: PlVehicle): DropdownOption => ({ label: title, value: id })),
    [vehicles],
  );

  const getOption = useCallback(
    (val: string) => options.find((i: DropdownOption) => i.value === val),
    [options],
  );

  useEffect(() => {
    const resourceVehicle = scheduledResource?.vehicleId || value;
    if (resourceVehicle) {
      formApi.setValue(`${leg}.vehicle`, getOption(resourceVehicle));
      setScheduledResource(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leg, options, scheduledResource, value]);

  useEffect(() => {
    const preferred = drivers?.find(
      (i) => i.id === (driver as DropdownOption)?.value,
    )?.preferredVehicle;
    if (preferred) formApi.setValue(`${leg}.vehicle`, getOption(preferred));
  }, [driver, drivers, formApi, getOption, leg]);

  const handleVehicleChange = useCallback(
    (selectedOption: DropdownOption | undefined) => {
      if (selectedOption) {
        const selectedVehicle = vehicles.find((i) => i.id === selectedOption.value);
        if (pax && selectedVehicle) {
          setWarning(
            pax > selectedVehicle.maxCapacity
              ? `${t('mobility.vehiclePaxWarning')} (Capacity: ${
                  selectedVehicle.maxCapacity
                }; Pax: ${pax})`
              : null,
          );
        }
        onChange?.(selectedOption);
      }
    },
    [onChange, pax, vehicles],
  );

  return (
    <div className="field" data-testid={`planner-booking-panel-vehicle-${leg}`}>
      <Dropdown
        className="dropdown"
        defaultValue={getOption(vehicle!)}
        isDisabled={disabled}
        label={t('mobility.selectVehicle')}
        name={`${leg}.vehicle`}
        options={options}
        validate={notEmptyValidator}
        onChange={handleVehicleChange}
      />
      {warning && <div className="field-info">{warning}</div>}
    </div>
  );
};

export default VehicleField;
