import { type ChangeEvent, type FC, useEffect } from 'react';
import { useField } from 'informed';
import './styles.scss';

type TimeFieldProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> & {
  className?: string;
  initialValue?: string;
  label?: string | JSX.Element;
  name: string;
  validateOnBlur?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  validate?: (
    value: string,
    values: {
      [key: string]: Record<string, any>;
    },
  ) => void | string;
};

const TimeField: FC<TimeFieldProps> = ({
  className,
  initialValue,
  label,
  name,
  onChange,
  onBlur,
  ...restProps
}) => {
  const {
    fieldState: { value, error },
    fieldApi: { setValue, setTouched },
    render,
  } = useField<TimeFieldProps, any>({
    className,
    initialValue,
    label,
    name,
  });

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue, setValue]);

  return render(
    <div className={className ? `inp-time ${className}` : 'inp-time'}>
      {label && (
        <label className="inp-time-label" htmlFor={name}>
          {label}
        </label>
      )}
      <input
        name={name}
        type="time"
        value={(value || '') as string}
        onChange={(e) => {
          setValue(e.target.value);
          onChange?.(e.target.value);
        }}
        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
          setTouched(true);
          onBlur?.(e.target.value);
        }}
        {...restProps}
      />
      {error ? <p className="inp-time-error">{error as string}</p> : null}
    </div>,
  );
};

export default TimeField;
