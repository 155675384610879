import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'usehooks-ts';
import { useFormApi } from 'informed';

import type { ApiList, DropdownOption, TravelRequestCitiesListApi } from '@common/interfaces';
import { Dropdown } from '@ui-modules/informed';
import { useRepository } from '@context';
import { Serializer, notEmptyValidator } from '@common/utils';
import './styles.scss';

interface FieldProps {
  idx?: number;
  label: string;
  name: string;
  onFetching?: (state: boolean) => void;
}

const CityField = ({ idx, label, name, onFetching }: FieldProps) => {
  const { travelRequestRepository } = useRepository();
  const formApi = useFormApi();
  const formState = formApi.getFormState().values as any;

  const [cities, setCities] = useState<DropdownOption[]>([]);
  const [searchCity, setSearchCity] = useState<string>();
  const debouncedCitySearch = useDebounce(searchCity, 800);

  const selectedCountry =
    name &&
    typeof idx === 'number' &&
    formState.trips?.[idx][name === 'arrivalCity' ? 'arrivalCountry' : 'departureCountry']?.value;

  const { isLoading } = useQuery(
    ['get-cities', debouncedCitySearch, selectedCountry],
    () =>
      travelRequestRepository.getCities({
        countryCode: selectedCountry,
        limit: 50,
        name: debouncedCitySearch,
      }),
    {
      enabled: debouncedCitySearch !== undefined || Boolean(selectedCountry),
      onSuccess: (data: ApiList<TravelRequestCitiesListApi>) => {
        setCities(data.results.map(Serializer.formatTravelRequestCity));

        if (typeof idx === 'number' && !data?.results?.length) {
          formApi.setValue(`trips[${idx}].${name}`, undefined);
        }
      },
    },
  );

  useEffect(() => {
    onFetching?.(isLoading);
  }, [isLoading, onFetching]);

  return (
    <div className="field">
      <Dropdown
        label={label}
        name={name}
        options={cities}
        validate={notEmptyValidator}
        onChange={(option: DropdownOption) => {
          if (
            name === 'arrivalCity' &&
            typeof idx === 'number' &&
            formState?.trips.length > idx + 1
          ) {
            formApi.setValue(`trips[${idx + 1}].departureCity`, option);
          }
        }}
        onInputChange={(value) => {
          setSearchCity(value);
        }}
      />
    </div>
  );
};

export default CityField;
