import { type ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, AutocompleteProps, Modal, Tooltip } from '@mantine/core';
import { useField } from 'informed';
import { useDebounce } from 'usehooks-ts';

import type { GoogleSuggestion } from '@common/types';
import { fetchLocation, fetchSuggestions } from '@common/utils';
import { Button, GoogleMaps } from '@components';
import { CloseRound, Process } from '@assets/svg/icons';

type LatLngLiteral = google.maps.LatLngLiteral;

export type LocationFieldProps = Omit<AutocompleteProps, 'data' | 'value' | 'onChange'> & {
  city: string;
  country: string;
  name: string;
  required?: boolean;
  onChange?: (e: GoogleSuggestion | string) => void;
};

const LocationField = ({ city, country, label, name, required, onChange }: LocationFieldProps) => {
  const { i18n, t } = useTranslation();
  const { language } = i18n;
  const { fieldState, fieldApi, render } = useField({
    name,
    validate: (value) => {
      if (required && (!value || value === '')) return 'This field is required';
    },
  });

  const [isInputLocked, setInputLocked] = useState<boolean>(false);
  const [isMapOpen, setMapOpen] = useState<boolean>(false);
  const [locationQuery, setLocationQuery] = useState<string>();
  const [placeParam, setPlaceParam] = useState<{ placeId: string; query: string } | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<LatLngLiteral | null>();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<GoogleSuggestion[]>([]);

  const [countryName] = country?.match(/(.*)\s\[(.*)\]/)?.slice(1) ?? [];
  const debQuery = useDebounce(locationQuery, 800);

  const clearInput = useCallback(() => {
    setInputLocked(false);
    setPlaceParam(null);
    setShowTooltip(false);
    setSelectedLocation(null);
    setSuggestions([]);
    setLocationQuery('');
    fieldApi.setValue(' ');
  }, [fieldApi]);

  const openMap = () => setMapOpen(true);
  const handleClose = () => setMapOpen(false);

  const handleAutocompleteChange = async (input: GoogleSuggestion) => {
    const selected = suggestions.find((s) => s.value === input.value);
    if (selected) {
      if (selected.value === 'Select the location on Google map') {
        clearInput();
        openMap();
      } else {
        setPlaceParam({ placeId: selected?.dataset?.placeId!, query: selected.value });
        setInputLocked(true);
        fieldApi.setValue(selected.value);
        onChange?.(selected);
      }
    }
  };

  const handleInputBlur = (input: string) => {
    setShowTooltip(false);

    if (placeParam) return;

    const hasMinMatching = (suggestion: string, minMatches = 2) =>
      input
        .toLowerCase()
        .split(/\s+/)
        .filter((w) => suggestion.toLowerCase().includes(w)).length >= minMatches;

    const isMatchFound = suggestions.some((i) => hasMinMatching(i.value));

    if (!isMatchFound) {
      clearInput();
      return;
    }

    const selectedSuggestion = suggestions.find((i) => i.dataset?.lat && i.dataset?.lng);
    if (!selectedSuggestion) clearInput();
  };

  const handleInputChange = (input: string) => {
    setLocationQuery(input);
    setShowTooltip(!!input);
    fieldApi.setValue(input);
  };

  const handleSelectLocation = (coords: LatLngLiteral) => {
    setSelectedLocation(coords);
    setInputLocked(true);
  };

  const handleSubmit = () => {
    if (selectedLocation) {
      fetchLocation({
        city,
        coords: selectedLocation,
        country: countryName,
        language,
        onChange: (e) => {
          fieldApi.setValue((e as GoogleSuggestion).value);
          onChange?.(e);
        },
      });
    }
    setMapOpen(false);
  };

  useEffect(() => {
    if (debQuery && debQuery.length >= 1 && debQuery === locationQuery) {
      (async () => {
        const data = await fetchSuggestions({
          query: debQuery,
          language,
          placeParam,
        });
        setSuggestions(data);

        const selectedSuggestion = data.find((i) => i.dataset?.lat && i.dataset?.lng);
        if (selectedSuggestion) onChange?.(selectedSuggestion);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debQuery, language, placeParam, clearInput]);

  return render(
    <>
      <Autocomplete
        classNames={{
          wrapper: 'autocomplete',
          input: 'autocomplete-input',
          error: 'autocomplete-error',
          rightSection: 'autocomplete-right-section',
        }}
        data={suggestions}
        error={fieldState?.error as ReactNode}
        filter={() => true}
        label={
          <Tooltip
            label={t('bookingForm.tooltipGoogleLocation')}
            arrowSize={6}
            color="blue"
            multiline
            opened={showTooltip}
            withArrow
            width={280}
          >
            <span>{label}</span>
          </Tooltip>
        }
        limit={10}
        readOnly={isInputLocked}
        rightSection={
          isInputLocked ? (
            <Button leftIcon={<CloseRound />} variant="icon" onClick={clearInput} />
          ) : (
            <Button leftIcon={<Process />} variant="icon" onClick={openMap} />
          )
        }
        value={fieldState?.value as string}
        variant="unstyled"
        onBlur={(e) => handleInputBlur(e?.target?.value)}
        onChange={(e) => handleInputChange(e)}
        onFocus={() => setShowTooltip(true)}
        onItemSubmit={handleAutocompleteChange}
      />

      <Modal
        title={label}
        classNames={{
          content: 'modal-recap-booking',
          header: 'modal-recap-booking-header',
          body: 'modal-recap-booking-body',
        }}
        opened={isMapOpen}
        onClose={handleClose}
        size={900}
      >
        <GoogleMaps defaultCity={city} onSelectLocation={handleSelectLocation} />

        <footer>
          <Button text={t('common.btnCancel')} variant="warning" onClick={handleClose} />
          <Button text={t('common.btnConfirm')} variant="submit" onClick={handleSubmit} />
        </footer>
      </Modal>
    </>,
  );
};

export default LocationField;
