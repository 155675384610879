import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import cn from 'classnames';
import './InputTime.styles.scss';

export type InputTimeProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'defaultValue' | 'value'> & {
    className?: string;
    label?: string | JSX.Element;
    required?: boolean;
  };

export function InputTime<T extends FieldValues>({
  control,
  defaultValue,
  name,
  required,
  rules,
  shouldUnregister,
  label,
  className,
  onChange,
  ...props
}: InputTimeProps<T>) {
  const {
    field: { value = '', onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <div className={cn('input-time', className)}>
      {label && (
        <label className="input-time-label" htmlFor={name}>
          {label}
        </label>
      )}
      <input
        className="input-time-field"
        required={!!required}
        type="time"
        value={value}
        onChange={(e) => {
          fieldOnChange(e);
          onChange?.(e);
        }}
        {...field}
        {...props}
      />
      {fieldState.error && <p className="field-error">{fieldState.error.message}</p>}
    </div>
  );
}
