/* istanbul ignore file */
import { addMilliseconds, areIntervalsOverlapping, format, parse } from 'date-fns';
import { EventExtProps, PlAcceptDrive } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { formatDateString } from '@common/utils';

export const formatScheduleDriveOnAccept = (
  data: PlAcceptDrive,
  drive: EventExtProps,
): EventExtProps => {
  const formattedDrive = { ...drive };
  const start = parse(drive.pickupTime, DF.ApiTimeFull, new Date());
  const end = parse(drive.dropoffTime, DF.ApiTimeFull, new Date());
  const acceptStart = parse(data.pickupTime, DF.ApiTime, new Date());
  const acceptEnd = parse(data.dropoffTime, DF.ApiTime, new Date());
  const isShuttle = drive.isShuttleEvent;

  const isOverlapping = areIntervalsOverlapping(
    { start, end },
    { start: acceptStart, end: acceptEnd },
  );

  if (isOverlapping && isShuttle) {
    if (start < acceptStart) {
      formattedDrive.pickupTime = formatDateString(data.pickupTime, DF.ApiTime, DF.ApiTimeFull);
    }

    if (start > acceptStart) formattedDrive.pickupTime = drive.pickupTime;

    if (end > acceptEnd) {
      formattedDrive.dropoffTime = formatDateString(data.dropoffTime, DF.ApiTime, DF.ApiTimeFull);
    }

    if (end < acceptEnd) formattedDrive.dropoffTime = drive.dropoffTime;
  } else {
    const acceptDuration = acceptEnd.getTime() - acceptStart.getTime();
    const driveDuration = end.getTime() - start.getTime();
    formattedDrive.pickupTime = drive.pickupTime;
    formattedDrive.dropoffTime =
      isShuttle && acceptDuration > driveDuration
        ? format(addMilliseconds(start, driveDuration), DF.ApiTimeFull)
        : format(addMilliseconds(start, acceptDuration), DF.ApiTimeFull);
  }

  formattedDrive.pickupLocation = data.pickupLocation;
  formattedDrive.pickupTown = data.pickupTown;
  formattedDrive.dropoffLocation = data.dropoffLocation;
  formattedDrive.dropoffTown = data.dropoffTown;

  return formattedDrive;
};
