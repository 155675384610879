/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { useFormApi } from 'informed';
import { t } from 'i18next';

import { Dropdown, TextField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';
import { DriveFormSteps, DropdownOption, EventMoved, ShuttleStop } from '@common/interfaces';
import { useFieldState } from '@common/hooks';
import '../styles/modal.scss';

interface FieldProps {
  choices?: { [x: string]: DropdownOption[] };
  eventMoved?: EventMoved;
  fieldGroup?: string;
  index?: number;
  isEdit?: boolean;
  options?: DropdownOption[];
  returnTrip?: boolean;
  scheduled?: ShuttleStop[];
}

const DropoffLocationField = ({
  choices,
  eventMoved,
  fieldGroup,
  index,
  isEdit,
  options,
  returnTrip = false,
  scheduled,
}: FieldProps) => {
  const { getFormState, getValue, setValue } = useFormApi();
  const formState = getFormState().values as any;
  const { value: dropoffLocation } = useFieldState<DropdownOption>('dropoffLocation');
  const { value: pickupLocation } = useFieldState<DropdownOption>('pickupLocation');
  const drives = formState?.[DriveFormSteps.Trip]?.driveList;
  const pickupLoc = drives?.[index!]?.pickupLocation ?? null;
  const dropoffLocationField = fieldGroup ? `${fieldGroup}.dropoffLocation` : 'dropoffLocation';
  const dropoffTownField = fieldGroup ? `${fieldGroup}.dropoffTown` : 'dropoffTown';
  const dropoffTownValue = getValue(dropoffTownField) as DropdownOption;

  const [data, setData] = useState<DropdownOption[]>([]);

  useEffect(() => {
    if (returnTrip && pickupLoc) setValue(dropoffLocationField, pickupLoc);
  }, [dropoffLocationField, pickupLoc, returnTrip, setValue]);

  useEffect(() => {
    let dropdown: DropdownOption[] = [];

    if (options) {
      const locIdx = options.findIndex((i) => i.value === pickupLocation?.value);
      dropdown = options.slice(locIdx + 1);
    } else if (choices && dropoffTownValue && dropoffTownValue.value in choices) {
      dropdown = choices[dropoffTownValue.value];
    }

    setData(dropdown);

    if (drives && !options?.length) {
      const currentLocation = drives?.[index!]?.dropoffLocation;
      const formatLocation =
        typeof drives?.[index!]?.dropoffLocation === 'object'
          ? (currentLocation as DropdownOption)?.value
          : currentLocation;

      setValue(dropoffLocationField, formatLocation);
    }

    if (
      scheduled &&
      dropoffLocation &&
      pickupLocation &&
      pickupLocation?.value === dropoffLocation?.value
    ) {
      setValue(dropoffLocationField, '');
      setValue(dropoffTownField, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices, dropoffLocation?.value, dropoffTownValue, options, pickupLocation?.value]);

  return (
    <div className="field" data-testid="planner-drive-form-field-dropoff-location">
      {choices || options?.length ? (
        <Dropdown
          className="dropdown"
          isClearable
          isCreatable={!!choices?.length || isEdit}
          label={t('bookingDetails.dropoffLocation')}
          name="dropoffLocation"
          options={data}
          placeholder={
            options ? undefined : `${t('common.select')} ${t('bookingDetails.dropoffTown')}...`
          }
          validate={notEmptyValidator}
          onChange={(option) => {
            if (scheduled) {
              const town = scheduled.find((i) => i.location === option?.value)?.town;
              setValue(dropoffTownField, town);
            }
          }}
        />
      ) : (
        <TextField
          className="input"
          data-testid="planner-drive-form-field-dropoff-location"
          label={t('bookingDetails.dropoffLocation')}
          name="dropoffLocation"
          validate={notEmptyValidator}
        />
      )}
      {eventMoved === EventMoved.InShuttle && (
        <span className="field-description dropdown">
          Please confirm the correct drop-off location
        </span>
      )}
    </div>
  );
};

export default DropoffLocationField;
