/* istanbul ignore file */
export const getFormSubmitError = (e: Record<string, any>): string | null => {
  if (!e || typeof e !== 'object' || Array.isArray(e)) return null;

  const formatKey = (key: string): string =>
    key
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/_/g, ' ')
      .replace(/^\w/, (c) => c.toUpperCase());

  let errorMsg: string | null = null;

  Object.keys(e)
    .sort()
    .some((key) => {
      const error = e[key];
      const record = Array.isArray(error) ? error[0] : error;

      if (record && typeof record === 'object' && record.message) {
        errorMsg = `${formatKey(key)}: ${record.message}`;
        return true;
      }
      return false;
    });

  return errorMsg;
};

interface Errors {
  [key: string]: Record<string, unknown>;
}

export const getErrors = (errorObject: Errors, includeKey = false): string => {
  if (typeof errorObject === 'object') {
    const text: string[] = [];

    Object.keys(errorObject).forEach((key) => {
      const prefix = includeKey ? `${key}: ` : '';

      if (typeof errorObject[key] === 'string') {
        text.push(`${prefix}${errorObject[key]}`);
      } else if (typeof errorObject[key] === 'object') {
        text.push(`${prefix}${errorObject[key][0]}`);
      } else {
        text.push('Something went wrong on this page');
      }
    });

    return text.join('; ');
  }

  return 'Something went wrong on this page.';
};

export const getRecurringDriveErrorsText = (e: any): string[] => {
  const errorsList: string[] = [];

  e.response.data.errors.forEach((error: any) => {
    if (error) {
      errorsList.push(error);
    }
  });

  return errorsList;
};
