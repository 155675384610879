import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { Table } from '@ui-modules';

import { CloseRound, Plus } from '@assets/svg/icons';
import { ChargesType, CurrencyType, PaymentType } from '@common/interfaces';
import { formatNumber, parseNumber } from '@common/utils';
import { Button, Field, Form } from '@components';
import DeleteBlock from '../../../../components/invoices/DeleteBlock';
import './InvoiceCharges.styles.scss';

export interface FormState {
  description: string;
  quantity: string;
  unitPrice: string;
}

interface ChargesProps {
  currencies: CurrencyType[];
  paymentMethods: PaymentType[];
  storedCharges: ChargesType[];
  onChangeCurrency: (value: number) => void;
  onChangePayment: (value: string) => void;
  updateInvoice: (data: ChargesType[]) => void;
}

const InvoiceCharges = ({
  currencies,
  paymentMethods,
  storedCharges,
  onChangeCurrency,
  onChangePayment,
  updateInvoice,
}: ChargesProps) => {
  const { t } = useTranslation();

  const [forms, setForms] = useState<string[]>([]);

  const add = () => setForms((prev) => [...prev, uuid()]);
  const remove = (id: string) => setForms((prev) => [...prev].filter((i) => i !== id));

  const handleSubmit = useCallback(
    async ({ description, quantity, unitPrice }: FormState) => {
      updateInvoice([
        ...storedCharges,
        { description, quantity: +parseNumber(quantity), unit_price: +parseNumber(unitPrice) },
      ]);

      setForms([]);
    },
    [storedCharges, updateInvoice],
  );

  const tableData = useMemo(
    () =>
      storedCharges.map((charge: ChargesType, idx: number) => ({
        actions: (
          <DeleteBlock
            key={charge.description}
            id={idx}
            deleteAction={() => updateInvoice(storedCharges.filter((_, index) => index !== idx))}
          />
        ),
        description: charge.description || '',
        id: idx,
        quantity: charge.quantity,
        unitPrice: charge.unit_price,
      })),
    [storedCharges, updateInvoice],
  );

  const columns = useMemo(
    () => [
      { dataIndex: 'description', key: 'description', title: 'Service' },
      { dataIndex: 'quantity', key: 'quantity', title: t('invoice.quantity') },
      { dataIndex: 'unit_price', key: 'unitPrice', title: t('common.price') },
      { dataIndex: 'actions', key: 'actions', title: '' },
    ],
    [t],
  );

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        description: yup.string().nullable().required(t('common.fieldRequired')),
        quantity: yup.string().nullable().required(t('common.fieldRequired')),
        unitPrice: yup.string().nullable().required(t('common.fieldRequired')),
      }),
    [t],
  );

  const currencyOptions = currencies.map((i) => ({ value: i.id.toString(), label: i.iso_code }));
  const paymentOptions = paymentMethods.map((i) => ({
    value: i.id.toString(),
    label: i.description,
  }));

  return (
    <section className="charges-container">
      <Form
        className="options"
        defaultValues={{ currency: currencyOptions[0]?.value, payment: paymentOptions[0]?.value }}
        schema={yup.object().shape({ currency: yup.string(), payment: yup.string() })}
        onSubmit={() => {}}
      >
        <Field
          name="currency"
          input="dropdown"
          className="dropdown"
          label={t('common.currency')}
          options={currencyOptions}
          defaultValue={currencyOptions[0]?.value}
          onChange={(value: string) => onChangeCurrency(+value)}
        />
        <Field
          name="payment"
          input="dropdown"
          className="dropdown"
          label={t('common.paymentMethod')}
          options={paymentOptions}
          defaultValue={paymentOptions[0]?.value}
          onChange={(value: string) => onChangePayment(value)}
        />
      </Form>

      <div className="table">
        <Table columns={columns} data={tableData} locale={{ emptyText: 'No items' }} />
      </div>

      {forms.map((id: string) => (
        <Form
          className="form"
          defaultValues={{ description: '', quantity: '', unitPrice: '' }}
          key={id}
          schema={formSchema}
          onSubmit={handleSubmit}
        >
          <div className="form-row">
            <div className="form-cell">
              <Field name="description" placeholder="Service" />
            </div>
            <div className="form-cell">
              <Field
                name="quantity"
                input="number"
                formatter={formatNumber}
                hideControls
                max={9999999}
                min={1}
                parser={(value: string) => value.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('invoice.quantity')}
              />
            </div>
            <div className="form-cell">
              <Field
                name="unitPrice"
                input="number"
                formatter={formatNumber}
                hideControls
                max={9999999}
                min={1}
                parser={(value: string) => value.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('common.price')}
              />
            </div>

            <div className="form-actions">
              <Button
                className="confirm"
                text={t('common.btnConfirm')}
                type="submit"
                variant="primary"
              />
              <Button
                className="delete"
                rightIcon={<CloseRound />}
                variant="icon"
                onClick={() => remove(id)}
              />
            </div>
          </div>
        </Form>
      ))}
      <div className="footer">
        <Button leftIcon={<Plus />} text="Add Service" variant="secondary" onClick={() => add()} />
      </div>
    </section>
  );
};

export default InvoiceCharges;
